import { useQuery, useMutation } from "react-query";
import { axiosInstance as axios } from "../config";

// export const fetchInventoryRequest = async ({ page, orderBy, sort, type }) => {
//   const response = await axios.get("/api/inventory/index", {
//     params: {
//       page,
//       orderBy,
//       sort,
//       type,
//     },
//   });
//   return response.data;
// };

// export const useFetchInventoryRequest = (params) => {
//   return useQuery(
//     ["inventoryRequest", params],
//     () => fetchInventoryRequest(params),
//     {
//       refetchOnWindowFocus: false,
//       keepPreviousData: true, // Optional: Useful for paginated data
//     }
//   );
// };
export const getGoldPrice = async () => {
  const response = await axios.get("/api/goldprice/sabikaPrice");
  return response.data?.data?.data;
};

export const useGetGoldPrice = () => {
  return useQuery(["goldPrice"], () => getGoldPrice(), {
    refetchOnWindowFocus: false,
    keepPreviousData: true, // Optional: Useful for paginated data
  });
};
const updateGoldPrice = async (body) => {
  const response = await axios.put("/api/goldprice/updateSabikaPrice", body);
  return response.data; // Returning only the data part
};

export const useUpdateGoldPrice = (onSuccess, onError) => {
  return useMutation(updateGoldPrice, {
    onSuccess, // Optional: Callback after a successful mutation
    onError, // Optional: Callback when the mutation fails
  });
};

const getCompetitors = async (params) => {
  const response = await axios.get("/api/goldprice/competitors", { params });
  return response.data?.data;
};

// Update the useGetAllLicenses hook to accept parameters
export const useGetCompetitors = (params) => {
  return useQuery(["competitors", params?.id], () => getCompetitors(params), {
    refetchOnWindowFocus: false,
    keepPreviousData: true, // Optional: Useful for paginated data
  });
};
