import React, { useEffect, useState } from "react";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import Header from "../../../components/Header";
import { Step, StepLabel, Stepper } from "@mui/material";
import { useStylesHook } from "../../../components/common/useStyles";
import { useLocation } from "react-router-dom";
import ImageUploader from "../../../components/ImageUploader";
import CustomDialog from "../../../components/CustomDialog";
import { useEditLicense } from "../../../services/apis/license";
// Arabic letters and spaces regex validation
const isArabic = (text) =>
  /^[\u0600-\u06FF\s\d.,!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(text);
const isEnglish = (text) =>
  /^[A-Za-z\s\d.,!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(text);

const EditLicense = () => {
  const steps = ["English", "Arabic", "Review"];
  const c = useStylesHook();
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const { mutateAsync, isLoading } = useEditLicense();
  const {
    state: { license },
  } = useLocation();
  console.log("license", license);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const currentLang = activeStep === 0 ? "en" : "ar";
  const [formData, setFormData] = useState({
    image: "",
    en: {
      name: license?.name_en,
      headline: license?.headline_en,
      description: license?.description_en,
    },
    ar: {
      name: license?.name_ar,
      headline: license?.headline_ar,
      description: license?.description_ar,
    },
  });
  const handleFieldChange = (lang, field, value) => {
    // Allow empty value to avoid blocking deletion
    if (
      value === "" ||
      (lang === "ar" && isArabic(value)) ||
      (lang === "en" && isEnglish(value))
    ) {
      setFormData((prev) => ({
        ...prev,
        [lang]: {
          ...prev[lang],
          [field]: value,
        },
      }));
    }
  };
  const validateForm = () => {
    const { name, headline, description } = formData[currentLang];
    const allFieldsFilled = Boolean(
      name?.trim() &&
        headline?.trim() &&
        (!license?.description_en || description?.trim())
    );
    setIsNextEnabled(allFieldsFilled);
  };

  useEffect(() => {
    validateForm();
  }, [formData, activeStep, file, image]);
  return (
    <div>
      <DashboardMainHeader />
      <Header title={"Edit License"} marginL={"24px"} />
      <div
        className="mt-8 py-16 px-6 mx-6 rounded-xl bg-white"
        style={{
          boxShadow: `
         0px 1px 3px 0px rgba(0, 0, 0, 0.02),
         0px 1px 6px 0px rgba(0, 0, 0, 0.07)
       `,
        }}
      >
        <Stepper className={`${c.root}`} activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="mt-12">
          {activeStep === 2 ? (
            <div className="flex mt-16">
              <div className=" flex-1">
                <div className="flex items-center ">
                  <h2 className="w-1/4">License Name:</h2>
                  <h2 className="">{formData?.en?.name}</h2>
                </div>
                <hr className={`text-[#F5F5F5] border-t-[1px] my-3`} />
                <div className="flex items-center ">
                  <h2 className="w-1/4">License Headline:</h2>
                  <h2 className="">{formData?.en?.headline}</h2>
                </div>
                <hr
                  className={`text-[#F5F5F5] border-t-[1px] my-3 ${
                    license?.description_en ? "" : "hidden"
                  }`}
                />
                <div
                  className={`flex items-center ${
                    license?.description_en ? "" : "hidden"
                  }`}
                >
                  <h2 className="w-1/4">License Description:</h2>
                  <h2 className="">{formData?.en?.description}</h2>
                </div>
                <hr className="text-[#F5F5F5] border-t-[1px] my-3" />
                <div className="flex items-center ">
                  <h2 className="w-1/4">License Name (Arabic):</h2>
                  <h2 className="">{formData?.ar?.name}</h2>
                </div>
                <hr className={`text-[#F5F5F5] border-t-[1px] my-3 `} />
                <div className="flex items-center ">
                  <h2 className="w-1/4">License Headline(Arabic):</h2>
                  <h2 className="">{formData?.ar?.headline}</h2>
                </div>
                <hr
                  className={`text-[#F5F5F5] border-t-[1px] my-3 ${
                    license?.description_en ? "" : "hidden"
                  }`}
                />
                <div
                  className={`flex items-center  ${
                    license?.description_en ? "" : "hidden"
                  }`}
                >
                  <h2 className="w-1/4">License Description(Arabic):</h2>
                  <h2 className="">{formData?.ar?.description}</h2>
                </div>
                <hr className="text-[#F5F5F5] border-t-[1px] my-3" />
              </div>
            </div>
          ) : (
            <div className="mt-16 mb-12 flex flex-col gap-6">
              {/* License Name Input */}
              <div>
                <p className="text-[#595959] text-sm mb-2">
                  License Name ({currentLang === "en" ? "English" : "Arabic"})
                </p>
                <input
                  onChange={(e) =>
                    handleFieldChange(currentLang, "name", e.target.value)
                  }
                  value={formData[currentLang].name}
                  placeholder={`Enter License Name (${
                    currentLang === "en" ? "English" : "Arabic"
                  })`}
                  className="border w-full bg-[#f5f5f5] rounded-xl p-4 outline-none"
                />
              </div>

              {/* Image Uploader - Only for English */}
              {currentLang === "en" && (
                <ImageUploader
                  title={"License Image"}
                  image={image}
                  setFile={setFile}
                  setImage={setImage}
                  setPayload={setFormData}
                  file={file}
                />
              )}

              {/* License Headline Input */}
              <div>
                <p className="text-[#595959] text-sm mb-2">
                  License Headline (
                  {currentLang === "en" ? "English" : "Arabic"})
                </p>
                <input
                  onChange={(e) =>
                    handleFieldChange(currentLang, "headline", e.target.value)
                  }
                  value={formData[currentLang].headline}
                  placeholder={`Enter License Headline (${
                    currentLang === "en" ? "English" : "Arabic"
                  })`}
                  className="border w-full bg-[#f5f5f5] rounded-xl p-4 outline-none"
                />
              </div>
              {license?.description_en && (
                <div>
                  <p className="text-[#595959] text-sm mb-2">
                    License Description (
                    {currentLang === "en" ? "English" : "Arabic"})
                  </p>
                  <input
                    onChange={(e) =>
                      handleFieldChange(
                        currentLang,
                        "description",
                        e.target.value
                      )
                    }
                    value={formData[currentLang].description}
                    placeholder={`Enter Description  (${
                      currentLang === "en" ? "English" : "Arabic"
                    })`}
                    className="border w-full bg-white border-[#e9c237] rounded-xl p-4 outline-none"
                  />
                </div>
              )}
            </div>
          )}
          {/* Navigation Buttons */}
          <div className="flex items-center justify-end gap-4">
            {/* Back Button */}
            <button
              onClick={() => setActiveStep((prev) => prev - 1)}
              disabled={activeStep === 0 || isLoading}
              className={`${
                activeStep === 0 ? "hidden" : "block"
              } px-20 py-2 border border-[#e9c237] text-[#e9c237] rounded-lg disabled:opacity-50`}
            >
              Back
            </button>

            {/* Next or Save Button */}
            <button
              onClick={async () => {
                if (activeStep === steps.length - 1) {
                  // Save API Call
                  let sentData;

                  const commonData = {
                    name_ar: formData.ar.name,
                    name_en: formData.en.name,
                    headline_ar: formData.ar.headline,
                    headline_en: formData.en.headline,
                  };

                  // Conditionally add descriptions if they exist
                  if (license?.description_en) {
                    commonData.description_ar = formData.ar.description;
                    commonData.description_en = formData.en.description;
                  }

                  // Create sentData with conditional image inclusion
                  sentData = {
                    ...(formData.image && { image: formData.image }), // Include image only if it exists
                    data: JSON.stringify(commonData),
                  };

                  await mutateAsync({ id: license?.id, body: sentData }).then(
                    () => {
                      setSuccessDialogOpen(true);
                    }
                  );
                }
                setActiveStep((prev) => prev + 1);
              }}
              disabled={!isNextEnabled || isLoading}
              className={`px-20 py-2 bg-[#e9c237] text-white rounded-lg disabled:opacity-50`}
            >
              {activeStep === steps.length - 1 ? "Save" : "Next"}
            </button>
          </div>
        </div>
      </div>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="License has been updated Successfully."
        buttonText="Go to Licenses"
        buttonLink="/app-license"
      />
    </div>
  );
};

export default EditLicense;
