import { useRef, useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import CustomDialog from "../../components/CustomDialog";
import { useEditArticle } from "../../services/apis/Articles";
import { MyEditor } from "../../components/MyEditor";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { MenuProps } from "../../components/common/MenuProps";
import { FirstTypography } from "../../components/common/CustomTypogrphy";
const EditArticle = () => {
  const editArticleMutation = useEditArticle();
  const [submitting, setSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const selectedArticle = location?.state?.selectedArticle;
  const [images, setImages] = useState(null);
  const [file, setFile] = useState(null);
  const [inputKey, setInputKey] = useState(0);

  const lang = [
    { id: 1, local: "en", name: "English" },
    { id: 2, local: "ar", name: "Arabic" },
  ];

  const [formData, setFormData] = useState({
    local: "en",
    is_hidden: "",
    title: "",
    body: "",
    image: "",
    sort: "",
  });
  useEffect(() => {
    if (!formData?.sort) {
      setFormData((prevPayload) => ({
        ...prevPayload,
        is_hidden: "1",
      }));
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        is_hidden: "0",
      }));
    }
  }, [formData?.sort]);
  const handleLangChange = (event) => {
    const selectedLang = event.target.value;
    const selectedLangObj = lang.find((item) => item.local === selectedLang);
    if (selectedLangObj) {
      if (selectedArticle) {
        setFormData((prevState) => ({
          ...prevState,
          local: selectedLang,
          title:
            selectedLang === "en"
              ? selectedArticle?.trans?.en?.title
              : selectedArticle?.trans?.ar?.title,
          body:
            selectedLang === "en"
              ? selectedArticle?.trans?.en?.body
              : selectedArticle?.trans?.ar?.body,
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          local: selectedLang,
          title:
            prevState.trans.find((item) => item.local === selectedLang)
              ?.title || "",
          body:
            prevState.trans.find((item) => item.local === selectedLang)?.body ||
            "",
        }));
      }
    }
  };
  const handleFileInputChange = (event) => {
    if (event && event.target) {
      const selectedFile = event.target.files[0];
      if (selectedFile && selectedFile instanceof File) {
        setFile(selectedFile);
        const reader = new FileReader();
        reader.onload = (e) => {
          setImages(e.target.result);
        };
        reader.readAsDataURL(selectedFile);
        setFormData((prevPayload) => ({
          ...prevPayload,
          image: selectedFile,
        }));
        setInputKey(inputKey + 1);
      }
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFile(file);

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImages(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const fileInputRef = useRef();
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleDeleteImage = () => {
    setImages(null);
    setFile(null);
  };
  const handleSave = async () => {
    try {
      setSubmitting(true);
      const data = {
        local: formData.local,
        title: formData.title,
        body: formData.body,
        sort: formData.sort,
      };
      const bodyEdit = {
        data: JSON.stringify(data),
      };
      if (file) {
        bodyEdit.image = file;
      }
      await editArticleMutation.mutateAsync({ id: id, body: bodyEdit });
      setSuccessDialogOpen(true);
    } finally {
      setSubmitting(false);
    }
  };

  const ordersData = [1, 2, 3, 4, 5];

  useEffect(() => {
    if (selectedArticle) {
      setFormData({
        local: "en",
        title: selectedArticle?.trans?.en?.title,
        body: selectedArticle?.trans?.en?.body,
        is_hidden: selectedArticle?.is_hidden,
        sort: selectedArticle?.sort,
      });
    }
  }, [selectedArticle]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <Box sx={{ mb: "3%" }}>
          <DashboardMainHeader />
        </Box>
        <Header title={"Edit Article"} />
      </Box>
      <Box
        m="20px"
        sx={{
          height: "auto",
          background: "#FFFFFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08)",
          overflow: "auto",
          padding: "4%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#4C4C4C",
              marginBottom: "5px",
              marginTop: "15px",
            }}
          >
            Language
          </Typography>
          <FormControl sx={{ width: "100%" }}>
            <Select
              displayEmpty
              onChange={handleLangChange}
              value={formData?.local || ""}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (!selected) {
                  return <em>{formData.title}</em>;
                }
                return lang.find((item) => item.local === selected)?.name || "";
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              {lang.map((item) => (
                <MenuItem key={item.local} value={item.local}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {formData.local === "en" && (
          <Box
            sx={{
              marginTop: "2%",
              width: "100%",
            }}
          >
            <FormLabel
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#4C4C4C",
              }}
              id="demo-row-radio-buttons-group-label"
            >
              Article Status
            </FormLabel>
            <RadioGroup
              style={{
                marginTop: "15px",
              }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event) => {
                const selectedType = event.target.value;
                setFormData((prevPayload) => ({
                  ...prevPayload,
                  is_hidden: selectedType,
                }));
              }}
              value={formData.is_hidden}
            >
              <FormControlLabel
                style={{
                  marginRight: "4%",
                  color: "#4C4C4C",
                  fontSize: "24px",
                }}
                value={0}
                control={
                  <Radio
                    sx={{
                      "&, &.Mui-checked": {
                        color: "#E9C237",
                      },
                      "&, &.Mui-unchecked": {
                        color: "#B3B3B3",
                      },
                    }}
                  />
                }
                label="Show"
              />
              <FormControlLabel
                style={{ color: "#4C4C4C", fontSize: "24px" }}
                value={1}
                onClick={() => {
                  setFormData((prevPayload) => ({
                    ...prevPayload,
                    sort: null,
                  }));
                }}
                control={
                  <Radio
                    sx={{
                      "&, &.Mui-checked": {
                        color: "#E9C237",
                      },
                      "&, &.Mui-unchecked": {
                        color: "#B3B3B3",
                      },
                    }}
                  />
                }
                label="Hide"
              />
            </RadioGroup>
          </Box>
        )}
        {formData.local === "en" && (
          <Box
            sx={{
              width: "100%",
              display: formData?.is_hidden === "0" ? "block" : "none",
            }}
          >
            <FirstTypography title={"Select Article Order"} />
            <FormControl sx={{ width: "100%" }}>
              <Select
                displayEmpty
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                value={formData?.sort}
                onChange={(event) => {
                  const order = event.target.value;
                  setFormData((prevPayload) => ({
                    ...prevPayload,
                    sort: String(order),
                  }));
                }}
                inputProps={{ "aria-label": "Without label" }}
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Select Order Number</em>;
                  }
                  return selected; // This line returns the selected value for display
                }}
              >
                {ordersData.map((orderNumber) => (
                  <MenuItem key={orderNumber} value={orderNumber}>
                    {orderNumber}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <Box sx={{ marginTop: "10px" }}>
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name={`Article Header`}
            placeholder={`Enter Article Header`}
            value={formData.title}
            onChange={(event) => {
              const disallowEnglishRegex = /^[^a-zA-Z]*$/;
              if (
                event.target.value === "" ||
                disallowEnglishRegex.test(event.target.value)
              ) {
                var temppayload = { ...formData };
                temppayload.title = event.target.value;
                setFormData({ ...temppayload });
              } else {
                if (formData?.local === "en") {
                  var temppayloadEn = { ...formData };
                  temppayloadEn.title = event.target.value;
                  setFormData({ ...temppayloadEn });
                }
              }
            }}
          />
        </Box>
        {formData.local === "en" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Box sx={{ marginTop: "15px" }}>
              <Typography
                sx={{ color: "#666666", fontSize: "16px", fontWeight: 400 }}
              >
                Article Image
              </Typography>
              <Box>
                {!images && (
                  <div
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    style={{
                      marginTop: "5px",
                      width: "490px",
                      height: "135px",
                      border: "2px dashed #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      borderRadius: "12px",
                      background: "#FFF",
                      flexDirection: "column",
                    }}
                  >
                    <img src={`../../assets/export.png`} alt="upload" />
                    <Box sx={{ display: "flex" }}>
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileInputChange}
                      />
                      {!images && (
                        <Button
                          sx={{
                            color: "#E9C237",
                            textDecoration: "underLine",
                            fontSize: "16px",
                            fontWeight: 400,
                            textTransform: "none",
                          }}
                          onClick={handleUploadButtonClick}
                        >
                          Click to upload
                        </Button>
                      )}
                      {images ? (
                        <img
                          src={images}
                          alt="Dropped"
                          height="152px"
                          width="100%"
                        />
                      ) : (
                        <p
                          style={{
                            color: "#333333",
                            fontSize: "16px",
                            marginTop: "8px",
                          }}
                        >
                          or drag and drop{"  "}
                        </p>
                      )}
                    </Box>
                    <Typography
                      sx={{
                        color: "#CCCCCC",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontFamily: "Roboto",
                        marginLeft: "5px",
                      }}
                    >
                      SVG, PNG, JPG or GIF (max. 3MB)
                    </Typography>
                  </div>
                )}
              </Box>
              {file && (
                <Box
                  sx={{
                    width: "500px",
                    borderRadius: "9px",
                    background: "#F7F7F7",
                    boxShadow: "none",
                    display: "flex",
                    height: "94px",
                    padding: "16px",
                    justifyContent: "space-between",
                    marginTop: "5px",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={`../../assets/document-upload.png`}
                      alt="upload"
                    />
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 400,
                        }}
                      >
                        {file.name}
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(0, 0, 0, 0.60)",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          letterSpacing: "0.17px",
                          wordSpacing: "4px",
                        }}
                      >
                        {Math.ceil(file.size / 1024)}kb • Complete
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Avatar
                      sx={{
                        bgcolor: "rgba(0, 51, 102, 0.1)",
                        height: "24px",
                        width: "24px",
                        alignSelf: "center",
                        marginRight: "15px",
                      }}
                    >
                      <img
                        src={`../../assets/checkcircle.png`}
                        alt="check circle"
                      />
                    </Avatar>

                    <Avatar
                      onClick={handleDeleteImage}
                      sx={{
                        cursor: "pointer",
                        height: "24px",
                        width: "24px",
                        alignSelf: "center",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <img src={`../../assets/delete.png`} alt="delete" />
                    </Avatar>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
        <Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              color: "#4C4C4C",
              marginBottom: "5px",
              marginTop: "15px",
            }}
          >
            Article
          </Typography>
          <MyEditor
            placeholder={formData?.body ? "" : "Enter Article"}
            text={formData.body}
            setText={(newContent) => {
              var temppayload = { ...formData };
              temppayload.body = newContent;
              setFormData({ ...temppayload });
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={submitting}
            onClick={handleSave}
            sx={{
              "&:hover": { backgroundColor: "#E9C237" },
              backgroundColor: "#E9C237",
              width: "200px",
              height: "46px",
              borderRadius: "12px",
              color: "#ffffff",
              fontSize: "14px",
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "5%",
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Article has been Edited Successfully."
        message=""
        buttonText="Go to Articles"
        buttonLink="/articles"
      />
      <ReusableSnackbar />
    </Box>
  );
};
export default EditArticle;
