import { Box } from "@mui/material";
import { DataGridStyle } from "../../../styles";
import { useState } from "react";
import { usePendingOrders } from "../../../services/apis/orders";
import RequestOrderDialog from "./RequestOrderDialog";
import { ItemsColumns } from "../../../components/common/Columns";
import NewTable from "../../../components/common/NewTable";

const FirstPendingTabPanel = () => {
  const [open, setOpen] = useState(false);
  const { data, isFetching, refetch } = usePendingOrders();

  const handleClose = () => {
    setOpen(false);
  };

  const allColumns = ItemsColumns([
    { field: "name", headerName: "Product" },
    {
      field: "amount",
      specialRender: "customRender",
      renderFunction: (params) => {
        return params.row.name === "Fractions"
          ? params.row.total_weight
          : params.row.amount;
      },
    },
    { field: "created", date: true },
  ]);

  return (
    <Box>
      <Box
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
          pb: 2,
        }}
      >
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <NewTable
            noPagination
            columns={allColumns}
            isLoading={isFetching}
            data={data?.data}
          />
        </Box>
      </Box>
      <RequestOrderDialog
        fetchData={() => {
          refetch();
        }}
        handleResponse={() => {
          refetch();
        }}
        open={open}
        onClose={handleClose}
        label={"Request"}
      />
    </Box>
  );
};
export default FirstPendingTabPanel;
