import { useMutation,   useQuery,   useQueryClient } from "react-query";
import { axiosInstance as axios } from "../config";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
// // Fetch articles
export const useFetchVouchers = (params) => {
  return useQuery(["vouchers",params], async () => {
    const response = await axios.get(`/api/vouchers/index`,{params});
    return response.data?.data ; // Assuming response.data contains the articles
  });
};

export const useFetchVoucherById = (body) => {
  const{id,...params}=body
   return useQuery(["vouchers",id], async () => {
    const response = await axios.get(`/api/vouchers/view/${body.id}`,{params});
    return response.data?.data ; // Assuming response.data contains the articles
  });
};

// // Delete an article
export const useDeleteVoucher = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async (id) => {
      const response = await axios.get(`/api/vouchers/delete/${id}`);
      return response.data; // Assuming response.data contains the result of deletion
    },
    {
      // On success, invalidate the 'vouchers' query to refetch the list
      onSuccess: () => {
        queryClient.invalidateQueries(["vouchers"]);
      },
      onError: (error) => {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      },
    }
  );
};

// Add a voucher
export const useAddVoucher = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async (body) => {
      const response = await axios.post("/api/vouchers/add", body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data?.data; // Assuming response.data contains the added article
    },
    {
      // On success, invalidate the 'articles' query to refetch the list
      onSuccess: () => {
        queryClient.invalidateQueries(["vouchers"]);
      },
      onError: (error) => {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      },
    }
  );
};
// Add a voucher
export const useEditVoucher = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async (body) => {
       const{id,...rest}=body
      const response = await axios.post(`/api/vouchers/edit/${id}`, rest, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data?.data; // Assuming response.data contains the added article
    },
    {
      // On success, invalidate the 'articles' query to refetch the list
      onSuccess: () => {
        queryClient.invalidateQueries(["vouchers"]);
      },
      onError: (error) => {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      },
    }
  );
};