import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import { Box, Button } from "@mui/material";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import CustomDialog from "../../components/CustomDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { SecondBox } from "../../components/common/CustomBox";
import { useAddRejectReason } from "../../services/apis/rejectionReasons";

const AddNewRejectReason = () => {
  const addRejectReason = useAddRejectReason();

  const [reasonsPayload, setReasonsPayload] = useState({
    reason_ar: "",
    reason_en: "",
  });
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSave = async () => {
    try {
      if (
        reasonsPayload?.reason_ar === "" ||
        reasonsPayload?.reason_en === ""
      ) {
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all fields",
          })
        );

        return;
      }
      setLoading(true);
      await addRejectReason.mutateAsync(reasonsPayload);
      setSuccessDialogOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      style={{
        overflowY: "auto",
        maxHeight: "calc(100vh)",
      }}
    >
      <DashboardMainHeader />

      <Header title="Add Reason" marginL={"25px"} />
      <SecondBox>
        <Box
          m="40px 0 0 0"
          height="auto"
          style={{
            paddingTop: "8%",
            paddingLeft: "9%",
            paddingBottom: "4%",
            width: "90%",
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Reason - English"
            placeholder="eg. New Items"
            value={reasonsPayload?.reason_en}
            onChange={(event) =>
              setReasonsPayload({
                reason_ar: reasonsPayload.reason_ar,
                reason_en: event.target.value,
              })
            }
          />
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Reason - Arabic"
            placeholder="eg. New Items"
            value={reasonsPayload?.reason_ar}
            onChange={(event) => {
              const disallowEnglishRegex = /^[^a-zA-Z]*$/;
              if (
                event.target.value === "" ||
                disallowEnglishRegex.test(event.target.value)
              ) {
                setReasonsPayload({
                  reason_en: reasonsPayload.reason_en,
                  reason_ar: event.target.value,
                });
              }
            }}
          />
          <Button
            disabled={loading}
            onClick={handleSave}
            sx={{
              "&:hover": { backgroundColor: "#E9C237" },
              backgroundColor: "#E9C237",
              width: "200px",
              height: "46px",
              borderRadius: "12px",
              color: "#ffffff",
              fontSize: "14px",
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "5%",
            }}
          >
            Save
          </Button>
        </Box>
      </SecondBox>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Reason has been added Successfully."
        message=""
        buttonText="Go to Rejection Reasons"
        buttonLink="/rejectReasons"
      />
      <ReusableSnackbar />
    </Box>
  );
};
export default AddNewRejectReason;
