import { formDate } from "../../../services/helpers";
import { Divider, Skeleton } from "@mui/material";

const ForthTicketPanel = ({ data, isLoading }) => {
  return (
    <div className="overflow-y-auto h-[25rem] mt-2 scrollbar-hide">
      {isLoading ? (
        <div className="flex flex-col gap-8 mt-4">
          <Skeleton sx={{ width: 1250 }} />
          <Skeleton sx={{ width: 1250 }} />
          <Skeleton sx={{ width: 1250 }} />
          <Skeleton sx={{ width: 1250 }} />
          <Skeleton sx={{ width: 1250 }} />
          <Skeleton sx={{ width: 1250 }} />
        </div>
      ) : (
        data?.history?.map((item) => (
          <div
            className="flex items-center h-[80px]"
            key={`${item.created}+${item?.message}`}
          >
            <Divider
              orientation="vertical"
              sx={{
                backgroundColor: "#E9C237",
                height: "100%",
                width: "3px",
              }}
            />
            <div className="flex flex-col w-full h-full ml-4">
              <div>
                <div className="flex items-center justify-between">
                  <p className="text-[#202020] text-xl font-semibold">
                    {item?.type === "newTicket"
                      ? "Ticket Opened"
                      : item?.type === "userReply"
                      ? "User Reply"
                      : item?.type === "reAssign"
                      ? "Ticket Reassigned "
                      : item?.type === "changeStatus"
                      ? "Ticket Closed"
                      : item?.type === "adminReply"
                      ? "Admin Reply"
                      : ""}
                  </p>
                  <p className="text-[#202020] text-sm font-semibold">
                    {formDate(item.created)}
                  </p>
                </div>
                <div className="flex items-center mt-2">
                  <p className="text-[#202020] text-sm">
                    {item?.type === "newTicket"
                      ? `${data?.user?.full_name} Open Ticket`
                      : item?.type === "userReply"
                      ? "User Reply"
                      : item?.type === "reAssign"
                      ? "Ticket Reassigned "
                      : item?.type === "changeStatus"
                      ? "Ticket Closed"
                      : item?.type === "adminReply"
                      ? " Admin Reply"
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ForthTicketPanel;
