import { Button } from "@mui/material";
import React from "react";

const AddButton = ({
  onClick,
  title,
  width = "171px",
  height = "48px",
  extraSx,
  InWhiteSpace = false,
  variant = "contained",
  secondDesign = false,
}) => {
  return (
    <Button
      onClick={onClick}
      color="secondary"
      variant={variant}
      sx={
        secondDesign
          ? {
              border: "2px solid #E9C237",
              borderRadius: "9px",
              background: "#ffffff",
              "&:hover": {
                background: "#ffffff",
                boxShadow: "none",
              },
              boxShadow: "none",
              width: "171px",
              height: "48px",
              color: "#E9C237",
              fontSize: "16px",
              textTransform: "none",
              display: "flex",
              alignSelf: "flex-end",
              alignItems: "flex-end",
              marginBottom: "20px",
            }
          : {
              borderRadius: "9px",
              background: InWhiteSpace ? "#FFF" : "#E9C237",
              "&:hover": {
                background: InWhiteSpace ? "#FFF" : "#E9C237",
                border: InWhiteSpace ? "1px solid #E9C237" : "",
                boxShadow: "none",
              },
              boxShadow: "none",
              width,
              height,
              color: InWhiteSpace ? "#E9C237" : "#FFF",
              border: InWhiteSpace ? "1px solid #E9C237" : "",
              fontSize: "16px",
              textTransform: "none",
              ...extraSx,
            }
      }
    >
      {title}
    </Button>
  );
};

export default AddButton;
