import { Dialog, DialogContent, Paper } from "@mui/material";
import { useState } from "react";
import { formDate } from "../../../services/helpers";
import { imageBaseUrl } from "../../../services/config";

const TicketMessage = ({ message, admin_id, created }) => {
  const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col mb-2" key={admin_id}>
      <p className="flex justify-center  mb-2 text-xs text-[#B3B3B3]">
        {formDate(created)}
      </p>
      <div
        className={` 
  flex ${!admin_id ? "justify-start" : "justify-end"} mb-2
  `}
      >
        <div
          className={`
  flex w-4/5
  ${!admin_id ? "flex-row" : " flex-row-reverse"} items-center
  `}
        >
          {imageExtensions.test(message) ? (
            <>
              <Dialog
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    boxShadow: "none",
                  },
                }}
              >
                <DialogContent
                  sx={{
                    width: "500px",
                    height: "778px",
                    padding: "0px",
                    overflow: "hidden",
                    backgroundColor: "transparent",
                  }}
                >
                  <img
                    src={`${imageBaseUrl}/${message}`}
                    alt="message"
                    className="w-full h-full object-contain"
                  />
                </DialogContent>
              </Dialog>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpen(true);
                }}
                src={`${imageBaseUrl}/${message}`}
                alt="message"
                width={"150px"}
              />
            </>
          ) : (
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                borderRadius: "12px",
                backgroundColor: admin_id ? "#E9C237" : "#F7F7F7",
                width: "35vw",
                border: "none",
                flexGrow: 1,
                ml: admin_id ? 1 : 0,
                mr: admin_id ? 0 : 1,
                overflow: "hidden",
              }}
            >
              <p
                className={`
                ${admin_id ? "text-white" : "text-black"}
                text-sm 
                `}
              >
                {message}
              </p>
            </Paper>
          )}
        </div>
      </div>
    </div>
  );
};
export default TicketMessage;
