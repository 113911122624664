import { Box, IconButton, InputBase, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../../styles";
import { useNavigate } from "react-router-dom";
import { useIngotPendingOrders } from "../../../services/apis/orders";
import { ItemsColumns } from "../../../components/common/Columns";
import { CustomLoadingOverlay } from "../../../components/common/CustomLoadingOverlay";
import NewTable from "../../../components/common/NewTable";
const SecondPendingTabPanel = () => {
  const navigate = useNavigate();
  const { data, isFetching } = useIngotPendingOrders({
    type: "ingot",
  });
  const handleClick = (params) => {
    const ingot = params.row;
    navigate(`/pendingOrders/${ingot.order_id}`);
  };
  const ingotsColumns = ItemsColumns([
    { field: "order_id", headerName: "Order ID" },
    { field: "phone", headerName: "User Phone number" },
    { field: "amount", headerName: "Total Ingots amount" },
    { field: "created", date: true },
    { field: "total", headerName: "Deposit" },
    // {
    //   field: "orderDetails",
    //   headerName: "Actions",
    //   actions: { view: (params) => handleClick(params) },
    // },
  ]);

  return (
    <Box>
      <div className="flex items-center rounded-xl bg-[#f5f5f5] p-2 w-[95%] md:w-4/5">
        <IconButton type="button" aria-label="search">
          <img alt="search-normal" src={`../../assets/search-normal.png`} />
        </IconButton>
        <InputBase
          // onChange={handleSearchInput}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Email or Phone number....."
        />
      </div>
      <Box
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
          pb: 2,
        }}
      >
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <NewTable
            noPagination
            columns={ingotsColumns}
            isLoading={isFetching}
            data={data?.data}
          />
          {/* <DataGrid
            components={{
              LoadingOverlay: () =>
                CustomLoadingOverlay({ columns: 5, rows: 2 }),
              Footer: () => null,
            }}
            rows={isFetching ? [] : data?.data}
            columns={ingotsColumns}
            loading={isFetching}
            autoHeight={true}
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
            }}
            getRowId={(row) => row?.order_id || ""}
            onRowClick={(params) => {
              handleClick(params);
            }}
          /> */}
        </Box>
      </Box>
    </Box>
  );
};
export default SecondPendingTabPanel;
