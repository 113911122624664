import { useNavigate, useParams } from "react-router-dom";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import arrowLeft from "../../../svg/arrow-circle-left.svg";
import TextView from "../../../components/common/TextView";
import { formDate } from "../../../services/helpers/index";
import { imageBaseUrl } from "../../../services/config";
import { useTransactionDetails } from "../../../services/apis/transactions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ImageDialog from "../../../components/common/ImageDialog";
import { useState } from "react";

const WithDrawView = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: transactionDetails, isFetching } = useTransactionDetails(id);
  if (isFetching) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      <DashboardMainHeader />
      <div className="mt-12 mx-6">
        <div className="flex items-center gap-[0.625rem] mb-8">
          <img
            src={arrowLeft}
            className="cursor-pointer"
            alt="Arrow Left"
            onClick={() => {
              navigate("/transactions");
            }}
          />
          <p className="text-2xl text-[#333333] ">View Withdraw </p>
        </div>
        <div className="bg-white p-12 rounded-xl">
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <TextView title={"Transaction ID"} value={transactionDetails?.id} />
            <hr className="text-[#e6e6e6] border-t-[1px] md:hidden " />

            <TextView
              title={"User Phone number"}
              value={transactionDetails?.phone}
            />
          </div>
          <hr className="text-[#e6e6e6] border-t-[1px]" />
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <TextView title={"IBAN"} value={transactionDetails?.iban} />
            <hr className="text-[#e6e6e6] border-t-[1px] md:hidden " />

            <TextView
              title={"Account Number"}
              value={transactionDetails?.account}
            />
          </div>

          <hr className="text-[#e6e6e6] border-t-[1px]" />
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <TextView
              title={"Transaction Amount"}
              value={`${transactionDetails?.amount} EGP`}
            />
            <hr className="text-[#e6e6e6] border-t-[1px] md:hidden " />
            <TextView
              title={"Payment Method"}
              value={transactionDetails?.paymentMethod}
            />
          </div>
          <hr className="text-[#e6e6e6] border-t-[1px]" />

          <div className="grid grid-cols-1 sm:grid-cols-2">
            <TextView
              title={"Created At"}
              value={formDate(transactionDetails?.created)}
            />
            <hr className="text-[#e6e6e6] border-t-[1px] md:hidden " />
            <TextView
              title={"Updated At"}
              value={formDate(transactionDetails?.modified)}
              hide={transactionDetails?.modified ? false : true}
            />
          </div>
          <hr className="text-[#e6e6e6] border-t-[1px]" />

          <div className="grid grid-cols-1 sm:grid-cols-2">
            <TextView
              title={"Transaction Status"}
              value={
                transactionDetails?.status_id === 2 ? "Approved" : "Rejected"
              }
            />
          </div>
        </div>
      </div>
      <ImageDialog
        imageUrl={`${imageBaseUrl}/${transactionDetails?.invoice}`}
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      />
    </div>
  );
};

export default WithDrawView;
