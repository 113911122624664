import { useState } from "react";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import Header from "../../../components/Header";
import FirstPendingTabPanel from "./FirstPendingTabPanel";
import SecondPendingTabPanel from "./SecondPendingTabPanel";
import ThirdPendingTabPanel from "./ThirdPendingTabPanel";
import { SecondBox } from "../../../components/common/CustomBox";
import NewTabs from "../../../components/common/NewTabs";
const PendingOrders = () => {
  const [value, setValue] = useState("1");
  const tabs = [
    { label: "All", value: "1" },
    { label: "Gold Ingots", value: "2" },
    { label: "Fractions", value: "3" },
  ];
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className="overflow-y-auto max-h-screen">
      <div className="ml-5 mb-8">
        <DashboardMainHeader />
      </div>
      <Header title="Pending Orders" marginL={"20px"} />
      <SecondBox>
        <div className="mt-10 h-auto bg-white rounded-lg">
          <NewTabs
            tabs={tabs}
            value={value}
            onChange={handleChange}
            className={"mx-10 my-5 bg-gray-100 rounded-lg"}
          />
          <div className="ml-4">
            {value === "1" && <FirstPendingTabPanel />}
            {value === "2" && <SecondPendingTabPanel />}
            {value === "3" && <ThirdPendingTabPanel />}
          </div>
        </div>
      </SecondBox>
    </div>
  );
};

export default PendingOrders;
