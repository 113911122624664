import { Box, Button, Step, StepLabel, Stepper } from "@mui/material";
import React, { useRef } from "react";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import { useState } from "react";
import CustomDialog from "../../components/CustomDialog";
import { useAddArticle } from "../../services/apis/Articles";
import StepOneArticle from "./StepOneArticle";
import StepTwoArticle from "./StepTwoArticle";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useStylesHook } from "../../components/common/useStyles";
import { stepsEA } from "../../data/steps";

const AddArticle = () => {
  const addArticleMutation = useAddArticle();

  const c = useStylesHook();
  const [activeStep, setActiveStep] = useState(0);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputKey, setInputKey] = useState(0);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);

  const [articlesPayload, setArticlesPayload] = useState({
    is_hidden: 0,
    sort: "",
    trans: [
      {
        local: "en",
        title: "",
        body: "",
      },
      {
        local: "ar",
        title: "",
        body: "",
      },
    ],
    image: "",
  });
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleFileInputChange = (event) => {
    if (event && event.target) {
      const selectedFile = event.target.files[0];
      if (selectedFile && selectedFile instanceof File) {
        setFile(selectedFile);
        const reader = new FileReader();
        reader.onload = (e) => {
          setImage(e.target.result);
        };
        reader.readAsDataURL(selectedFile);
        setArticlesPayload((prevPayload) => ({
          ...prevPayload,
          image: selectedFile,
        }));
        setInputKey(inputKey + 1);
      }
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFile(file);

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const fileInputRef = useRef();
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleDeleteImage = () => {
    setImage(null);
    setFile(null);
  };
  const isStepOneValid = () => {
    const { trans, sort } = articlesPayload;

    return (
      trans[0]?.title.trim() !== "" &&
      trans[0]?.body.trim() !== "" &&
      sort?.trim() !== "" &&
      image !== null
    );
  };

  const isStepTwoValid = () => {
    const { trans } = articlesPayload;
    return trans[1].title.trim() !== "" && trans[1].body.trim() !== "";
  };

  const isCurrentStepValid = () => {
    if (activeStep === 0) {
      return isStepOneValid();
    }
    if (activeStep === 1) {
      return isStepTwoValid();
    }
    return false;
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const bodySend = {
        data: JSON.stringify({
          sort: articlesPayload.sort,
          trans: articlesPayload.trans,
        }),
        image: file,
      };
      await addArticleMutation.mutateAsync(bodySend);
      setSuccessDialogOpen(true);
    } finally {
      setLoading(false);
    }
  };
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StepOneArticle
            setArticlesPayload={setArticlesPayload}
            articlesPayload={articlesPayload}
            handleDrop={handleDrop}
            handleDragOver={handleDragOver}
            handleFileInputChange={handleFileInputChange}
            fileInputRef={fileInputRef}
            handleUploadButtonClick={handleUploadButtonClick}
            image={image}
            file={file}
            handleDeleteImage={handleDeleteImage}
          />
        );
      case 1:
        return (
          <StepTwoArticle
            setArticlesPayload={setArticlesPayload}
            articlesPayload={articlesPayload}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <Box sx={{ mb: "3%" }}>
          <DashboardMainHeader />
        </Box>

        <Header title="Add Article" />
      </Box>
      <Box
        m="20px"
        sx={{
          height: "auto",
          background: "#FFFFFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08)",
          overflow: "auto",
          padding: "4%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Stepper className={c.root} activeStep={activeStep}>
            {stepsEA.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <>
            {renderStepContent(activeStep)}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              {activeStep !== 0 && (
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#ffffff",
                    },
                    backgroundColor: "#ffffff",
                    width: "200px",
                    height: "46px",
                    borderRadius: "12px",
                    color: "#E9C237",
                    border: "3px solid #E9C237",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  Back
                </Button>
              )}
              <Button
                disabled={!isCurrentStepValid() || loading}
                sx={{
                  "&:hover": { backgroundColor: "#E9C237" },
                  backgroundColor: "#E9C237",
                  width: "200px",
                  height: "46px",
                  borderRadius: "12px",
                  color: "#ffffff",

                  fontSize: "14px",
                }}
                onClick={
                  activeStep === stepsEA.length - 1 ? handleSave : handleNext
                }
              >
                {activeStep === stepsEA.length - 1 ? "Save" : "Next"}
              </Button>
            </Box>
          </>
        </Box>
      </Box>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Article has been added Successfully."
        message=""
        buttonText="Go to Articles"
        buttonLink="/articles"
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default AddArticle;
