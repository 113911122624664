import axios from "axios";

const getToken = () => {
  return localStorage.getItem("token");
};

export const authToken = getToken();
// export const dn =apiConfig.apiUrl
// export const imageBaseUrl = "https://sabika.s3.eu-central-1.amazonaws.com";
export const imageBaseUrl = "https://sabika-dev.s3.eu-central-1.amazonaws.com";
// export const dn = "https://api.sabika.app";
export const dn = "https://dev.api.sabika.app";

export const axiosInstance = axios.create({
  baseURL: dn,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers["Authorization"] = `Bearer ${token}`;
  config.headers["Content-Type"] =
    config.headers["Content-Type"] ?? `application/json`;

  return config;
});

const redirectToLogin = () => {
  window.location.href = "/login";
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      redirectToLogin();
    }

    return Promise.reject(error);
  }
);
