import TextFieldComponent from "../../components/TextFieldComponent";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { MyEditor } from "../../components/MyEditor";

const StepOneArticle = ({
  setArticlesPayload,
  articlesPayload,
  handleDrop,
  handleDragOver,
  handleFileInputChange,
  fileInputRef,
  handleUploadButtonClick,
  image,
  file,
  handleDeleteImage,
}) => {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  const handleTransChange = (local, field, newValue) => {
    setArticlesPayload((prevState) => {
      const updatedTrans = prevState?.trans?.map((transItem) => {
        if (transItem?.local === local) {
          return { ...transItem, [field]: newValue };
        }
        return transItem;
      });
      return { ...prevState, trans: updatedTrans };
    });
  };
  const ordersData = [1, 2, 3, 4, 5];
  return (
    <Box sx={{ marginTop: "3%" }}>
      <Box
        sx={{
          marginTop: "5%",
          width: "100%",
          marginLeft: "1%",
        }}
      >
        <FormLabel
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#4C4C4C",
          }}
          id="demo-row-radio-buttons-group-label"
        >
          Article Status
        </FormLabel>
        <RadioGroup
          style={{
            marginTop: "15px",
          }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(event) => {
            const selectedType = event.target.value;
            setArticlesPayload((prevPayload) => ({
              ...prevPayload,
              is_hidden: selectedType,
            }));
          }}
          value={articlesPayload.is_hidden}
        >
          <FormControlLabel
            style={{
              marginRight: "4%",
              color: "#4C4C4C",
              fontSize: "24px",
            }}
            value={0}
            control={
              <Radio
                sx={{
                  "&, &.Mui-checked": {
                    color: "#E9C237",
                  },
                  "&, &.Mui-unchecked": {
                    color: "#B3B3B3",
                  },
                }}
              />
            }
            label="Show"
          />
          <FormControlLabel
            style={{ color: "#4C4C4C", fontSize: "24px" }}
            value={1}
            control={
              <Radio
                onClick={() => {
                  setArticlesPayload((prevPayload) => ({
                    ...prevPayload,
                    sort: null,
                  }));
                }}
                sx={{
                  "&, &.Mui-checked": {
                    color: "#E9C237",
                  },
                  "&, &.Mui-unchecked": {
                    color: "#B3B3B3",
                  },
                }}
              />
            }
            label="Hide"
          />
        </RadioGroup>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: articlesPayload?.is_hidden === 0 ? "block" : "none",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#4C4C4C",
            marginBottom: "5px",
            marginTop: "15px",
          }}
        >
          Select Article Order
        </Typography>
        <FormControl sx={{ width: "100%" }}>
          <Select
            displayEmpty
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            value={articlesPayload?.sort}
            onChange={(event) => {
              const order = event.target.value;
              setArticlesPayload((prevPayload) => ({
                ...prevPayload,
                sort: String(order),
              }));
            }}
            inputProps={{ "aria-label": "Without label" }}
            renderValue={(selected) => {
              if (!selected) {
                return <em>Select Order Number</em>;
              }
              return selected; // This line returns the selected value for display
            }}
          >
            {ordersData.map((orderNumber) => (
              <MenuItem key={orderNumber} value={orderNumber}>
                {orderNumber}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Article Header"
        placeholder="Enter Article Header"
        value={
          articlesPayload?.trans?.find((transItem) => transItem.local === "en")
            .title
        }
        onChange={(event) =>
          handleTransChange("en", "title", event.target.value)
        }
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Box sx={{ marginTop: "15px" }}>
          <Typography
            sx={{ color: "#666666", fontSize: "16px", fontWeight: 400 }}
          >
            Article Image
          </Typography>
          <Box>
            {!image && (
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                style={{
                  marginTop: "5px",
                  width: "490px",
                  height: "135px",
                  border: "2px dashed #ccc",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  borderRadius: "12px",
                  background: "#FFF",
                  flexDirection: "column",
                }}
              >
                <img src={`../../assets/export.png`} alt="upload" />
                <Box sx={{ display: "flex" }}>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleFileInputChange}
                  />
                  {!image && (
                    <Button
                      sx={{
                        color: "#E9C237",
                        textDecoration: "underLine",
                        fontSize: "16px",
                        fontWeight: 400,
                        textTransform: "none",
                      }}
                      onClick={handleUploadButtonClick}
                    >
                      Click to upload
                    </Button>
                  )}
                  {image ? (
                    <img
                      src={image}
                      alt="Dropped"
                      height="152px"
                      width="100%"
                    />
                  ) : (
                    <p
                      style={{
                        color: "#333333",
                        fontSize: "16px",
                        marginTop: "8px",
                      }}
                    >
                      or drag and drop{"  "}
                    </p>
                  )}
                </Box>
                <Typography
                  sx={{
                    color: "#CCCCCC",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontFamily: "Roboto",
                    marginLeft: "5px",
                  }}
                >
                  SVG, PNG, JPG or GIF (max. 3MB)
                </Typography>
              </div>
            )}
          </Box>
          {file && (
            <Box
              sx={{
                width: "500px",
                borderRadius: "9px",
                background: "#F7F7F7",
                boxShadow: "none",
                display: "flex",
                height: "94px",
                padding: "16px",
                justifyContent: "space-between",
                marginTop: "5px",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <img src={`../../assets/document-upload.png`} alt="upload" />
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                    }}
                  >
                    {file.name}
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(0, 0, 0, 0.60)",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      letterSpacing: "0.17px",
                      wordSpacing: "4px",
                    }}
                  >
                    {Math.ceil(file.size / 1024)}kb • Complete
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Avatar
                  sx={{
                    bgcolor: "rgba(0, 51, 102, 0.1)",
                    height: "24px",
                    width: "24px",
                    alignSelf: "center",
                    marginRight: "15px",
                  }}
                >
                  <img src={`../../assets/checkcircle.png`} alt="circle" />
                </Avatar>

                <Avatar
                  onClick={handleDeleteImage}
                  sx={{
                    cursor: "pointer",
                    height: "24px",
                    width: "24px",
                    alignSelf: "center",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <img src={`../../assets/delete.png`} alt="delete" />
                </Avatar>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 400,
          color: "#4C4C4C",
          marginBottom: "5px",
          marginTop: "15px",
        }}
      >
        Article
      </Typography>
      <MyEditor
        placeholder={
          articlesPayload?.trans?.find((transItem) => transItem.local === "en")
            .body
            ? ""
            : "Enter Article"
        }
        text={
          articlesPayload?.trans?.find((transItem) => transItem.local === "en")
            .body
        }
        setText={(newContent) => {
          handleTransChange("en", "body", newContent);
        }}
      />
    </Box>
  );
};
export default StepOneArticle;
