import { Box, Typography, TextField, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import shariaLaw from "../../svg/sahriaLaw.svg";
import arrowLeft from "../../svg/arrow-circle-left.svg";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import ReusableSnackbar from "../../components/ReusableSnackbar";
const BadgeViewEdit = () => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({ name: "", description: "" });
  const navigate = useNavigate();
  const badge = useLocation()?.state?.badge;

  return (
    <Box>
      <Box
        sx={{
          margin: "0 0 1% 5%",
          display: "flex",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <Box sx={{ cursor: "pointer" }}>
          <img
            src={arrowLeft}
            alt=""
            onClick={() => {
              navigate("/app-badge");
            }}
          />
        </Box>
        <Typography component="span" fontSize={"24px"} fontWeight={"bold"}>
          {badge?.trans?.en || "badge name"}
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "3% 0% 2% 5%",
          height: "auto",
          width: "90%",
          borderRadius: "24px",
          background: "#FFF",
          margin: "auto",
        }}
      >
        <Box sx={{ float: "right", marginRight: "6%" }}>
          {isEdit ? (
            <Button
              color="secondary"
              variant="outlined"
              sx={{
                borderRadius: "9px",
                background: "#FFF",
                "&:hover": {
                  background: "#FFF",
                  border: "1px solid #E9C237",
                },
                width: "171px",
                height: "48px",
                color: "#E9C237",
                border: "1px solid #E9C237",
                fontSize: "16px",
                textTransform: "none",
              }}
              onClick={() => {
                setIsEdit(false);
                dispatch(
                  setData({
                    openSnack: true,
                    message: "Badge Successfully updated",
                  })
                );
              }}
            >
              Save
            </Button>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={() => {
                setIsEdit(true);
              }}
              style={{ cursor: "pointer" }}
            >
              <path
                d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z"
                fill="#00303C"
              />
              <path
                d="M8.49984 17.69C7.88984 17.69 7.32984 17.47 6.91984 17.07C6.42984 16.58 6.21984 15.87 6.32984 15.12L6.75984 12.11C6.83984 11.53 7.21984 10.78 7.62984 10.37L15.5098 2.49C17.4998 0.499998 19.5198 0.499998 21.5098 2.49C22.5998 3.58 23.0898 4.69 22.9898 5.8C22.8998 6.7 22.4198 7.58 21.5098 8.48L13.6298 16.36C13.2198 16.77 12.4698 17.15 11.8898 17.23L8.87984 17.66C8.74984 17.69 8.61984 17.69 8.49984 17.69ZM16.5698 3.55L8.68984 11.43C8.49984 11.62 8.27984 12.06 8.23984 12.32L7.80984 15.33C7.76984 15.62 7.82984 15.86 7.97984 16.01C8.12984 16.16 8.36984 16.22 8.65984 16.18L11.6698 15.75C11.9298 15.71 12.3798 15.49 12.5598 15.3L20.4398 7.42C21.0898 6.77 21.4298 6.19 21.4798 5.65C21.5398 5 21.1998 4.31 20.4398 3.54C18.8398 1.94 17.7398 2.39 16.5698 3.55Z"
                fill="#00303C"
              />
              <path
                d="M19.8501 9.83003C19.7801 9.83003 19.7101 9.82003 19.6501 9.80003C17.0201 9.06003 14.9301 6.97003 14.1901 4.34003C14.0801 3.94003 14.3101 3.53003 14.7101 3.41003C15.1101 3.30003 15.5201 3.53003 15.6301 3.93003C16.2301 6.06003 17.9201 7.75003 20.0501 8.35003C20.4501 8.46003 20.6801 8.88003 20.5701 9.28003C20.4801 9.62003 20.1801 9.83003 19.8501 9.83003Z"
                fill="#00303C"
              />
            </svg>
          )}
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Box sx={{ marginRight: "50px" }}>
            <img src={badge?.photo || shariaLaw} alt="" width={150} />
          </Box>

          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "100%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignSelf: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#808080",
                  }}
                >
                  Badge Name:
                </Typography>
                {isEdit ? (
                  <TextField
                    id="outlined-controlled"
                    label="badge Name"
                    value={formData?.name}
                    onChange={(event) => {
                      setFormData({ ...formData, name: event.target.value });
                    }}
                  />
                ) : (
                  <Typography
                    component="span"
                    style={{
                      marginLeft: "10px",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#808080",
                    }}
                  >
                    {badge?.trans?.en || "Badge Name"}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box style={{ borderBottom: "1px solid #E0E0E0", width: "96%" }} />

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#808080",
                  }}
                >
                  Badge Description :
                </Typography>
                {isEdit ? (
                  <TextField
                    id="outlined-controlled"
                    label="Badge Description"
                    value={formData?.description}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        description: event.target.value,
                      });
                    }}
                  />
                ) : (
                  <Typography
                    component="span"
                    style={{
                      marginLeft: "10px",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#808080",
                    }}
                  >
                    {badge?.inventory_amount ||
                      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nihil rerum minus molestias, similique voluptates eligendi ipsum fugiat labore nisi in magni itaque incidunt vero quod dolore quasi dicta ipsa ut!"}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <ReusableSnackbar />
    </Box>
  );
};

export default BadgeViewEdit;
