import { Box } from "@mui/material";
import { DataGridStyle } from "../../styles";
import { ItemsColumns } from "../../components/common/Columns";
import NewTable from "../../components/common/NewTable";

const SecondTabPanel = ({ role }) => {
  const rolesColumns = ItemsColumns([
    { field: "name", headerName: "Permission" },
    { field: "description", flex: 3 },
  ]);

  return (
    <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
      <NewTable
        noPagination
        columns={rolesColumns}
        isLoading={!role}
        data={role?.permissions}
      />
    </Box>
  );
};
export default SecondTabPanel;
