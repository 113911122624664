import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { editSplash } from "../../services/apis/splashscreen";
import LoadingSpinner from "../../components/LoadingSpinner";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { modalStyle } from "../../styles";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { MenuProps } from "../../components/common/MenuProps";

const EditSplashModal = React.forwardRef((props, ref) => {
  const lang = [
    { id: 1, local: "en", name: "English" },
    { id: 2, local: "ar", name: "Arabic" },
    { id: 3, local: "eg", name: "Egyptian Arabic" },
  ];
  const [formData, setFormData] = useState({
    local: "en",
    text: props?.splash?.trans?.en?.text,
    title: props?.splash?.trans?.en?.title,
    isActive: props?.splash?.isActive,
    sort: props?.splash?.sort,
  });

  const [errorMessage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleLangChange = (event) => {
    const selectedLang = event.target.value;
    const selectedLangObj = lang.find((item) => item.local === selectedLang);
    if (selectedLangObj) {
      if (props) {
        setFormData((prevState) => ({
          ...prevState,
          local: selectedLang,
          title:
            selectedLang === "en"
              ? props?.splash?.trans?.en?.title
              : selectedLang === "ar"
              ? props?.splash?.trans?.ar?.title
              : props?.splash?.trans?.eg?.title,
          text:
            selectedLang === "en"
              ? props?.splash?.trans?.en?.text
              : selectedLang === "ar"
              ? props?.splash?.trans?.ar?.text
              : props?.splash?.trans?.eg?.text,
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          local: selectedLang,
          title:
            prevState.trans.find((item) => item.local === selectedLang)
              ?.title || "",
          description:
            prevState.trans.find((item) => item.local === selectedLang)
              ?.description || "",
        }));
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const isEmpty = Object.values(formData).some(
        (value) => value === "" || value === undefined
      );

      if (isEmpty) {
        setIsLoading(false);
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all fields.",
          })
        );
        return;
      }
      const response = await editSplash(props.splash.id, formData);
      if (response.data.status === 200) {
        setIsLoading(false);
        dispatch(
          setData({
            severity: "success",
            openSnack: true,
            message: "Splash Updated Successfully",
          })
        );
        setFormData({
          local: "en",
          text: "",
          title: "",
          isActive: 2,
          sort: "",
        });
      }
      props.handleClose();
    } catch (error) {
      setIsLoading(false);
      setIsLoading(false);
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };
  const handleChange = (e) => {
    const newValue =
      e.target.name === "isActive"
        ? e.target.checked
          ? 1
          : 2
        : e.target.value;
    setFormData({ ...formData, [e.target.name]: newValue });
  };
  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Box
        m="20px"
        display="grid"
        gridTemplateColumns="reapeat(1, 1fr)"
        alignContent="space-between"
        sx={modalStyle}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1 },
            display: "grid",
            gridTemplateColumns: "repeat(2, 2fr)",
            height: "auto",
            p: "10px",
            alignContent: "space-around",
            borderRadius: "5px",
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h3" gridColumn="span 2">
            Edit Splash
          </Typography>
          <Box sx={{ flexDirection: "column", display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "300px" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#4C4C4C",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  Select Language
                </Typography>
                <FormControl sx={{ width: "97%" }}>
                  <Select
                    displayEmpty
                    onChange={handleLangChange}
                    value={formData?.local || ""}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>{formData.title}</em>;
                      }
                      return (
                        lang.find((item) => item.local === selected)?.name || ""
                      );
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {lang.map((item) => (
                      <MenuItem key={item.local} value={item.local}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <FormControl sx={{ width: "300px", marginTop: "45px" }}>
                <InputLabel htmlFor="component-outlined" color="secondary">
                  Text
                </InputLabel>
                <OutlinedInput
                  id="component-outlined"
                  label="Text"
                  type="text"
                  name="text"
                  value={formData.text}
                  onChange={handleChange}
                  color="secondary"
                  required
                />
                {errorMessage?.text && (
                  <FormHelperText
                    id="component-error-text"
                    sx={{ color: "red" }}
                  >
                    {errorMessage.text}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <FormControl sx={{ width: "300px" }}>
                <InputLabel htmlFor="component-outlined" color="secondary">
                  Title
                </InputLabel>
                <OutlinedInput
                  id="component-outlined"
                  label="title"
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  color="secondary"
                  required
                />
                {errorMessage?.title && (
                  <FormHelperText
                    id="component-error-text"
                    sx={{ color: "red" }}
                  >
                    {errorMessage.title}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl sx={{ width: "300px" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt="10px"
                >
                  <Checkbox
                    label="Is Active"
                    checked={formData.isActive === 1}
                    color="secondary"
                    name="isActive"
                    onChange={(e) => {
                      const newIsActiveValue = e.target.checked ? 1 : 2;
                      setFormData((prevData) => ({
                        ...prevData,
                        [e.target.name]: newIsActiveValue,
                      }));
                    }}
                  />
                  <Typography>Active</Typography>
                </Box>
                {errorMessage.isActive && (
                  <FormHelperText
                    id="component-error-text"
                    sx={{ color: "red" }}
                  >
                    {errorMessage.isActive}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box gridColumn="span 2 " display="flex" justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#E9C237",
                  height: "40px",
                  width: "100px",
                  "&:hover": { backgroundColor: "#E9C237" },
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
        <ReusableSnackbar />
      </Box>
    </>
  );
});

export default EditSplashModal;
