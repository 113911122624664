import { useState } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../styles";
import { useNavigate } from "react-router-dom";
import ActionAlert from "../user-profile/ActionAlert";
import CustomDialog from "../../components/CustomDialog";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import EditBlockReasons from "./EditBlockReasons";
import { handlePageChange } from "../../services/utils/filters";
import {
  useDeleteClosedReason,
  useFetchClosedReasons,
} from "../../services/apis/closedReasons";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import AddButton from "../../components/common/AddButton";
import { ItemsColumns } from "../../components/common/Columns";
import { CustomLoadingOverlay } from "../../components/common/CustomLoadingOverlay";
import NewTable from "../../components/common/NewTable";
const ClosedReasons = () => {
  const [disableWhileDelete, setDisableWhileDelete] = useState(false);
  const deleteClosedReasonMutation = useDeleteClosedReason();

  const [open, setOpen] = useState(false);
  const [selectedBlockReasonId, setBlockReasonId] = useState(null);
  const [selectedBlockReason, setSelectedBlockReason] = useState(null);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);

  const navigate = useNavigate();
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
  });
  const { data: closedReasons, isFetching: loadingClosedReasons } =
    useFetchClosedReasons(queryParameters);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddFAQ = () => {
    navigate(`/AddClosedReason`);
  };
  const handleOpenDeleteAlert = (id) => {
    setBlockReasonId(id);
    setOpenDeletedAlert(true);
  };
  const handleEditBlockReason = (blockReasonId) => {
    setSelectedBlockReason(blockReasonId);
    setOpen(true);
  };
  const handleDeleteBlockReason = async () => {
    if (selectedBlockReasonId) {
      setDisableWhileDelete(true);
      try {
        await deleteClosedReasonMutation.mutateAsync(selectedBlockReasonId);
        setDisableWhileDelete(false);
        setSuccessDialogOpen(true);
        handleCloseDeleteAlert();
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    }
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const blockReasonsColumns = ItemsColumns([
    {
      field: "reason",
      specialRender: "customRender",
      renderFunction: (params) => params?.row?.trans?.en,
    },
    { field: "type", headerName: "Reason For" },
    {
      field: "action",
      headerName: "Actions",
      actions: {
        edit: (params) => {
          handleEditBlockReason(params.row.id);
        },
        delete: (params) => {
          handleOpenDeleteAlert(params.row.id);
        },
      },
    },
  ]);
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <DashboardMainHeader />

      <Header title="Closed Reasons for Tickets" marginL={"25px"} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: 2,
          pr: 2,
        }}
      >
        <AddButton
          title={"+ New reason"}
          onClick={() => {
            handleAddFAQ();
          }}
        />
      </Box>
      <Box
        m="20px"
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <NewTable
            columns={blockReasonsColumns}
            isLoading={loadingClosedReasons}
            data={closedReasons?.result}
            totalPages={closedReasons?.totalPages}
            totalItems={closedReasons?.totalItems}
            handlePageChange={(newPage) =>
              handlePageChange(newPage, queryParameters, setQueryParameters)
            }
            currentPage={queryParameters}
          />
          {/* <DataGrid
            components={{
              LoadingOverlay: () => CustomLoadingOverlay({ columns: 3 }),
            }}
            rows={loadingClosedReasons ? [] : closedReasons?.result}
            columns={blockReasonsColumns}
            loading={loadingClosedReasons}
            rowsPerPageOptions={[10]}
            pageSize={10}
            totalPages={closedReasons?.totalPages}
            rowCount={closedReasons?.totalItems || 0}
            paginationMode="server"
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
            localeText={{
              noRowsLabel: "No Block Reasons",
            }}
          /> */}
        </Box>
      </Box>
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteBlockReason}
        message={`Are you sure you want to delete this Reason ?`}
        label={"Delete"}
        disabled={disableWhileDelete}
      />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Reason has been deleted Successfully."
        message=""
        buttonText="Go to Block Reason"
      />
      <EditBlockReasons
        closedReasons={closedReasons?.result}
        blockReasonsId={selectedBlockReason}
        open={open}
        onClose={handleClose}
        label={"Save"}
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default ClosedReasons;
