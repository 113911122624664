import React from "react";
import { Typography, Box, Divider } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import UserInfoField from "../../user-profile/UserInfoField";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import arrowLeft from "../../../svg/arrow-circle-left.svg";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../../styles";
import { useSerialDetails } from "../../../services/apis/inventory";
import { formDate } from "../../../services/helpers";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { ItemsColumns } from "../../../components/common/Columns";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { CustomLoadingOverlay } from "../../../components/common/CustomLoadingOverlay";

const SerialDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: serialDetails, isFetching } = useSerialDetails(id);
  const requestsColumns = ItemsColumns([
    { field: "serial", headerName: "Serial Number" },
    {
      field: "weight",
      headerName: "Weight",
    },
    {
      field: "full_name",
      headerName: "Username",
    },
    {
      field: "phone",
      headerName: "User phone number",
    },
    { field: "created", headerName: "Created At", date: true },
  ]);

  if (isFetching) return <LoadingSpinner />;
  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <DashboardMainHeader />
      <Box
        sx={{
          margin: "0 0 1% 2%",
          display: "flex",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <Box sx={{ cursor: "pointer" }}>
          <img
            src={arrowLeft}
            alt=""
            onClick={() => {
              navigate(-1);
            }}
          />
        </Box>
        <Typography component="span" fontSize={"24px"} fontWeight={"400"}>
          {serialDetails?.serial} Ingots Details
        </Typography>
      </Box>
      <Box
        m="20px"
        sx={{
          display: "flex",
          width: "95%",
          flexDirection: "column",
          gap: 2,
          background: "#FFFFFF",
          alignItems: "center",
          borderRadius: "24px",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "95%",
            marginTop: "3%",
            marginBottom: "3%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: { xs: 2, sm: 3 },
                alignItems: "flex-start",
              }}
            >
              <UserInfoField title="Order ID" data={serialDetails?.order_id} />
              <UserInfoField title="Product" data={serialDetails?.name} />
              <UserInfoField
                title="Product Status"
                data={
                  serialDetails?.productStatus === 1
                    ? "In Stock"
                    : "Out of Stock"
                }
              />
            </Box>
            <Divider sx={{ backgroundColor: "#E6E6E6" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: { xs: 2, sm: 3 },
                alignItems: "flex-start",
              }}
            >
              <UserInfoField title="weight" data={serialDetails?.name} />
              <UserInfoField
                title="Available weight:"
                data={serialDetails?.availableWeight}
              />
              <UserInfoField
                title="Sold weight"
                data={serialDetails?.sold_weight}
              />
            </Box>
            <Divider sx={{ backgroundColor: "#E6E6E6" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: { xs: 2, sm: 3 },
                alignItems: "flex-start",
              }}
            >
              <UserInfoField
                title="Storage Location"
                data={serialDetails?.location}
              />
              <UserInfoField
                title="Ingot status"
                color={serialDetails?.ingotStatus === 1 ? "#28A745" : "#CF2424"}
                data={
                  serialDetails?.ingotStatus === 1 ? "In Stock" : "Out of Stock"
                }
              />
              {serialDetails?.modified && (
                <UserInfoField
                  title="Last update"
                  data={formDate(serialDetails?.modified)}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {serialDetails?.serials?.length !== 0 && (
        <Box
          m="20px"
          sx={{
            display: "flex",
            width: "95%",
            flexDirection: "column",
            gap: 2,
            background: "#FFFFFF",
            alignItems: "center",
            borderRadius: "24px",
            height: "auto",
          }}
        >
          <Typography
            sx={{
              alignSelf: "flex-start",
              color: "#333333",
              fontSize: "18px",
              fontWeight: 400,
              marginLeft: "2%",
              marginTop: "2%",
            }}
          >
            Serials
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "auto",
              background: "#FFF",
              borderRadius: "12px",
            }}
          >
            <Box
              m="40px 0 0 0"
              height="auto"
              sx={[
                DataGridStyle,
                {
                  overflowX: "auto",
                  "& .MuiDataGrid-columnHeader:first-of-type": {
                    backgroundColor: "#ffffff",
                    borderBottom: "none",
                    pointerEvents: "auto",
                  },
                },
              ]}
            >
              <DataGrid
                components={{
                  LoadingOverlay: () => CustomLoadingOverlay({ columns: 5 }),
                }}
                pageSize={10}
                disableColumnMenu={true}
                rows={isFetching ? [] : serialDetails?.serials}
                loading={isFetching}
                rowsPerPageOptions={[10]}
                columns={requestsColumns}
                sx={{
                  backgroundColor: "#ffffff",
                  height: "650px",
                  minWidth: "1000px",
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      <ReusableSnackbar />
    </Box>
  );
};

export default SerialDetails;
