import React from "react";
import { Box, Typography, Checkbox } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  handleIsOnlineStatus,
  handleIsRoleType,
} from "../../services/utils/filters";
import { useEffect } from "react";
import { useState } from "react";

const FilteringOptions = ({
  filterList,
  queryParameters,
  setQueryParameters,
  k,
  handleCheckbox,
  firstTitle,
  isUsers,
  isRole,
  isGold,
}) => {
  const [checkedItems, setCheckedItems] = useState({});
  useEffect(() => {
    const checkedFromParams = queryParameters[k] || [];
    const newCheckedItems = {};
    checkedFromParams.forEach((id) => {
      newCheckedItems[id] = true;
    });
    setCheckedItems(newCheckedItems);
  }, [filterList, k, queryParameters]);
  const handleCheckboxChange = (event, value) => {
    const isChecked = event.target.checked;
    const updatedCheckedItems = { ...checkedItems, [value]: isChecked };
    setCheckedItems(updatedCheckedItems);
    if (firstTitle !== "Transaction Type") {
      const updatedQueryParams = {
        ...queryParameters,
        [k]: isChecked
          ? [...(queryParameters[k] || []), value]
          : queryParameters[k]?.filter((id) => id !== value),
      };
      setQueryParameters(updatedQueryParams);
    }
    handleCheckbox(event);
  };

  return isUsers ? (
    <Box sx={{ ml: 2 }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="is_online"
        value={queryParameters.is_online}
        onChange={(e) =>
          handleIsOnlineStatus(
            e.target.value,
            queryParameters,
            setQueryParameters
          )
        }
      >
        <FormControlLabel value="true" control={<Radio />} label="Online" />
        <FormControlLabel value="false" control={<Radio />} label="Offline" />

        <FormControlLabel value="all" control={<Radio />} label="All" />
      </RadioGroup>
    </Box>
  ) : isRole ? (
    <Box sx={{ ml: 2 }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="Type"
        value={queryParameters.role_type}
        onChange={(e) =>
          handleIsRoleType(e.target.value, queryParameters, setQueryParameters)
        }
      >
        <FormControlLabel value="all" control={<Radio />} label="All" />
        <FormControlLabel value="true" control={<Radio />} label="Built-In" />

        <FormControlLabel value="false" control={<Radio />} label="Created" />
      </RadioGroup>
    </Box>
  ) : isGold ? (
    <Box sx={{ ml: 2 }}>
      {filterList?.map((t) => (
        <Box key={t.name} sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            value={t.id}
            checked={queryParameters[k]?.includes(t.id)}
            onChange={handleCheckbox}
          />
          <Typography>{t?.trans?.en}</Typography>
        </Box>
      ))}
    </Box>
  ) : (
    <Box sx={{ ml: 2 }}>
      {filterList?.map((t) => (
        <Box key={t.name} sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            value={t.id}
            checked={
              firstTitle === "Transaction Type"
                ? checkedItems[t.id] || false
                : queryParameters[k]?.includes(t.id)
            }
            onChange={
              firstTitle === "Transaction Type"
                ? (event) => handleCheckboxChange(event, t.id)
                : handleCheckbox
            }
            disabled={
              firstTitle === "Transaction Type"
                ? Object.values(checkedItems).filter(Boolean).length === 1 &&
                  checkedItems[t.id]
                : false
            }
          />
          <Typography>{t.name}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default FilteringOptions;
