import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import useChart from "./UseChart";
import Chart from "./Chart";
import { InputBase, Paper } from "@mui/material";
import { useRef } from "react";

import "apexcharts/dist/apexcharts.css";

export default function DashboardCitiesChart({
  title,
  subheader,
  chart,
  queryParameters,
  setQueryParameters,
  height,
  width,
  isFetching,
  ...other
}) {
  const { labels, colors, series, options } = chart;
  const chartRef = useRef();

  const chartOptions = useChart({
    colors,
    plotOptions: {
      bar: {
        columnWidth: "80%",
        horizontal: false,
      },
      line: {
        curve: "straight",
      },
    },
    fill: {
      type: Array.isArray(series) ? series.map((i) => i.fill) : [],
    },
    labels,
    axisX: {
      position: "start",
    },
    xaxis: {
      position: "start",
      type: "category",
    },
    series: {
      line: {
        curve: "straight",
      },
    },
    stroke: {
      curve: "straight",
    },
    chart: {
      toolbar: {},
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value) => {
          if (typeof value !== "undefined") {
            return `${value.toFixed(0)}`;
          }
          return value;
        },
      },
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Paper
        component="form"
        sx={{
          marginLeft: "4%",
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
          width: "90%",
          height: "48px",
          backgroundColor: "#F5F5F5",
          boxShadow: "none",
          borderRadius: "12px",
        }}
        // onSubmit={handleSubmit}
      >
        <InputBase
          //   onChange={handleSearchInput}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Filter By Date and Cities ....."
        />
        {/* <FilterDrawer /> */}
      </Paper>
      <Box
        sx={{
          p: 3,
          pb: 1,
          width: "96%", // Ensure full width
        }}
      >
        {isFetching ? (
          <table className="w-full">
            <tbody>
              <tr className="animate-pulse">
                <td className="py-2 whitespace-nowrap w-full">
                  <div className="h-[21.6rem] bg-gray-200 rounded w-full" />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <Chart
            ref={chartRef}
            dir="ltr"
            series={series}
            options={chartOptions}
            width={width}
            height={height}
          />
        )}
      </Box>
    </Card>
  );
}

DashboardCitiesChart.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
};
