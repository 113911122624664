import { useMutation, useQuery } from "react-query";
import { axiosInstance as axios } from "../config";

export const sendToAll = async (body) => {
  try {
    const response = await axios.post(`/api/notifications/push-all`, body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const sendSegmentedNotifications = async (body) => {
  try {
    const response = await axios.post(
      `/api/notifications/pushBySegmentation`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchSegmented = async (q) => {
  try {
    const response = await axios.get(`/api/notifications/findSegmented`, {
      params: {
        page: q.page,
      },
    });
    return response.data.data; // Assuming you want to return the data part of the response
  } catch (error) {
    throw error;
  }
};

export const useFetchSegmented = (q) => {
  return useQuery(["segmented", q], () => fetchSegmented(q), {
    // Additional options can be added here
    keepPreviousData: true,
  });
};
export const fetchNotificationsCities = async () => {
  try {
    const response = await axios.get(`/api/notifications/cities`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchNotificationsAreas = async (body) => {
  try {
    const response = await axios.post(`/api/notifications/areas`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

// Create a custom hook that uses useQuery to fetch the data
export const useNotificationsCities = () => {
  return useQuery(["notificationsCities"], fetchNotificationsCities);
};

export const fetchNotificationsAreas1 = async (body) => {
  if (!body?.cityIds || body?.cityIds.length === 0) {
    return Promise.resolve([]); // Return an empty array or any default value
  }
  try {
    const response = await axios.post(`/api/notifications/areas`, {
      cityIds: body?.cityIds,
    });
    return response.data; // Assuming the relevant data is in the 'data' property
  } catch (error) {
    throw error;
  }
};

export const useFetchNotificationsAreas = () => {
  return useMutation((body) => fetchNotificationsAreas1(body));
};
