import { useQuery, useMutation, useQueryClient } from "react-query";
import { axiosInstance as axios } from "../config";

// Fetch closed reasons with pagination
export const useFetchClosedReasons = (q) => {
  return useQuery(["closedReasons", q.page], async () => {
    const response = await axios.get(`/api/contactUsCloseReasons/index`, {
      params: {
        page: q.page,
      },
    });
    return response.data?.data; // Assuming response.data contains the closed reasons
  });
};

// Fetch all closed reasons for dropdown
export const useFetchAllClosedReasons = (q) => {
  return useQuery(["allClosedReasons", q.type], async () => {
    const response = await axios.get(`/api/contactUsCloseReasons/dropdown`, {
      params: {
        type: q.type,
      },
    });
    return response?.data?.data; // Assuming response.data contains all closed reasons
  });
};

// Add a closed reason
export const useAddClosedReason = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (body) => {
      const response = await axios.post("/api/contactUsCloseReasons/add", body);
      return response.data; // Assuming response.data contains the added closed reason
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["closedReasons"]); // Adjust query key as needed
        queryClient.invalidateQueries(["allClosedReasons"]); // Adjust query key as needed
      },
    }
  );
};

// Delete a closed reason
export const useDeleteClosedReason = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id) => {
      const response = await axios.get(
        `/api/contactUsCloseReasons/delete/${id}`
      );
      return response.data; // Assuming response.data contains the result of deletion
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["closedReasons"]); // Adjust query key as needed
        queryClient.invalidateQueries(["allClosedReasons"]); // Adjust query key as needed
      },
    }
  );
};

// Edit a closed reason
export const useEditClosedReason = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, body }) => {
      const response = await axios.post(
        `/api/contactUsCloseReasons/edit/${id}`,
        body
      );
      return response.data; // Assuming response.data contains the updated closed reason
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["closedReasons"]); // Adjust query key as needed
        queryClient.invalidateQueries(["allClosedReasons"]); // Adjust query key as needed
      },
    }
  );
};
