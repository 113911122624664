// store.js
import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "./slices/authenticationSlice";
import errorMessageReducer from "./slices/errorMessageSlice";
import sidebarReducer from "./slices/sidebarSlice";
import ticketsReducer from "./slices/ticketSlice";
import globalReducer from "./slices/globalSlice";
export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    errorMessage: errorMessageReducer,
    sideBar: sidebarReducer,
    tickets: ticketsReducer,
    global: globalReducer,
  },
});
