import { Box, Divider, Skeleton, Typography } from "@mui/material";
import { formDate } from "../../services/helpers";

const FirstTabPanel = ({ role }) => {
  return (
    <Box>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "45%",
          }}
        >
          <Typography sx={{ color: "#808080", fontSize: "14px" }}>
            Role name:
          </Typography>
          <Typography
            sx={{
              marginLeft: "5px",
              color: "#808080",
              fontSize: "14px",
            }}
          >
            {role ? role?.name : <Skeleton sx={{ width: 200 }} />}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // marginLeft: "30%",
          }}
        >
          <Typography sx={{ color: "#808080", fontSize: "14px" }}>
            Type :
          </Typography>
          <Typography
            sx={{
              marginLeft: "5px",
              color: "#808080",
              fontSize: "14px",
            }}
          >
            {role?.is_built === 0 ? "Created" : "Built-In"}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "45%",
          }}
        >
          <Typography sx={{ color: "#808080", fontSize: "14px" }}>
            Creation date :
          </Typography>
          <Typography
            sx={{
              marginLeft: "5px",
              color: "#808080",
              fontSize: "14px",
            }}
          >
            {role ? formDate(role?.created) : <Skeleton sx={{ width: 200 }} />}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#808080", fontSize: "14px" }}>
            Category :
          </Typography>
          <Typography
            sx={{
              marginLeft: "5px",
              color: "#808080",
              fontSize: "14px",
            }}
          >
            {role ? role?.category : <Skeleton sx={{ width: 200 }} />}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ flexDirection: "row", display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "45%",
          }}
        >
          <Typography sx={{ color: "#808080", fontSize: "14px" }}>
            Number of users :
          </Typography>
          <Typography
            sx={{
              marginLeft: "5px",
              color: "#808080",
              fontSize: "14px",
            }}
          >
            {role ? role?.totalUsers : <Skeleton sx={{ width: 200 }} />}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#808080", fontSize: "14px" }}>
            Status :
          </Typography>
          <Typography
            sx={{
              marginLeft: "5px",
              color: "#3284FF",
              fontSize: "14px",
            }}
          >
            Active
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography sx={{ color: "#808080", fontSize: "14px" }}>
          Description :
        </Typography>
        <Typography
          sx={{
            marginLeft: "5px",
            color: "#808080",
            fontSize: "14px",
          }}
        >
          {role ? role?.description : <Skeleton sx={{ width: 200 }} />}
        </Typography>
      </Box>
    </Box>
  );
};
export default FirstTabPanel;
