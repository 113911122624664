import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 px-6">
      <div className="text-center">
        <h1 className="text-6xl font-extrabold text-gray-800 mb-4">404</h1>
        <p className="text-2xl font-semibold text-gray-700">
          Oops! Page Not Found
        </p>
        <p className="mt-2 text-gray-500">
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </p>
        <div className="mt-6">
          <Link
            to={"/AdminProfile"}
            className="px-6 py-3 bg-[#E9C237] text-white rounded-md  transition duration-300"
          >
            Go To Profile
          </Link>
        </div>
      </div>
    </div>
  );
};
