import { useState } from "react";
import { IconButton, InputBase, Paper } from "@mui/material";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { checkString, handlePageChange } from "../../services/utils/filters";
import FilterDrawer from "../../components/common/FilterDrawer";
import { useFetchFractions } from "../../services/apis/transactions";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import { DataGridStyle } from "../../styles";
import NewTable from "../../components/common/NewTable";

const FractionsTransfer = () => {
  const [queryParameters, setQueryParameters] = useState({
    date_from: null,
    date_to: null,
    orderBy: null,
    sort: null,
    page: 0,
    phone: null,
    goldTransactionType: [3, 4],
  });
  const { data, isFetching } = useFetchFractions(queryParameters);
  const handleSearchInput = (e) => {
    const { value } = e.target;
    checkString(value, queryParameters, setQueryParameters);
  };
  const fractionsTransferColumns = ItemsColumns([
    { field: "id", headerName: "Transaction ID" },
    { field: "senderPhone", headerName: "From" },
    {
      field: "receiverPhone",
      headerName: "To",
      specialRender: "customRender",
      renderFunction: (params) => params?.row?.receiver?.phone,
    },
    {
      field: "total_weight",
      headerName: "Amount",
      specialRender: "totalWeight",
    },
    {
      field: "created",
      headerName: "Date",
      date: true,
    },
  ]);

  return (
    <Box>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Fractions Transfer" marginL={"20px"} />
      <Box sx={{ display: "flex", width: "100%", gap: 2, p: 2 }}>
        <div className="flex items-center rounded-xl bg-white p-2 w-[95%] md:w-3/5">
          <IconButton type="button" aria-label="search">
            <img alt="search-normal" src={`../../assets/search-normal.png`} />
          </IconButton>
          <InputBase
            onChange={handleSearchInput}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search National Id or Phone number....."
          />
          <FilterDrawer
            queryParameters={queryParameters}
            setQueryParameters={setQueryParameters}
          />
        </div>
      </Box>
      <SecondBox>
        <Box height="100%" sx={{ ...DataGridStyle, overflowX: "auto" }}>
          <NewTable
            columns={fractionsTransferColumns}
            isLoading={isFetching}
            data={data?.data?.result}
            totalItems={data?.data?.totalItems}
            totalPages={data?.data?.totalPages}
            handlePageChange={(newPage) =>
              handlePageChange(newPage, queryParameters, setQueryParameters)
            }
            currentPage={queryParameters}
          />
        </Box>
      </SecondBox>
    </Box>
  );
};

export default FractionsTransfer;
