import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const LoadingSpinner = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 2,
          flexDirection: "column",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    </Box>
  );
};

export default LoadingSpinner;
