import {
  Button,
  Box,
  Typography,
  IconButton,
  Avatar,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import { useEffect } from "react";
import {
  addNewFractionProduct,
  addNewProduct,
  editProductInfo,
} from "../../services/apis/products";
import UploadImage from "../../components/UploadImage";
import { IoIosCloseCircleOutline } from "react-icons/io";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import useWindowSize from "../../hooks/useWindowSize";

const ProductModal = ({
  open,
  onClose,
  label,
  setProducts,
  fetchData,
  products,
  productId,
  functionTypeParams,
  queryParameters,
  value,
}) => {
  const { width } = useWindowSize();
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [typeParams, setTypeParams] = useState("ingot");
  const [inputKey, setInputKey] = useState(0);
  const dispatch = useDispatch();

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const handleDeleteImage = () => {
    setImage(null);
    setFile(null);
  };

  // const fileInputRef = useRef(null);
  // edit image start
  const [hasChanges, setHasChanges] = useState(false);
  // const handleEditClick = () => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click();
  //     setHasChanges(true);
  //   }
  // };
  const resetImageState = () => {
    if (hasChanges) {
      setImage(null);
      setHasChanges(false);
    }
  };

  // edit image end
  const [productsPayload, setProductsPayload] = useState(() => {
    let initialTrans = [];
    if (typeParams === "ingot") {
      initialTrans = [
        {
          local: "ar",
          name: "",
        },
        {
          local: "en",
          name: "",
        },
        {
          local: "eg",
          name: "",
        },
      ];
    } else if (typeParams === "fraction") {
      initialTrans = [
        {
          local: "en",
          name: "",
        },
      ];
    }
    return {
      trans: initialTrans,
      local: "en",
      name: "",
      weight: null,
      karat: 21,
      inventory_type: typeParams === "ingot" ? "ingot" : "fraction",
      availability: 1,
      stored_fees: null,
      photo: file,
    };
  });
  const handleTransChange = (local, newValue) => {
    setProductsPayload((prevState) => {
      const updatedTrans = prevState?.trans?.map((transItem) => {
        if (transItem?.local === local) {
          return { ...transItem, name: newValue };
        }
        return transItem;
      });
      return { ...prevState, trans: updatedTrans };
    });
  };
  const handleStockStatusChange = (event) => {
    const updatedProductData = {
      ...productsPayload,
      availability: parseInt(event.target.value, 10),
    };
    setProductsPayload(updatedProductData);
  };
  const productToEdit = products?.find((product) => product?.id === productId);
  // const lang = [
  //   { id: 1, local: "en", name: "English" },
  //   { id: 2, local: "ar", name: "Arabic" },
  //   { id: 3, local: "eg", name: "Egyptian Arabic" },
  // ];
  // const handleLangChange = (event) => {
  //   const selectedLang = event.target.value;
  //   const selectedLangObj = lang?.find((item) => item.local === selectedLang);
  //   if (selectedLangObj) {
  //     if (productToEdit) {
  //       setProductsPayload((prevState) => ({
  //         ...prevState,
  //         local: selectedLang,
  //         name:
  //           selectedLang === "en"
  //             ? productToEdit?.trans?.en
  //             : selectedLang === "ar"
  //             ? productToEdit?.trans?.ar
  //             : productToEdit?.trans?.eg,
  //       }));
  //     } else {
  //       setProductsPayload((prevState) => ({
  //         ...prevState,
  //         local: selectedLang,
  //         name:
  //           prevState.trans.find((item) => item.local === selectedLang)?.name ||
  //           "",
  //       }));
  //     }
  //   }
  // };
  const handleFileInputChange = (event) => {
    if (event && event.target) {
      const selectedFile = event.target.files[0];
      if (selectedFile && selectedFile instanceof File) {
        setFile(selectedFile);
        const reader = new FileReader();
        reader.onload = (e) => {
          setImage(e.target.result);
        };
        reader.readAsDataURL(selectedFile);
        setProductsPayload((prevPayload) => ({
          ...prevPayload,
          photo: selectedFile,
        }));
        setInputKey(inputKey + 1);
      }
    }
  };

  const resetForm = () => {
    if (!productId) {
      setProductsPayload({
        trans: [
          {
            local: "ar",
            name: "",
          },
          {
            local: "en",
            name: "",
          },
          {
            local: "eg",
            name: "",
          },
        ],
        weight: null,
        karat: null,
        inventory_type: null,
        availability: 1,
        stored_fees: null,
        photo: "",
      });
    }
    onClose();
  };
  const handleCloseDialog = () => {
    resetForm();
    resetImageState();
  };
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsSubmitting(true);
      if (
        file === null &&
        productsPayload.inventory_type === "ingot" &&
        !productId
      ) {
        dispatch(
          setData({
            openSnack: true,
            message: "You Should Fill All The Required Fields for products",
          })
        );

        return;
      }
      if (productId) {
        if (value === "1") {
          const isValidEdit = (payload) => {
            const requiredFields = ["name", "stored_fees", "local"];
            for (const field of requiredFields) {
              if (!payload[field]) {
                console.error(`Field '${field}' is empty`);
                return false;
              }
            }
            return true;
          };

          if (!isValidEdit(productsPayload)) {
            dispatch(
              setData({
                openSnack: true,
                message: "You Should Fill All The Required Fields for products",
              })
            );
            return;
          }
        }
        if (value === "1") {
          const bodyEdit = {
            data: JSON.stringify({
              local: productsPayload.local,
              name: productsPayload.name,
              availability: productsPayload.availability,
              stored_fees: productsPayload.stored_fees,
            }),
            photo: file,
          };
          await editProductInfo(productId, bodyEdit);
          onClose();
          setSuccessDialogOpen(true);
          const response = await fetchData(queryParameters);
          const data = response?.data?.data?.result;
          setProducts(data);
        } else if (value === "2") {
          const bodyEdit = {
            data: JSON.stringify({
              availability: productsPayload.availability,
            }),
          };
          await editProductInfo(productId, bodyEdit);
          onClose();
          setSuccessDialogOpen(true);
          const response = await fetchData(queryParameters);
          const data = response?.data?.data?.result;
          setProducts(data);
        }
      } else {
        const isValidIngotAdd = (payload) => {
          const requiredFields = ["weight", "karat", "stored_fees", "photo"];
          for (const field of requiredFields) {
            if (!payload[field]) {
              console.error(`Field '${field}' is empty`);
              return false;
            }
          }
          if (!payload.trans || payload.trans.length === 0) {
            console.error("Trans array is empty");
            return false;
          }
          for (const item of payload.trans) {
            if (!item.name) {
              console.error(`Trans item for ${item.local} is empty`);
              return false;
            }
          }
          return true;
        };
        const isValidFractionAdd = (payload) => {
          const requiredFields = ["weight", "karat"];
          for (const field of requiredFields) {
            if (!payload[field]) {
              console.error(`Field '${field}' is empty`);
              return false;
            }
          }
          if (!payload.trans || payload.trans.length === 0) {
            console.error("Trans array is empty");
            return false;
          }
          for (const item of payload.trans) {
            if (item.local === "en" && !item.name) {
              console.error(`Trans item for English is empty`);
              return false;
            }
          }
          return true;
        };
        const isWeightValid = (weight) => {
          if (weight < 0) {
            console.error("Weight cannot be negative");
            return false;
          }
          return true;
        };
        if (!isWeightValid(productsPayload.weight)) {
          dispatch(
            setData({
              openSnack: true,
              message: "Weight cannot be negative.",
            })
          );
          return;
        }
        if (productsPayload.inventory_type === "ingot") {
          if (!isValidIngotAdd(productsPayload)) {
            dispatch(
              setData({
                openSnack: true,
                message: "You Should Fill All The Required Fields for products",
              })
            );
            return;
          }
        } else if (productsPayload.inventory_type === "fraction") {
          if (!isValidFractionAdd(productsPayload)) {
            dispatch(
              setData({
                openSnack: true,
                message:
                  "You Should Fill All The Required Fields for fractions",
              })
            );
            return;
          }
        } else {
          dispatch(
            setData({
              openSnack: true,
              message: "Invalid inventory type",
            })
          );
          return;
        }

        if (productsPayload.inventory_type === "ingot") {
          const body = {
            data: JSON.stringify({
              trans: productsPayload.trans,
              weight: productsPayload.weight,
              karat: productsPayload.karat,
              inventory_type: productsPayload.inventory_type,
              availability: productsPayload.availability,
              stored_fees: productsPayload.stored_fees,
            }),
            photo: file,
          };
          await addNewProduct(body);
          onClose();
          setSuccessDialogOpen(true);
          const response = await fetchData(queryParameters);
          const data = response?.data?.data?.result;
          setProducts(data);
        } else if (productsPayload.inventory_type === "fraction") {
          const body = {
            trans: productsPayload.trans,
            weight: productsPayload.weight,
            inventory_type: productsPayload.inventory_type,
            availability: productsPayload.availability,
          };
          await addNewFractionProduct(body);
          onClose();
          setSuccessDialogOpen(true);
          const response = await fetchData(queryParameters);
          const data = response?.data?.data?.result;
          setProducts(data);
        } else {
          dispatch(
            setData({
              openSnack: true,
              message: "Invalid inventory type",
            })
          );
        }
      }
      onClose();
    } catch (error) {
      if (error.response.status === 422) {
        const validationErrors = error.response.data.validation;
        const nameEnError = validationErrors.name_en;
        const nameArError = validationErrors.name_ar;
        const nameEgError = validationErrors.name_eg;

        let errorMessage = "";
        if (nameEnError) {
          errorMessage += `${nameEnError}`;
        } else if (nameArError) {
          errorMessage += `${nameArError}`;
        } else if (nameEgError) {
          errorMessage += `${nameEgError}`;
        }

        dispatch(
          setData({
            openSnack: true,
            message: errorMessage,
          })
        );
      } else {
        dispatch(
          setData({
            openSnack: true,
            message: error?.response?.data?.message,
          })
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    if (productToEdit) {
      setProductsPayload({
        name: productToEdit?.trans?.en,
        local: "en",
        weight: productToEdit?.weight,
        karat: productToEdit?.karat,
        inventory_type: productToEdit?.inventory_type,
        availability: productToEdit?.availability,
        stored_fees: productToEdit?.stored_fees,
        photo: productToEdit?.photo,
      });
    } else {
      setProductsPayload({
        trans: [
          {
            local: "ar",
            name: "",
          },
          {
            local: "en",
            name: "",
          },
          {
            local: "eg",
            name: "",
          },
        ],
        weight: null,
        karat: 21,
        inventory_type: "ingot",
        availability: 1,
        stored_fees: null,
        photo: "",
      });
      setImage(null);
      setFile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, productId, fetchData]);
  return (
    <>
      <Dialog
        open={successDialogOpen}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            Product has been added Successfully.
          </Typography>
          <Button
            onClick={() => {
              handleSuccessDialogClose();
            }}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#E9C237",
              "&:hover": { backgroundColor: "#E9C237" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Go to Products
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="850px"
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: width > 768 ? "850px" : "fit-content",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <div
            className="
          md:flex md:gap-2  md:flex-col md:items-center   rounded-3xl md:w-4/5 h-auto"
          >
            <div className="hidden md:flex self-end ">
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </div>
            <h1
              className={`
              text-2xl text-center  md:mt-0  md:text-left w-full 
               
              `}
              style={{
                marginTop:
                  productsPayload?.inventory_type === "ingot"
                    ? 400
                    : 1,
              }}
            >
              Add Products
            </h1>

            <div className="flex w-full flex-col">
              <div className="flex flex-col justify-center mx-auto md:mx-0  md:items-start w-fit mt-2">
                <p className="font-medium text-[#4c4c4c]  text-left">Type</p>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={productsPayload?.inventory_type}
                  onChange={(event) => {
                    setTypeParams(event.target.value);
                    const updatedProductData = {
                      ...productsPayload,
                      inventory_type: event.target.value,
                      trans:
                        event.target.value === "ingot"
                          ? [
                              { local: "ar", name: "" },
                              { local: "en", name: "" },
                              { local: "eg", name: "" },
                            ]
                          : [{ local: "en", name: "" }],
                    };
                    setProductsPayload(updatedProductData);
                  }}
                >
                  <FormControlLabel
                    style={{ color: "#4C4C4C" }}
                    value="ingot"
                    control={<Radio />}
                    label="Ingots"
                  />
                  <FormControlLabel
                    style={{ color: "#4C4C4C" }}
                    value="fraction"
                    control={<Radio />}
                    label="Fractions"
                  />
                </RadioGroup>
              </div>
              {productsPayload?.inventory_type === "ingot" ? (
                <div className=" md:flex  justify-center items-center grid grid-cols-1 w-fit mx-auto md:w-full md:mx-0">
                  <TextFieldComponent
                    sx={{ "& fieldset": { border: "none" } }}
                    style={{
                      height: "48px",
                      width: "200px",
                      backgroundColor: "#F7F7F7",
                      borderRadius: "9px",
                    }}
                    name="Product Name"
                    placeholder="Enter Product Name"
                    value={
                      productsPayload?.trans?.find(
                        (transItem) => transItem.local === "en"
                      ).name
                    }
                    onChange={(event) =>
                      handleTransChange("en", event.target.value)
                    }
                  />
                  <TextFieldComponent
                    sx={{ "& fieldset": { border: "none" } }}
                    style={{
                      height: "48px",
                      width: "200px",
                      backgroundColor: "#F7F7F7",
                      borderRadius: "9px",
                    }}
                    name="Product Name(Arabic)"
                    placeholder="Enter Product Name"
                    value={
                      productsPayload?.trans?.find(
                        (transItem) => transItem.local === "ar"
                      ).name
                    }
                    onChange={(event) => {
                      const disallowEnglishRegex = /^[^a-zA-Z]*$/;
                      if (
                        event.target.value === "" ||
                        disallowEnglishRegex.test(event.target.value)
                      ) {
                        handleTransChange("ar", event.target.value);
                      }
                    }}
                  />
                  <TextFieldComponent
                    sx={{ "& fieldset": { border: "none" } }}
                    style={{
                      height: "48px",
                      width: "200px",
                      backgroundColor: "#F7F7F7",
                      borderRadius: "9px",
                    }}
                    name="Product Name(Egyptian Arabic)"
                    placeholder="Enter Product Name"
                    value={
                      productsPayload?.trans?.find(
                        (transItem) => transItem.local === "eg"
                      ).name
                    }
                    onChange={(event) => {
                      const disallowEnglishRegex = /^[^a-zA-Z]*$/;
                      if (
                        event.target.value === "" ||
                        disallowEnglishRegex.test(event.target.value)
                      ) {
                        handleTransChange("eg", event.target.value);
                      }
                    }}
                  />
                </div>
              ) : (
                <div className="grid grid-cols-1 md:flex md:justify-between md:gap-4 justify-center items-center w-fit mx-auto ">
                  <TextFieldComponent
                    sx={{ "& fieldset": { border: "none" } }}
                    style={{
                      height: "48px",
                      width: width > 768 ? "310px" : "fit-content",

                      backgroundColor: "#F7F7F7",
                      borderRadius: "9px",
                    }}
                    name="Product Name"
                    placeholder="Enter Product Name"
                    value={
                      productsPayload?.trans?.find(
                        (transItem) => transItem.local === "en"
                      ).name
                    }
                    onChange={(event) =>
                      handleTransChange("en", event.target.value)
                    }
                  />
                  <TextFieldComponent
                    sx={{
                      "& fieldset": { border: "none" },
                      height: "48px",
                      width: width > 768 ? "310px" : "fit-content",

                      backgroundColor: "#F7F7F7",
                      borderRadius: "9px",
                    }}
                    type="number"
                    name="Weight"
                    placeholder="Enter Product Weight"
                    value={
                      productsPayload.weight === null
                        ? ""
                        : productsPayload.weight
                    }
                    onChange={(event) => {
                      var temppayload = { ...productsPayload };
                      temppayload.weight = event.target.value;
                      setProductsPayload({ ...temppayload });
                    }}
                  />
                </div>
              )}
              <div className="">
                {productsPayload?.inventory_type === "ingot" ? (
                  <div className="grid grid-cols-1 md:flex items-center justify-center ">
                    <div className="w-fit mx-auto">
                      <TextFieldComponent
                        sx={{
                          "& fieldset": { border: "none" },
                          height: "48px",
                          width: width > 768 ? "292px" : "fit-content",
                          backgroundColor: "#F7F7F7",
                          borderRadius: "9px",
                        }}
                        type="number"
                        name="Weight"
                        placeholder="Enter Product Weight"
                        value={
                          productsPayload.weight === null
                            ? ""
                            : productsPayload.weight
                        }
                        onChange={(event) => {
                          var temppayload = { ...productsPayload };
                          temppayload.weight = event.target.value;
                          setProductsPayload({ ...temppayload });
                        }}
                      />
                    </div>
                    <div className="w-fit mx-auto">
                      <TextFieldComponent
                        sx={{ "& fieldset": { border: "none" } }}
                        style={{
                          height: "48px",
                          width: width > 768 ? "292px" : "fit-content",
                          backgroundColor: "#F7F7F7",
                          borderRadius: "9px",
                        }}
                        name="Storage Fees"
                        placeholder="Enter Storage Fees"
                        value={
                          productsPayload.stored_fees === null
                            ? ""
                            : productsPayload.stored_fees
                        }
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          if (inputValue === "" || !isNaN(inputValue)) {
                            var temppayload = { ...productsPayload };
                            temppayload.stored_fees =
                              inputValue === "" ? "" : parseInt(inputValue, 10);
                            setProductsPayload({ ...temppayload });
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className=" grid grid-cols-1 w-fit mx-auto md:mx-0 mt-4">
                    <FormLabel
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#4C4C4C",
                      }}
                      id="demo-row-radio-buttons-group-label"
                    >
                      Availability
                    </FormLabel>
                    <RadioGroup
                      style={{ marginTop: "15px" }}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={productsPayload.availability.toString()}
                      onChange={handleStockStatusChange}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="In Stock"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Out Of Stock"
                      />
                    </RadioGroup>
                  </div>
                )}
              </div>

              {productsPayload?.inventory_type === "ingot" && (
                <>
                  <div className="flex w-full items-center">
                    <div className=" mx-auto md:mx-0 mt-4">
                      <p className="font-medium text-[#4c4c4c] text-center   md:text-left w-fit">
                        Product Status
                      </p>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={productsPayload.availability.toString()}
                        onChange={handleStockStatusChange}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="In Stock"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Out Of Stock"
                        />
                      </RadioGroup>
                    </div>
                  </div>

                  <div className="mt-4">
                    <p className="text-[#4c4c4c] font-medium      md:text-left md:w-full w-fit">
                      Product image
                    </p>
                    <UploadImage
                      setImage={setImage}
                      setFile={setFile}
                      image={image}
                      handleChange={handleFileInputChange}
                    />
                  </div>

                  {file && (
                    <div
                      className="rounded-lg bg-white   flex items-center justify-between w-full mt-2 p-4"
                      style={{
                        boxShadow: "1px 1px 16px 0px rgba(0, 51, 102, 0.08)",
                      }}
                    >
                      <div className="flex flex-col gap-2 items-center">
                        <div className="  flex-col md:flex-row items-center  gap-4 hidden md:flex">
                          <img
                            className=""
                            src={`../../assets/uploadicon.png`}
                            alt="upload"
                          />
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 400,
                            }}
                          >
                            {file?.name || "loajnfasdafds.jpg"}
                          </Typography>
                          <Typography
                            sx={{
                              color: "rgba(0, 0, 0, 0.60)",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              letterSpacing: "0.17px",
                              wordSpacing: "4px",
                            }}
                          >
                            {Math.ceil(file?.size || 2032 / 1024)}kb • Complete
                          </Typography>
                        </div>
                      </div>
                      <Box sx={{ display: "flex" }}>
                        <IconButton
                          aria-label="delete"
                          size="large"
                          sx={{ color: "#FF725E" }}
                          onClick={handleDeleteImage}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>

                        <Avatar
                          sx={{
                            bgcolor: "#92E3A9",
                            height: "24px",
                            width: "24px",
                            alignSelf: "center",
                          }}
                        >
                          <img
                            src={`../../assets/checkicon.png`}
                            alt="checkIcon"
                          />
                        </Avatar>
                      </Box>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="grid grid-cols-1 md:flex gap-2 mt-3">
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#E9C237",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#E9C237",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#E9C237",
                  fontWeight: 600,
                  borderColor: "#E9C237",
                  "&:hover": {
                    borderColor: "#E9C237",
                  },
                }}
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar />
    </>
  );
};

export default ProductModal;
