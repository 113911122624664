import { Box, Grid, Typography } from "@mui/material";
import OverViewMainCard from "../../components/OverViewMainCard";
import { useFetchPayments } from "../../services/apis/overview";
import LoadingSpinner from "../../components/LoadingSpinner";

const PaymentTabPanel = () => {
  const today = new Date();
  const date14DaysAgo = new Date();
  date14DaysAgo.setDate(today.getDate() - 14);
  const { data: paymentData, isFetching } = useFetchPayments();
  return (
    <Box>
      <Box>
        <Typography
          sx={{ color: "#808080", fontSize: "16px", fontWeight: "500" }}
        >
          OverView
        </Typography>
      </Box>
      {/* users overview */}
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <Grid container spacing={1.5} pt={4}>
          {/* Main Cards Start  */}
          <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
            <OverViewMainCard
              backgroundColor="#FFFFFF"
              title="Pending Payments"
              total={paymentData?.pending?.toString()}
              value={"Request"}
            />
          </Grid>
          <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
            <OverViewMainCard
              backgroundColor="#FFFFFF"
              title="Approved Payments"
              total={paymentData?.approved?.toString()}
              value={"Request"}
            />
          </Grid>
          <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
            <OverViewMainCard
              backgroundColor="#FFFFFF"
              title="Rejected Payments"
              total={paymentData.rejected?.toString()}
              value={"Request"}
            />
          </Grid>
          {/* End Main Cards  */}
        </Grid>
      )}

      {/* end users overview */}
    </Box>
  );
};
export default PaymentTabPanel;
