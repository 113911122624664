import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, IconButton, InputBase, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGridStyle } from "../../../styles";
import Header from "../../../components/Header";
import { handlePageChange } from "../../../services/utils/filters";
import { TabContext, TabPanel } from "@mui/lab";
import {
  fetchInventoryRequest,
  useFetchInventoryRequest,
} from "../../../services/apis/inventory";
import RequestDialog from "./RequestDialog";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import AddButton from "../../../components/common/AddButton";
import { FirstBox, SecondBox } from "../../../components/common/CustomBox";
import { ItemsColumns } from "../../../components/common/Columns";
import NewTabs from "../../../components/common/NewTabs";
import { CustomLoadingOverlay } from "../../../components/common/CustomLoadingOverlay";
import NewTable from "../../../components/common/NewTable";
const Requests = () => {
  const tabs = [
    { label: "Ingots", value: "1" },
    { label: "Fractions", value: "2" },
  ];
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("1");
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
    orderBy: null,
    sort: null,
    type: "ingot",
  });
  const { data, refetch, isFetching } =
    useFetchInventoryRequest(queryParameters);
  const handleViewRequestDetails = (request) => {
    navigate(`/requests/${request.row.order_id}`, {
      state: { requestData: request.row },
    });
  };

  const handleChange = (newValue) => {
    setValue(newValue);
    if (newValue === "1") {
      setQueryParameters({
        ...queryParameters,
        page: 0,
        type: "ingot",
      });
    } else if (newValue === "2") {
      setQueryParameters({
        ...queryParameters,
        page: 0,
        type: "fraction",
      });
    }
  };
  const requestsColumns = ItemsColumns([
    { field: "order_id", headerName: "Request ID" },
    { field: "name", headerName: "Product" },
    { field: "quantity", headerName: "Quantity" },
    { field: "created", headerName: "Created At", date: true },
    {
      field: "status",
      headerName: "Order Status",
      specialRender: "orderHistory",
    },
  ]);
  const fractionsColumns = ItemsColumns([
    { field: "order_id", headerName: "Order ID" },
    { field: "name", headerName: "Product" },
    { field: "quantity", headerName: "Quantity" },
    { field: "created", headerName: "Created At", date: true },
    {
      field: "status",
      headerName: "Order Status",
      specialRender: "orderHistory",
    },
  ]);
  const handleSearchInput = (e) => {
    const { value } = e.target;
    setQueryParameters((prevParams) => ({
      ...prevParams,
      query: value.trim(),
    }));
  };
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <DashboardMainHeader />

      <Header title="Order History" marginL={"20px"} />
      <RequestDialog
        value={value}
        queryParameters={queryParameters}
        fetchData={fetchInventoryRequest}
        handleResponse={refetch}
        setRequests={setRequests}
        requests={requests}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        label={"Request"}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 2,
          p: 2,
          justifyContent: "end",
        }}
      >
        <AddButton title={"+ New Order"} onClick={() => setOpen(true)} />
      </Box>
      <SecondBox>
        <FirstBox m="40px 0 0 0" height="100%">
          <Box
            m="40px 0 0 0"
            height="auto"
            sx={{ ...DataGridStyle, overflowX: "auto" }}
          >
            <TabContext value={value}>
              <Box
                sx={{
                  width: "100%",
                  paddingTop: "2%",
                  paddingBottom: "1%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <NewTabs tabs={tabs} value={value} onChange={handleChange} />
              </Box>
              <TabPanel
                sx={{
                  borderBottom: "none  !important",
                }}
                value="1"
              >
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                  }}
                >
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: "60%",
                      height: "48px",
                      backgroundColor: "#F5F5F5",
                      boxShadow: "none",
                      borderRadius: "12px",
                      mb: "20px",
                    }}
                    onSubmit={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <img
                        alt="search-normal"
                        src={`../../assets/search-normal.png`}
                      />
                    </IconButton>
                    <InputBase
                      onChange={handleSearchInput}
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search Order ID ....."
                    />
                    {/* <FilterDrawer
                        queryParameters={queryParameters}
                        setQueryParameters={setQueryParameters}
                        secondTitle="Order Status"
                        secondKey="orderStatus"
                      /> */}
                  </Paper>
                  <NewTable
                    columns={requestsColumns}
                    isLoading={isFetching}
                    data={data?.data?.result}
                    totalPages={data?.data?.totalPages}
                    totalItems={data?.data?.totalItems}
                    handlePageChange={(newPage) =>
                      handlePageChange(
                        newPage,
                        queryParameters,
                        setQueryParameters
                      )
                    }
                    currentPage={queryParameters}
                    onCellClick={(params) => {
                      handleViewRequestDetails(params);
                    }}
                  />
                  {/* <DataGrid
                    components={{
                      LoadingOverlay: () =>
                        CustomLoadingOverlay({ columns: 5 }),
                    }}
                    disableSelectionOnClick
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "#ffffff",
                      height: "650px",
                      minWidth: "1000px",
                    }}
                    pageSize={10}
                    rowCount={data?.data?.totalItems || 0}
                    rows={isFetching ? [] : data?.data?.result}
                    columns={requestsColumns}
                    loading={isFetching}
                    paginationMode="server"
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    rowsPerPageOptions={[10]}
                    totalPages={data?.data?.totalPages}
                    getRowId={(row) => row.order_id}
                    localeText={{
                      noRowsLabel: "No gold ingots requests",
                    }}
                    onRowClick={(params) => {
                      handleViewRequestDetails(params);
                    }}
                  /> */}
                </Box>
              </TabPanel>
              <TabPanel
                sx={{
                  borderBottom: "none !important",
                }}
                value="2"
              >
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    justifyContent: "center",
                    background: "#ffffff",
                  }}
                >
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: "60%",
                      height: "48px",
                      backgroundColor: "#F5F5F5",
                      boxShadow: "none",
                      borderRadius: "12px",
                    }}
                    onSubmit={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <img
                        alt="search-normal"
                        src={`../../assets/search-normal.png`}
                      />
                    </IconButton>
                    <InputBase
                      onChange={handleSearchInput}
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search Order ID ....."
                    />
                    {/* <FilterDrawer
                        queryParameters={queryParameters}
                        setQueryParameters={setQueryParameters}
                        secondTitle="Order Status"
                        secondKey="orderStatus"
                      /> */}
                  </Paper>
                  <NewTable
                    columns={fractionsColumns}
                    isLoading={isFetching}
                    data={data?.data?.result}
                    totalPages={data?.data?.totalPages}
                    totalItems={data?.data?.totalItems || 0}
                    handlePageChange={(newPage) =>
                      handlePageChange(
                        newPage,
                        queryParameters,
                        setQueryParameters
                      )
                    }
                    currentPage={queryParameters}
                    onCellClick={(params) => {
                      handleViewRequestDetails(params);
                    }}
                  />
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </FirstBox>
      </SecondBox>
    </Box>
  );
};

export default Requests;
