import Header from "../../../components/Header";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { handlePageChange } from "../../../services/utils/filters";
import IngotDetails from "./IngotDetails";
import { useFetchSoldIngots } from "../../../services/apis/inventory";
import { FirstBox, SecondBox } from "../../../components/common/CustomBox";
import { Box } from "@mui/material";
import { ItemsColumns } from "../../../components/common/Columns";
import { CustomLoadingOverlay } from "../../../components/common/CustomLoadingOverlay";
import { DataGridStyle } from "../../../styles";
import NewTable from "../../../components/common/NewTable";

const SoldIngots = () => {
  const [currentData, setCurrentData] = useState({});
  const [open, setOpen] = useState(false);
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
  });
  const { data: soldIngotsData, isFetching } =
    useFetchSoldIngots(queryParameters);
  const handleViewRequestDetails = (request) => {
    setCurrentData(request);
    setOpen(true);
  };
  const soldIngotsColumns = ItemsColumns([
    { field: "name", headerName: "Product" },
    { field: "weight", add: "Gm" },
    { field: "serial", headerName: "Serial Number" },
    { field: "sold_price", headerName: "Ingot Price" },
    { field: "date", date: true },
  ]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <DashboardMainHeader />
      <Header title="Sold Ingots" marginL={"20px"} />
      <FirstBox m="40px 0 0 0" height="100%" padding={"40px"}>
        <Box
          sx={{
            ...DataGridStyle,
            overflowX: "auto",
            background: "white",
            p: 1
          }}
        >
          <NewTable
            columns={soldIngotsColumns}
            isLoading={isFetching}
            data={soldIngotsData?.data?.data?.result}
            totalPages={soldIngotsData?.data?.data?.totalPages}
            totalItems={soldIngotsData?.data?.data?.totalItems}
            handlePageChange={(newPage) =>
              handlePageChange(newPage, queryParameters, setQueryParameters)
            }
            currentPage={queryParameters}
            onCellClick={(params) => {
              handleViewRequestDetails(params.row);
            }}
          />
          {/* <DataGrid
            components={{
              LoadingOverlay: () => CustomLoadingOverlay({ columns: 5 }),
            }}
            disableSelectionOnClick
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "650px",
              minWidth: "1000px",
            }}
            pageSize={10}
            rowCount={soldIngotsData?.data?.data?.totalItems || 0}
            rows={isFetching ? [] : soldIngotsData?.data?.data?.result}
            columns={soldIngotsColumns}
            loading={isFetching}
            rowsPerPageOptions={[10]}
            totalPages={soldIngotsData?.data?.data?.totalPages}
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            paginationMode="server"
            localeText={{
              noRowsLabel: "No Sold Ingots",
            }}
            onRowClick={(params) => {
              handleViewRequestDetails(params.row);
            }}
          /> */}
        </Box>
      </FirstBox>
      <IngotDetails
        value={currentData}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Box>
  );
};

export default SoldIngots;
