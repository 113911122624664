import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp, FaCircle } from "react-icons/fa";
import { useFetchUserPages } from "../../services/apis/pages";
import { useLocation, useNavigate } from "react-router-dom";
import graphsvg from "../../svg/graph.svg";
import profileusersvg from "../../svg/profile-2user.svg";
import convertcardsvg from "../../svg/convert-card.svg";
import documenttextsvg from "../../svg/document-text.svg";
import baghappysvg from "../../svg/bag.svg";
import shieldsvg from "../../svg/security-user.svg";
import settingsSvg from "../../svg/setting-2.svg";
import shop from "../../svg/shop.svg";
import box from "../../svg/box.svg";
import ticket from "../../svg/ticket-discount.svg";
import directnotificationsvg from "../../svg/direct-notification.svg";
import Logout from "../../components/Logout";
import {
  setAllowedRoutes,
  setIsLoading,
  toggleSidebar,
} from "../../store/slices/sidebarSlice";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "../../hooks/useWindowSize";
import key from "../../svg/key.svg";
import sidebarButton from "../../svg/sidebarButton.svg";
const iconMap = {
  graph: graphsvg,
  "profile-2user": profileusersvg,
  "convert-card": convertcardsvg,
  "document-text": box,
  "bag-happy": baghappysvg,
  shop: shop,
  ticket: ticket,
  shield: shieldsvg,
  settings: settingsSvg,
  "category-2": documenttextsvg,
  "direct-notification": directnotificationsvg,
};
const NewSideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const isOpen = useSelector((state) => state.sideBar.isOpen);
  const location = useLocation();
  const [openSubmenus, setOpenSubmenus] = useState([]);
  const { data: items, isLoading } = useFetchUserPages();
  useEffect(() => {
    if (!isLoading && items?.length > 0 && items !== undefined) {
      dispatch(setIsLoading(false));
      let filteredData = items.filter((item) => item.pages.length > 0);
      const extractRoutes = filteredData.flatMap((item) =>
        item.pages.map((page) => page.to)
      );
      dispatch(setAllowedRoutes(extractRoutes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, items]);
  const [search, setSearch] = useState({ input: "", data: "" });
  function searchByTitle(title, arr) {
    const result = [];
    arr.forEach((item) => {
      if (item.title.toLowerCase().includes(title.toLowerCase())) {
        result.push(item);
      } else if (item.pages) {
        const matchingPages = item.pages.filter((page) =>
          page.title.toLowerCase().includes(title.toLowerCase())
        );

        if (matchingPages.length > 0) {
          result.push({
            ...item,
            pages: matchingPages,
          });
        }
      }
    });
    setSearch({ ...search, data: result });
  }
  useEffect(() => {
    if (search.input) {
      searchByTitle(search.input, items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.input]);

  useEffect(() => {
    if (width < 640) {
      dispatch(toggleSidebar());
    }
  }, [width, dispatch]);

  const toggleTheSidebar = () => {
    dispatch(toggleSidebar());
  };

  const toggleSubmenu = (index) => {
    setOpenSubmenus((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  if (isLoading) return null;

  return (
    <div
      className={`border-r fixed top-0 left-0 h-screen overflow-y-auto transition-all duration-300 bg-white text-[#808080] z-20
        ${
          width < 768
            ? isOpen
              ? "w-[18rem]"
              : "w-0"
            : isOpen
            ? "w-[18rem]"
            : "w-20"
        }
        ${isOpen ? "block" : "hidden md:block"}
      `}
    >
      <div className="flex flex-col h-full">
        <button className="p-4 focus:outline-none flex items-center justify-between">
          <div className="flex items-center justify-center gap-3 ">
            <img
              src={key}
              alt="logo"
              onClick={() => navigate("/")}
              className="mt-1"
            />
            <p
              className={`text-[#e9c237] text-4xl font-semibold font-ibm ${
                isOpen ? "block" : "hidden"
              }`}
            >
              Sabika
            </p>
          </div>

          <img
            src={sidebarButton}
            alt="sidebar"
            onClick={toggleTheSidebar}
            className={`absolute z-50 top-6 right-2 cursor-pointer -ml-[1rem] ${
              width > 700 ? "hidden" : ""
            }`}
          />
          {/* <div
            className="p-2 rounded-full hover:bg-gray-50"
            onClick={toggleTheSidebar}
          >
            <RxHamburgerMenu color="#e9c237" size={20} />
          </div> */}
        </button>
        <input
          type="text"
          placeholder="Search"
          className={`  border-b border-gray-200 focus:outline-none bg-gray-100 mx-4 rounded-lg p-2 text-[#e9c237] mb-2
            ${isOpen ? "" : ""}
            `}
          onChange={(e) => {
            setSearch({ ...search, input: e.target.value });
          }}
        />
        <nav className="flex-grow">
          <ul>
            {search.input ? (
              search.data.length > 0 ? (
                search.data.map((item, index) => (
                  <li key={item.id}>
                    <div
                      onClick={() => toggleSubmenu(index)}
                      className={`${
                        item.to === null && item.pages.length === 0
                          ? "hidden"
                          : ""
                      }
flex items-center p-4 hover:bg-gray-100 cursor-pointer justify-center bg-white`}
                    >
                      <img
                        src={iconMap[item.icon]}
                        alt={item.icon}
                        onClick={() => {
                          if (!isOpen) {
                            dispatch(toggleSidebar());
                          }
                        }}
                      />
                      {isOpen && (
                        <span className="ml-4 mr-2">{item.title}</span>
                      )}
                      {isOpen &&
                        item.pages.length > 0 &&
                        (openSubmenus.includes(index) ? (
                          <FaChevronUp className="ml-auto" />
                        ) : (
                          <FaChevronDown className="ml-auto" />
                        ))}
                    </div>
                    {isOpen && item.pages.length > 0 && (
                      <ul
                        onClick={() => {
                          if (width < 640) {
                            toggleTheSidebar();
                          }
                        }}
                        className={`bg-white overflow-hidden transition-max-height duration-300 ${
                          openSubmenus.includes(index) ? "" : "max-h-0"
                        } pl-8`}
                      >
                        {item.pages.map((page) => (
                          <li
                            onClick={() => {
                              navigate(page?.to);
                            }}
                            key={page.id}
                            className={`flex items-center py-3 px-6 cursor-pointer rounded-xl mr-2 ${
                              location?.pathname.split("/")[1] ===
                              page?.to.split("/")[1]
                                ? "bg-[#e9c237] text-white"
                                : "bg-white hover:bg-gray-100"
                            }`}
                          >
                            <FaCircle className="text-[0.4rem] mr-2" />
                            {page.title}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))
              ) : (
                // Display message when search input is present but no data
                <p className="text-center text-md my-2">
                  What you are searching for is not found.
                </p>
              )
            ) : (
              // Display default items when there's no search input
              items.map((item, index) => (
                <li
                  className={`
                  ${item.to === null && item.pages.length === 0 ? "hidden" : ""}
                  `}
                  key={item.id}
                  onClick={() => {
                    if (item?.to) {
                      navigate(item?.to);
                    }
                  }}
                >
                  <div
                    onClick={() => toggleSubmenu(index)}
                    className={`p-4 flex items-center   cursor-pointer justify-center 
                      ${
                        item?.to === pathname
                          ? "bg-[#e9c237] text-white  hover:bg-[#e9c237] hover:text-white  rounded-xl  mx-2"
                          : "hover:bg-gray-100"
                      }
                      `}
                  >
                    <img
                      src={iconMap[item.icon]}
                      alt={item.icon}
                      onClick={() => {
                        if (!isOpen) {
                          dispatch(toggleSidebar());
                        }
                      }}
                    />
                    {isOpen && (
                      <span className="ml-4 mr-auto">{item.title}</span>
                    )}
                    {isOpen &&
                      item.pages.length > 0 &&
                      (openSubmenus.includes(index) ? (
                        <FaChevronUp className="ml-auto" />
                      ) : (
                        <FaChevronDown className="ml-auto" />
                      ))}
                  </div>
                  {isOpen && item.pages.length > 0 && (
                    <ul
                      onClick={() => {
                        if (width < 640) {
                          toggleTheSidebar();
                        }
                      }}
                      className={`bg-white overflow-hidden transition-max-height duration-300 ${
                        openSubmenus.includes(index) ? "" : "max-h-0"
                      } pl-8`}
                    >
                      {item.pages.map((page) => (
                        <li
                          onClick={() => {
                            navigate(page?.to);
                          }}
                          key={page.id}
                          className={`flex items-center py-3 px-6 cursor-pointer rounded-xl mr-2 ${
                            location?.pathname.split("/")[1] ===
                            page?.to.split("/")[1]
                              ? "bg-[#e9c237] text-white"
                              : "bg-white hover:bg-gray-100"
                          }`}
                        >
                          <FaCircle className="text-[0.4rem] mr-2" />
                          {page.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))
            )}
          </ul>
          <Logout isOpen={isOpen} />
        </nav>
      </div>
    </div>
  );
};

export default NewSideBar;
