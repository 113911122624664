import { Box, Grid, Typography } from "@mui/material";
import BlockedUsersChart from "./BlockedUsersChart";
import UserSingleLineChart from "./UserSingleLineChart";
import UserOverviewMainCard from "../../components/UserOverviewMainCard";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { DataGrid } from "@mui/x-data-grid";

const PhysicalGoldTabPanel = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const today = new Date();
  const date14DaysAgo = new Date();
  date14DaysAgo.setDate(today.getDate() - 14);
  const formattedToday = today.toLocaleDateString();
  const formattedDate14DaysAgo = date14DaysAgo.toLocaleDateString();
  const [queryParameters, setQueryParameters] = useState({
    date_from: formattedDate14DaysAgo,
    date_to: formattedToday,
  });
  const walletBalances = [
    {
      id: 1,
      full_name: "Alaa Ali",
      phone: "01234567891",
      TotalGoldWeight: "5000 Gram",
      IngotsAmount: "2",
      FractionsAmount: "10 Gram",
    },
    {
      id: 2,
      full_name: "Alaa Ali",
      phone: "01234567891",
      TotalGoldWeight: "5000 Gram",
      IngotsAmount: "2",
      FractionsAmount: "10 Gram",
    },
    {
      id: 3,
      full_name: "Alaa Ali",
      phone: "01234567891",
      TotalGoldWeight: "5000 Gram",
      IngotsAmount: "2",
      FractionsAmount: "10 Gram",
    },
    {
      id: 4,
      full_name: "Alaa Ali",
      phone: "01234567891",
      TotalGoldWeight: "5000 Gram",
      IngotsAmount: "2",
      FractionsAmount: "10 Gram",
    },
  ];
  const walletBalancesColumns = [
    {
      field: "actions",
      headerName: "",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          cursor="pointer"
          // onClick={() => handleClick(params)}
        >
          <path
            d="M15.5799 12C15.5799 13.98 13.9799 15.58 11.9999 15.58C10.0199 15.58 8.41992 13.98 8.41992 12C8.41992 10.02 10.0199 8.42 11.9999 8.42C13.9799 8.42 15.5799 10.02 15.5799 12Z"
            stroke="#00303C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.4C18.8198 5.8 15.5298 3.72 11.9998 3.72C8.46984 3.72 5.17984 5.8 2.88984 9.4C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
            stroke="#00303C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      field: "full_name",
      headerName: "Username",
      flex: 2,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
    {
      field: "phone",
      headerName: "Phone number",
      flex: 2,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
    {
      field: "TotalGoldWeight",
      headerName: "Total Gold Weight",
      flex: 3,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
    {
      field: "IngotsAmount",
      headerName: "Ingots Amount",
      flex: 2,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
    {
      field: "FractionsAmount",
      headerName: "Fractions Amount",
      flex: 2,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
  ];
  const locationStorage = [
    {
      id: 1,
      Location: "Bank Masr",
      City: "Giza",
      Area: "El-Harm",
    },
    {
      id: 2,
      Location: "Bank Masr",
      City: "Giza",
      Area: "El-Harm",
    },
    {
      id: 3,
      Location: "Bank Masr",
      City: "Giza",
      Area: "El-Harm",
    },
    {
      id: 4,
      Location: "Bank Masr",
      City: "Giza",
      Area: "El-Harm",
    },
  ];
  const locationStorageColumns = [
    {
      field: "Location",
      headerName: "Location",
      flex: 2,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
    {
      field: "City",
      headerName: "City",
      flex: 2,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
    {
      field: "Area",
      headerName: "Area",
      flex: 2,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
  ];
  const InsuranceCoverage = [
    {
      id: 1,
      CompanyName: "Bank Masr",
      Expireddate: "25/12/2023 06:59:32",
    },
    {
      id: 2,
      CompanyName: "Bank Masr",
      Expireddate: "25/12/2023 06:59:32",
    },
    {
      id: 3,
      CompanyName: "Bank Masr",
      Expireddate: "25/12/2023 06:59:32",
    },
    {
      id: 4,
      CompanyName: "Bank Masr",
      Expireddate: "25/12/2023 06:59:32",
    },
  ];
  const InsuranceCoverageColumns = [
    {
      field: "CompanyName",
      headerName: "Company Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
    {
      field: "Expireddate",
      headerName: "Expired date",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
  ];
  return (
    <Box>
      <Box>
        <Typography
          sx={{ color: "#808080", fontSize: "16px", fontWeight: "500" }}
        >
          OverView
        </Typography>
      </Box>
      {/* Physical Gold overview start  */}
      <Grid container spacing={1.5} pt={4}>
        {/* card Charts start  */}
        <Grid item lg={4} xs={12} sm={4} md={4}>
          <UserOverviewMainCard
            backgroundColor="#FFFFFF"
            title="Live Gold Price"
            total={"3,805.50"}
            value={"EGP"}
            arrow={<img alt="icon" src={`../../assets/arrow-up.png`} />}
            perc={`+${"10"} %`}
            perccolor={"#E9C237"}
            percbackground={"rgba(233, 194, 55, 0.1)"}
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={4} md={4}>
          <UserOverviewMainCard
            backgroundColor="#FFFFFF"
            title="Current Gold Bars in Stock"
            total={"521,562"}
            value={"Bar"}
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={4} md={4}>
          <UserOverviewMainCard
            backgroundColor="#FFFFFF"
            title="Total Physical Gold Value"
            total={"145,984,673,922.268"}
            value={"EGP"}
          />
        </Grid>
        {/* end card charts  */}
        {/* gold ingot details chart start  */}
        <Grid mt={1.4} item xs={12} md={12} lg={7.7}>
          <UserSingleLineChart
            height={288}
            width={"100%"}
            style={{
              boxShadow: "0px 0px 0px #D3D3D3",
              borderRadius: "24px",
            }}
            title="Gold Ingot Details"
            queryParameters={queryParameters}
            setQueryParameters={setQueryParameters}
            chart={{
              labels: [
                "Ingot 1 Gram",
                "Ingot 2.5 Gram",
                "Ingot 5 Gram",
                "Ingot 10 Gram",
                "Ingot 50 Gram",
                "Ingot 100 Gram",
              ],
              series: [
                {
                  name: "Ingot",
                  type: "column",
                  fill: "solid",
                  data: [38, 44, 35, 35, 28, 22],
                  color: "#E9C237",
                },
              ],
            }}
          />
        </Grid>
        <Grid
          item
          mt={3}
          sx={{
            backgroundColor: "#ffffff",
            height: "448px",
            borderRadius: "24px",
            marginLeft: "2%",
          }}
          xs={12}
          md={12}
          lg={4}
        >
          <BlockedUsersChart
            type="donut"
            sx={{ borderRadius: "24px", boxShadow: "none" }}
            title="Providers"
            chart={{
              series: [
                {
                  label: "Provider1",
                  value: 65,
                },
                {
                  label: "Provider2",
                  value: 20,
                },
                {
                  label: "Provider3",
                  value: 15,
                },
              ],
            }}
            labelTitle={"Gold Bars in Stock"}
            labelNumber={"5,025,024"}
          />
        </Grid>
        {/* end gold ingot details chart  */}

        {/* source of gold pie chart & gold holdings start  */}
        <Grid
          item
          mt={3}
          sx={{
            backgroundColor: "#ffffff",
            height: "418px",
            borderRadius: "24px",
            marginLeft: "1.5%",
          }}
          xs={12}
          md={12}
          lg={4}
        >
          <BlockedUsersChart
            type="donut"
            sx={{ borderRadius: "24px", boxShadow: "none" }}
            title="Source of Gold"
            chart={{
              series: [
                {
                  label: "New",
                  value: 65,
                },
                {
                  label: "Reason2",
                  value: 20,
                },
              ],
            }}
            labelTitle={"Total Gold Bars"}
            labelNumber={"2,521,562"}
          />
        </Grid>
        <Grid
          item
          p={2}
          mt={3}
          sx={{
            backgroundColor: "#ffffff",
            height: "418px",
            borderRadius: "24px",
            marginLeft: "2%",
          }}
          xs={12}
          md={12}
          lg={7.5}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ color: "#808080", fontSize: "20px", fontWeight: "600" }}
            >
              Largest Gold Holdings
            </Typography>
          </Box>
          <Box
            m="20px"
            sx={{
              height: "auto",
              background: "#FFF",
              borderRadius: "12px",
            }}
          >
            <Box
              m="40px 0 0 0"
              height="100%"
              sx={{
                "& .MuiDataGrid-columnHeader.bold-header": {
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#000000",
                },
                "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                  backgroundColor: "#FFF",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#FFF",
                  borderBottom: "none",
                  pointerEvents: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: "#FFF",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: "#FFF",
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                hideFooterSelectedRowCount={true}
                rows={walletBalances}
                columns={walletBalancesColumns}
                autoHeight={true}
                components={{
                  Footer: () => null,
                }}
                disableColumnMenu={true}
              />
            </Box>
          </Box>
        </Grid>
        {/*End source of gold pie chart & gold holdings  */}
        <Grid
          item
          p={1}
          mt={3}
          sx={{
            backgroundColor: "#ffffff",
            height: "418px",
            borderRadius: "24px",
            marginLeft: "2%",
          }}
          xs={12}
          md={12}
          lg={5.7}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ color: "#808080", fontSize: "20px", fontWeight: "600" }}
            >
              Location of Gold Storage
            </Typography>
          </Box>
          <Box
            m="20px"
            sx={{
              height: "auto",
              background: "#FFF",
              borderRadius: "12px",
            }}
          >
            <Box
              m="40px 0 0 0"
              height="100%"
              sx={{
                "& .MuiDataGrid-columnHeader.bold-header": {
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#000000",
                },
                "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                  backgroundColor: "#FFF",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#FFF",
                  borderBottom: "none",
                  pointerEvents: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: "#FFF",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: "#FFF",
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                hideFooterSelectedRowCount={true}
                rows={locationStorage}
                columns={locationStorageColumns}
                autoHeight={true}
                components={{
                  Footer: () => null,
                }}
                disableColumnMenu={true}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          p={1}
          mt={3}
          sx={{
            backgroundColor: "#ffffff",
            height: "418px",
            borderRadius: "24px",
            marginLeft: "2%",
          }}
          xs={12}
          md={12}
          lg={5.7}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ color: "#808080", fontSize: "20px", fontWeight: "600" }}
            >
              Insurance Coverage for Physical Gold
            </Typography>
          </Box>
          <Box
            m="20px"
            sx={{
              height: "auto",
              background: "#FFF",
              borderRadius: "12px",
            }}
          >
            <Box
              m="40px 0 0 0"
              height="100%"
              sx={{
                "& .MuiDataGrid-columnHeader.bold-header": {
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#000000",
                },
                "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                  backgroundColor: "#FFF",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#FFF",
                  borderBottom: "none",
                  pointerEvents: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: "#FFF",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: "#FFF",
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                hideFooterSelectedRowCount={true}
                rows={InsuranceCoverage}
                columns={InsuranceCoverageColumns}
                autoHeight={true}
                components={{
                  Footer: () => null,
                }}
                disableColumnMenu={true}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* end Physical Gold overview  */}
    </Box>
  );
};
export default PhysicalGoldTabPanel;
