//make me a slice that will hold the state of the sidebar
import { createSlice } from "@reduxjs/toolkit";
// Retrieve the JSON string from localStorage
const storedData = sessionStorage.getItem("tickets"); // Replace 'yourKey' with the actual key used in localStorage
const storedUserInfo = sessionStorage.getItem("users"); // Replace 'yourKey' with the actual key used in localStorage

export const ticketSlice = createSlice({
  name: "tickets",
  initialState: {
    data: storedData ? JSON.parse(storedData) : [],
    moneyTransaction: false,
    userInfo: storedUserInfo ? JSON.parse(storedUserInfo) : [],
  },
  reducers: {
    addItem: (state, action) => {
      const exists = state.data.some((item) => item.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload); // Add the item if it doesn't exist
      }
    },
    removeItem: (state, action) => {
      state.data = state.data.filter((item) => item.id !== action.payload);
    },
    changeMoneyTransaction: (state) => {
      state.moneyTransaction = !state.moneyTransaction;
    },
    addUser: (state, action) => {
      const exists = state.userInfo.some(
        (item) => item.id === action.payload.id
      );
      if (!exists) {
        state.userInfo.push(action.payload); // Add the item if it doesn't exist
      }
    },
    removeUser: (state, action) => {
      state.userInfo = state.userInfo.filter(
        (item) => item.id !== action.payload
      );
    },
  },
});

export const {
  addItem,
  removeItem,
  changeMoneyTransaction,
  addUser,
  removeUser,
} = ticketSlice.actions;
export default ticketSlice.reducer;
