import { useMutation, useQuery } from "react-query";
import { axiosInstance as axios } from "../config";
const fetchProductsInfo = async ({ queryKey }) => {
  const [, params] = queryKey;
  const response = await axios.get(`/api/products/index`, {
    params: {
      page: params.page,
      orderBy: params.orderBy,
      sort: params.sort,
      inventory_type: params.inventory_type,
    },
  });
  function flattenRowData(row) {
    // Flatten the trans object and prefix its keys with 'name'
    const flattenedTrans = Object.keys(row.trans).reduce((acc, key) => {
      acc[`name${key.charAt(0).toUpperCase() + key.slice(1)}`] = row.trans[key];
      return acc;
    }, {});

    // Merge the flattened trans object with the rest of the row
    return {
      ...row,
      ...flattenedTrans,
    };
  }

  // Example usage for multiple rows
  const flattenedRows = response.data?.data?.result.map(flattenRowData);
  let finalResponse = {
    totalItems: response.data?.data?.totalItems,
    result: flattenedRows,
    totalPages: response.data?.data?.totalPages,
  };
  return finalResponse;
};
export const useFetchProductsInfo = (params) => {
  return useQuery(["products", params], fetchProductsInfo, {
    keepPreviousData: true, // keeps the previous data while fetching new data
  });
};
export const fetchProductsRequest = async (p) => {
  try {
    const response = await axios.get("/api/products/dropdown", {
      params: {
        inventory_type: p.inventory_type,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
// Define the fetch function
const fetchProducts = async ({ queryKey }) => {
  const [, { inventory_type }] = queryKey; // Destructure queryKey to access params
  try {
    const response = await axios.get("/api/products/dropdown", {
      params: { inventory_type },
    });
    return response.data?.data; // Return the data from the response
  } catch (error) {
    throw error; // Throw error for React Query to handle
  }
};

// Create the custom hook
export const useFetchProducts = (inventory_type, options = {}) => {
  return useQuery(["fetchProducts", { inventory_type }], fetchProducts, {
    enabled: !!inventory_type, // Only fetch if inventory_type is provided
    ...options, // Spread any additional options passed to the hook
  });
};
const fetchProductDetails = async (id) => {
  try {
    const response = await axios.get(`/api/products/get/${id}`);
    return response.data?.data; // Return the data directly
  } catch (error) {
    throw error;
  }
};
export const useProductDetails = (id) => {
  return useQuery(["productDetails", id], () => fetchProductDetails(id), {
    staleTime: 5000, // Data stays fresh for 5 seconds
    cacheTime: 10000, // Data is cached for 10 seconds
    enabled: !!id, // Query will not run unless `id` is truthy
  });
};
export const addNewProduct = async (body) => {
  try {
    const response = await axios.post("/api/products/add", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const addNewFractionProduct = async (body) => {
  try {
    const response = await axios.post("/api/products/addFraction", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const editProductInfo = async (id, body) => {
  try {
    const response = await axios.post(`/api/products/edit/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const editTheProductInfo = async ({ id, body }) => {
  try {
    const response = await axios.post(`/api/products/edit/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const useEditProductInfo = () => {
  return useMutation(editTheProductInfo);
};
