import { useQuery } from "react-query";
import { axiosInstance as axios } from "../config";

export const fetchPages = async () => {
  try {
    const response = await axios.get(`/api/sidebar/index`);
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchUserPages = async () => {
  const response = await axios.get(`/api/sidebar/user`);
  return response?.data?.data; // Assuming you only want the data part of the response
};

export const useFetchUserPages = () => {
  return useQuery(["userPages"], fetchUserPages);
};

export const addNewPage = async (body) => {
  try {
    const response = await axios.post(`/api/sidebar/add-page`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addNewSection = async (body) => {
  try {
    const response = await axios.post(`/api/sidebar/add-section`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchSections = async () => {
  try {
    const response = await axios.get(`/api/sidebar/sections`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addRoleToPage = async (pid, rid) => {
  const body = {
    id: pid,
    role: rid,
  };
  try {
    const response = await axios.post(`/api/sidebar/changeRole`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editPage = async (id, body) => {
  try {
    const response = await axios.post(`/api/sidebar/edit-page/${id}`, body);
    return response;
  } catch (error) {
    throw error;
  }
};
