import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearToken } from "../store/slices/authenticationSlice";
import profile from "../svg/profile-circle.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import { toggleSidebar } from "../store/slices/sidebarSlice";
import useWindowSize from "../hooks/useWindowSize";
import { axiosInstance as axios } from "../services/config";

const DashboardMainHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = async () => {
    try {
      await axios.post(`api/users/adminLogout`);
      window.history.pushState(null, null, "/login");
      window.history.replaceState(null, null, "/login");
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
      sessionStorage.removeItem("selectedTitle");
      sessionStorage.removeItem("isPageRefreshed");
      dispatch(clearToken());
    } catch (error) {
      console.error("Logout error:", error);
      window.history.pushState(null, null, "/login");
      window.history.replaceState(null, null, "/login");
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
      sessionStorage.removeItem("selectedTitle");
      sessionStorage.removeItem("isPageRefreshed");
      dispatch(clearToken());
    }
  };
  const { width } = useWindowSize();
  return (
    <div
      className={`flex 
     items-center mr-[1%] pt-[2%] mb-[30px]`}
    >
      <RxHamburgerMenu
        color="#e9c237"
        size={20}
        onClick={() => {
          dispatch(toggleSidebar());
        }}
        className={` mr-auto ml-6
          ${width > 768 ? "hidden" : "block"}
          `}
      />
      <div className="flex items-center  p-1 ml-auto">
        <div className="flex flex-row items-center">
          <img
            onClick={() => navigate("/AdminProfile")}
            className="cursor-pointer w-[30px]"
            alt="profile"
            src={profile}
          />
          <div
            onClick={handleLogOut}
            className="ml-[30px] flex flex-row items-center bg-[#f5f5f5] rounded-xl px-[30px] py-[10px] cursor-pointer"
          >
            <img alt="logout" src={`../../assets/logout.png`} />
            <span className="ml-[15px] text-[#4C4C4C] text-sm font-normal ">
              Logout
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardMainHeader;
