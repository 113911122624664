import React from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const TextFieldComponent = ({
  name,
  value,
  placeholder,
  onChange,
  backgroundColor,
  height,
  type,
  showPassword,
  onTogglePasswordVisibility,
  error,
  marginLeft,
  hide,
  sx,
  className,
  widthFit,
  ...props
}) => {
  const handleWheel = (event) => {
    if (type === "number") {
      event.target.blur();
      setTimeout(() => {
        event.target.focus();
      }, 0);
    }
  };

  return (
    <Box
      marginLeft={marginLeft || 0}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: widthFit ? "fit-content" : "100%",
      }}
    >
      <Typography
        sx={{
          display: hide ? "none" : "block",
          fontSize: "16px",
          fontWeight: "500",
          color: "#4C4C4C",
          marginBottom: "5px",
          marginTop: "15px",
        }}
      >
        {name}
      </Typography>
      <TextField
        className={`${className}`}
        sx={{
          height: "48px",
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
            {
              WebkitAppearance: "none",
              margin: 0,
            },
          ...sx,
        }}
        InputLabelProps={{ shrink: false }}
        placeholder={placeholder}
        fullWidth
        value={value}
        onChange={onChange}
        type={showPassword ? "text" : type}
        error={error}
        onWheel={handleWheel} // Disable number scroll
        {...props}
        InputProps={
          type === "password"
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onTogglePasswordVisibility} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {}
        }
      />
      {error && (
        <Typography
          sx={{ color: "red", fontSize: "14px", marginTop: "5px" }}
          variant="body2"
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default TextFieldComponent;
