import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { IoIosCloseCircleOutline } from "react-icons/io";
import UserInfoField from "../user-profile/UserInfoField";
import { formDate } from "../../services/helpers";

const LogsDetailsDialog = ({ open, onClose }) => {
  return (
    <>
      <Dialog
        maxWidth="800px"
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            height: "auto",
            width: "800px",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // alignSelf: "center",
          }}
        >
          <Box
            sx={{
              //   justifyContent: "center",
              width: "100%",
              height: "auto",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              //   alignItems: "center",
              //   alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={{ color: "#666666", fontSize: "24px", fontWeight: 500 }}
              >
                Action details :
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "5%",
                }}
              >
                <UserInfoField title="Action" data={"Approve KYC"} />
                <UserInfoField
                  title="Date"
                  data={formDate("2023-09-06T15:19:50.000Z")}
                />
              </Box>
              <Divider sx={{ backgroundColor: "#E6E6E6", my: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <UserInfoField title="Target Type" data={"user"} />
                <UserInfoField title="Target User ID" data={"01272011115"} />
              </Box>
              <Divider sx={{ backgroundColor: "#E6E6E6", my: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <UserInfoField title="Id" data={"739"} />
                <UserInfoField title="Type" data={"2"} />
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LogsDetailsDialog;
