import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import useChart from "./UseChart";
import Chart from "./Chart";
import { Button, Menu, MenuItem } from "@mui/material";
import { useState, useRef } from "react";
import "apexcharts/dist/apexcharts.css";
import { withStyles } from "@mui/styles";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import DistributionFilterDate from "./DistributionFilterDate";
import FractionalFilterDate from "./FractionalFilterDate";
import CharnRateDate from "./CharnRateDate";

export default function DashboardDashedChart({
  title,
  subheader,
  chart,
  queryParameters,
  setQueryParameters,
  height,
  width,
  type,
  isfilter,
  changeButtonWidth,
  isFetching,
  ...other
}) {
  const { labels, colors, series, options } = chart;
  const [anchorEl, setAnchorEl] = useState(null);
  const chartRef = useRef();
  const [values, setValues] = useState("1");
  const handleChange = (event, newValue) => {
    setValues(newValue);
  };
  const AntTabs = withStyles({
    root: {
      borderBottom: "0px solid #fff",
      display: "flex",
      justifyContent: "center",
    },
    indicator: {
      backgroundColor: "#E9C237",
      height: 2,
      marginBottom: 8,
    },
  })(Tabs);
  const AntTab = withStyles((theme) => ({
    root: {
      padding: theme.spacing(0.5, 1),
      "&:hover": {
        color: "#E9C237",
        opacity: 1,
      },
      "&$selected": {
        color: "#E9C237",
      },
      "&:focus": {
        color: "#E9C237",
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleDownloadPNG = () => {
    const chartInstance = chartRef.current.chart;
    chartInstance.dataURI().then((dataURI) => {
      const link = document.createElement("a");
      link.href = dataURI.imgURI;
      link.download = "chart.png";
      link.click();
    });
  };
  const handleDownloadCSV = () => {
    if (!labels || labels.length === 0 || !series || series.length === 0) {
      console.error("Chart labels or series data is empty.");
      return;
    }
    let csvContent = "data:text/csv;charset=utf-8," + labels.join(",") + "\n";
    series.forEach((dataSeries) => {
      if (dataSeries && dataSeries.data && dataSeries.data.length > 0) {
        csvContent += dataSeries.data.join(",") + "\n";
      }
    });
    const link = document.createElement("a");
    link.href = encodeURI(csvContent);
    link.download = "chart_data.csv";
    link.click();
  };
  const handleDownloadSVG = () => {
    try {
      const chartElement = document.querySelector(".apexcharts-svg"); // Select the SVG element
      if (chartElement) {
        const svgData = chartElement.outerHTML; // Get the SVG content

        // Create a download link
        const link = document.createElement("a");
        link.href = "data:image/svg+xml;utf8," + encodeURIComponent(svgData);
        link.download = `chart.svg`; // Specify the filename
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup
        document.body.removeChild(link);
      } else {
        console.error("Chart element not found.");
      }
    } catch (error) {
      console.error("Error exporting chart:", error);
    }
  };

  const chartOptions = useChart({
    colors,
    plotOptions: {
      bar: {
        columnWidth: "16%",
      },
      line: {
        curve: "straight",
      },
    },
    fill: {
      type: Array.isArray(series) ? series.map((i) => i.fill) : [],
    },
    labels,
    axisX: {
      position: "start",
    },
    xaxis: {
      position: "start",
      type: "category",
    },
    series: {
      line: {
        curve: "straight",
      },
    },
    stroke: {
      curve: "straight",
      width: 1.5,
      dashArray: [1, 10],
    },
    chart: {
      toolbar: {},
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value) => {
          if (typeof value !== "undefined") {
            return `${value.toFixed(0)}`;
          }
          return value;
        },
      },
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        // action={}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent:
            changeButtonWidth === "1" ? "space-evenly" : "space-between",
          marginLeft: "2%",
          marginRight: "2%",
          flexWrap: "wrap",
        }}
      >
        <Box>
          {type === "fractional" && (
            <FractionalFilterDate
              queryParameters={queryParameters}
              setQueryParameters={setQueryParameters}
            />
          )}
          {type === "Distribution" && (
            <DistributionFilterDate
              queryParameters={queryParameters}
              setQueryParameters={setQueryParameters}
            />
          )}
          {type === "charnRate" && (
            <CharnRateDate
              queryParameters={queryParameters}
              setQueryParameters={setQueryParameters}
            />
          )}
        </Box>
        {type !== "fractional" && (
          <Box sx={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
            <Button
              sx={{
                "&:hover": { backgroundColor: "#E9C237" },
                textTransform: "none",
                height: "38px",
                width: "auto",
                backgroundColor: "#E9C237",
                borderRadius: "6px",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
              }}
              aria-controls="download-menu"
              aria-haspopup="true"
              onClick={handleOpenMenu}
              endIcon={
                <img
                  style={{ width: "22px", height: "22px" }}
                  src={`../../assets/arrowdown.png`}
                  alt="Custom Icon"
                />
              }
            >
              Download Report
            </Button>
            <Menu
              id="download-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              {/* <MenuItem onClick={() => handleDownload("png")}>PNG</MenuItem> */}
              <MenuItem onClick={handleDownloadPNG}>PNG</MenuItem>
              <MenuItem onClick={handleDownloadSVG}>svg</MenuItem>
              <MenuItem onClick={handleDownloadCSV}>CSV</MenuItem>
            </Menu>
          </Box>
        )}
      </Box>
      {isfilter === "filter" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TabContext sx={{}} value={values}>
            <Box
              sx={{
                display: "flex",
                ml: "20px",
                mt: "20px",
              }}
            >
              <AntTabs
                value={values}
                onChange={handleChange}
                aria-label="ant example"
              >
                <AntTab label="Day" value="1" />
                <AntTab label="Week" value="2" />
              </AntTabs>
            </Box>
            <TabPanel value="1">
              {/* <Typography>test1</Typography> */}
            </TabPanel>
            <TabPanel value="2">
              {/* <Typography>test2</Typography> */}
            </TabPanel>
          </TabContext>
        </Box>
      )}

      <Box
        sx={{
          p: 2,
          pb: 1,
          ml: 2,
          width: "96%", // Ensure full width
        }}
      >
        {isFetching ? (
          <table className="w-full">
            <tbody>
              <tr className="animate-pulse">
                <td className="py-2 whitespace-nowrap w-full">
                  <div className="h-[21.6rem] bg-gray-200 rounded w-full" />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <Chart
            ref={chartRef}
            dir="ltr"
            series={series}
            options={chartOptions}
            width={width || "100%"} // Set chart width dynamically
            height={height}
          />
        )}
      </Box>
    </Card>
  );
}

DashboardDashedChart.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
};
