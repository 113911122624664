import { useQuery, useMutation, useQueryClient } from "react-query";
import { axiosInstance as axios } from "../config";

// Fetch banks
export const useFetchBanks = (q) => {
  return useQuery(["banks", q.page], async () => {
    const response = await axios.get(`/api/banks/index`, {
      params: {
        page: q.page,
      },
    });
    return response.data?.data; // Assuming response.data contains the banks data
  });
};

// Delete a bank
export const useDeleteBank = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id) => {
      const response = await axios.get(`/api/banks/delete/${id}`);
      return response.data; // Assuming response.data contains the result of deletion
    },
    {
      // On success, invalidate the 'banks' query to refetch the list
      onSuccess: () => {
        queryClient.invalidateQueries(["banks"]);
      },
    }
  );
};

// Add a new bank
export const useAddNewBank = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (body) => {
      const response = await axios.post("/api/banks/add", body);
      return response.data; // Assuming response.data contains the added bank
    },
    {
      // On success, invalidate the 'banks' query to refetch the list
      onSuccess: () => {
        queryClient.invalidateQueries(["banks"]);
      },
    }
  );
};

// Edit a bank
export const useEditBank = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, body }) => {
      const response = await axios.post(`/api/banks/edit/${id}`, body);
      return response.data; // Assuming response.data contains the updated bank
    },
    {
      // On success, invalidate the 'banks' query to refetch the list
      onSuccess: () => {
        queryClient.invalidateQueries(["banks"]);
      },
    }
  );
};
