import { Box, Grid, Typography } from "@mui/material";
import BlockedUsersChart from "./BlockedUsersChart";
import { useState } from "react";
import DashboardDashedChart from "./DashboardDashedChart";
import DonutChart from "./donutChart";
import DashboardLineChart from "./DashboardLineChart";
import DashboardCitiesChart from "./DashboardCitiesChart";
import OverViewMainCard from "../../components/OverViewMainCard";
import newEye from "../../svg/neweye.svg";
import { useFetchUsersReports } from "../../services/apis/overview";
import dayjs from "dayjs";
import NewTable from "../../components/common/NewTable";
import { ItemsColumns } from "../../components/common/Columns";

const UsersTabPanel = () => {
  const today = new Date();
  const date14DaysAgo = new Date();
  date14DaysAgo.setDate(today.getDate() - 14);
  const formattedToday = today.toISOString().split("T")[0];

  const prevWeek = new Date(today.setDate(today.getDate() - 7))
    .toISOString()
    .split("T")[0];
  const calculatePercentage = (value) =>
    (value / usersReports ? usersReports?.statistics?.total : 1) * 100;

  const [queryParameters, setQueryParameters] = useState({
    churnRate_startDate: prevWeek,
    churnRate_endDate: formattedToday,
    age_date_from: prevWeek,
    age_date_to: formattedToday,
    cities: [1, 2, 3, 4, 5, 6, 7, 8] || [],
  });
  const { data: usersReports, isFetching: usersIsFetching } =
    useFetchUsersReports(queryParameters);
  const walletBalancesColumns = ItemsColumns([
    {
      field: "full_name",
      headerName: "Username",
    },
    {
      field: "phone",
      headerName: "Phone number",
    },
    {
      field: "transactionsCount",
      headerName: "Transactions number",
    },
    {
      field: "transactionsAmount",
      headerName: "Wallet Balance",
    },
    {
      field: "is_online",
      headerName: "Online Status",
      isOnline: true,
    },
  ]);

  const activeUsersColumns = ItemsColumns([
    {
      field: "actions",
      headerName: "",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <img
          style={{ cursor: "pointer" }}
          src={newEye}
          alt="eye"
          // onClick={() => handleClick(params)}
        />
      ),
    },
    {
      field: "UserRank",
      headerName: "User Rank",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
      valueGetter: (params) => params.api.getRowIndex(params.row.id) + 1,
    },
    {
      field: "full_name",
      headerName: "Username",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
    {
      field: "phone",
      headerName: "Phone number",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
    {
      field: "LoginFrequency",
      headerName: "Login Frequency",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
    {
      field: "AvgTime",
      headerName: "Avg. Time Spending",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
    {
      field: "is_online",
      headerName: "Online Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
      renderCell: (params) => {
        let style = {
          fontFamily: "Roboto",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          color: params.value === 0 ? "#F23045" : "#40C754",
        };
        return params.value === 0 ? (
          <Typography sx={style}>Offline</Typography>
        ) : (
          <Typography sx={style}>Online</Typography>
        );
      },
    },
  ]);

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            color: "#808080",
            fontSize: "16px",
            fontWeight: "500",
            mb: 2,
            ml: 2,
          }}
        >
          OverView
        </Typography>
      </Box>
      {/* users overview */}

      <Grid container spacing={1.5} pt={4}>
        {/* Main Cards Start  */}
        <Grid item lg={3} xs={12} sm={6} md={3} p={1}>
          <OverViewMainCard
            backgroundColor="#FFFFFF"
            title="Total Users"
            total={
              !usersIsFetching ? (
                usersReports?.statistics?.total?.toLocaleString()
              ) : (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              )
            }
            // icon={<img alt="icon" src={`../../assets/radar.png`} />}
            value={"User"}
          />
        </Grid>
        <Grid item lg={3} xs={12} sm={6} md={3} p={1}>
          <OverViewMainCard
            type={"DeletedUsers"}
            backgroundColor="#FFFFFF"
            title="Deleted Users"
            total={
              !usersIsFetching ? (
                usersReports?.statistics?.deleted?.toLocaleString()
              ) : (
                <tr className="animate-pulse ">
                  <td className=" py-2 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              )
            }
            subtext={"From Total Users"}
            // icon={<img alt="icon" src={`../../assets/dollar-circle.png`} />}
            infoIcon={
              <img alt="infoIcon" src={`../../assets/info-circle.png`} />
            }
            value={"User"}
            arrow={<img alt="icon" src={`../../assets/arrow-up.png`} />}
            perc={`+${calculatePercentage(
              usersReports ? usersReports?.statistics?.deleted : 0
            ).toFixed(2)} %`}
            perccolor={"#E9C237"}
            percbackground={"rgba(233, 194, 55, 0.1)"}
          />
        </Grid>
        <Grid item lg={3} xs={12} sm={6} md={3} p={1}>
          <OverViewMainCard
            isLoading={usersIsFetching}
            subtext={"From last month"}
            backgroundColor="#FFFFFF"
            title="New Users"
            total={
              !usersIsFetching ? (
                usersReports?.statistics?.new?.toLocaleString()
              ) : (
                <tr className="animate-pulse ">
                  <td className=" py-2 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              )
            }
            // icon={<img alt="icon" src={`../../assets/box.png`} />}
            value={"User"}
            arrow={<img alt="icon" src={`../../assets/arrow-up.png`} />}
            perc={`+${calculatePercentage(
              usersReports ? usersReports?.statistics?.new : 1
            ).toFixed(2)} %`}
            perccolor={"#E9C237"}
            percbackground={"rgba(233, 194, 55, 0.1)"}
          />
        </Grid>
        <Grid item lg={3} xs={12} sm={6} md={3} p={1}>
          <OverViewMainCard
            subtext={"From last month"}
            backgroundColor="#FFFFFF"
            title="Login Frequency"
            total={
              !usersIsFetching ? (
                "2500"
              ) : (
                <tr className="animate-pulse ">
                  <td className=" py-2 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              )
            }
            // icon={<img alt="icon" src={`../../assets/profile-circle.png`} />}
            value={""}
            arrow={<img alt="icon" src={`../../assets/arrow-up.png`} />}
            perc={`+${"20"} %`}
            perccolor={"#E9C237"}
            percbackground={"rgba(233, 194, 55, 0.1)"}
          />
        </Grid>
        {/* End Main Cards  */}

        {/* New User Acquisition Rate Chart Start  */}
        <Grid item xs={12} md={12} lg={7.7}>
          <DashboardDashedChart
            isFetching={usersIsFetching}
            type={"charnRate"}
            height={340}
            width={"100%"}
            style={{
              boxShadow: "0px 0px 0px #D3D3D3",
              borderRadius: "24px",
            }}
            title="New User Acquisition Rate & Churn Rate"
            changeButtonWidth="1"
            queryParameters={queryParameters}
            setQueryParameters={setQueryParameters}
            chart={{
              labels: usersReports?.churnRate?.map((item) => {
                const timezoneOffset = new Date().getTimezoneOffset();
                const date = new Date(item.date.replace(/'/g, ""));
                date.setHours(date.getHours() - timezoneOffset / 60);
                return dayjs(date).format("YYYY-MMM-DD");
              }),
              series: [
                {
                  name: "New User",
                  type: "area",
                  fill: "gradient",
                  data: usersReports?.churnRate?.map((item) => item.new_user),
                  color: "#E9C237",
                },
                {
                  name: "Churn Rate",
                  type: "area",
                  fill: "gradient",
                  data: usersReports?.churnRate?.map((item) => item.sessions),
                  color: "#FF453A",
                },
              ],
            }}
          />
        </Grid>
        <Grid
          item
          mt={1.5}
          sx={{
            backgroundColor: "#ffffff",
            height: "500px",
            borderRadius: "24px",
            marginLeft: "2%",
          }}
          xs={12}
          md={12}
          lg={4}
        >
          <DonutChart
            isFetching={usersIsFetching}
            type="donut"
            sx={{ borderRadius: "24px", boxShadow: "none" }}
            title="KYC Completion Rate"
            chart={{
              series:
                (usersReports &&
                  usersReports?.kycChart?.map((item) => ({
                    label: item.kyc_status,
                    value: item.total,
                  }))) ||
                [],
            }}
            donutLabel="Total Users"
          />
        </Grid>
        {/* End New User Chart  */}

        {/*Blocked Users and wallet Balances Start */}
        <Grid
          item
          mt={3}
          sx={{
            backgroundColor: "#ffffff",
            height: "416px",
            borderRadius: "24px",
            marginLeft: "1.5%",
          }}
          xs={12}
          md={12}
          lg={4}
        >
          <BlockedUsersChart
            isFetching={usersIsFetching}
            type="donut"
            sx={{ borderRadius: "24px", boxShadow: "none" }}
            title="Blocked Users"
            chart={{
              series: [
                {
                  label: "Reason1",
                  value: 65,
                },
                {
                  label: "Reason2",
                  value: 20,
                },
                {
                  label: "Other",
                  value: 15,
                },
              ],
            }}
            labelTitle={"Total Users"}
            labelNumber={"50"}
          />
        </Grid>
        <Grid
          item
          p={2}
          mt={3}
          sx={{
            backgroundColor: "#ffffff",
            height: "418px",
            borderRadius: "24px",
            marginLeft: "2%",
          }}
          xs={12}
          md={12}
          lg={7.5}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ color: "#808080", fontSize: "20px", fontWeight: "600" }}
            >
              Highest Wallet Balances
            </Typography>
          </Box>
          <NewTable
            noPagination
            columns={walletBalancesColumns}
            isLoading={usersIsFetching}
            data={usersReports?.walletBalance}
            currentPage={queryParameters}
            rows={6}
          />
          {/* <Box
            m="20px"
            sx={{
              height: "auto",
              background: "#FFF",
              borderRadius: "12px",
            }}
          >
            <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
              <DataGrid
                hideFooterSelectedRowCount={true}
                rows={
                  usersIsFetching
                    ? []
                    : usersReports
                    ? usersReports?.walletBalance
                    : []
                }
                loading={usersIsFetching}
                columns={walletBalancesColumns}
                autoHeight={true}
                components={{
                  Footer: () => null,
                }}
                disableColumnMenu={true}
              />
            </Box>
          </Box> */}
        </Grid>
        {/*End Blocked Users and wallet Balances  */}

        {/*Age line chart and gender Start */}
        <Grid mt={1.4} item xs={12} md={12} lg={7.7}>
          <DashboardLineChart
            isFetching={usersIsFetching}
            type={"age"}
            height={288}
            width={"100%"}
            style={{
              boxShadow: "0px 0px 0px #D3D3D3",
              borderRadius: "24px",
            }}
            title="Age"
            queryParameters={queryParameters}
            setQueryParameters={setQueryParameters}
            chart={{
              labels: usersReports?.ageChart?.map((item) => item.title),
              series: [
                {
                  name: "Male",
                  type: "column",
                  fill: "solid",
                  data: usersReports?.ageChart?.map((item) => item.male),
                  color: "#E9C237",
                },
                {
                  name: "Female",
                  type: "column",
                  fill: "solid",
                  data: usersReports?.ageChart?.map((item) => item.female),
                  color: "#FBF3D7",
                },
              ],
            }}
          />
        </Grid>
        <Grid
          item
          mt={3}
          sx={{
            backgroundColor: "#ffffff",
            height: "450px",
            borderRadius: "24px",
            marginLeft: "2%",
          }}
          xs={12}
          md={12}
          lg={4}
        >
          <BlockedUsersChart
            isFetching={usersIsFetching}
            type="donut"
            sx={{ borderRadius: "24px", boxShadow: "none" }}
            title="Gender"
            chart={{
              series:
                (usersReports &&
                  usersReports?.genderChart?.map((item) => ({
                    label: item.gender,
                    value: item.total,
                  }))) ||
                [],
            }}
            labelTitle={"Total Users"}
            labelNumber={
              (usersReports &&
                usersReports?.genderChart?.reduce(
                  (acc, item) => acc + item.total,
                  0
                )) ||
              0
            }
          />
        </Grid>
        {/* End Age line chart and gender  */}

        {/* online offline chart Start  */}
        <Grid mt={2} item xs={11.8} md={11.8} lg={11.8}>
          <DashboardDashedChart
            isFetching={usersIsFetching}
            height={440}
            width={"100%"}
            style={{
              boxShadow: "0px 0px 0px #D3D3D3",
              borderRadius: "24px",
            }}
            title="Online and Offline Users"
            queryParameters={queryParameters}
            setQueryParameters={setQueryParameters}
            chart={{
              labels: ["2024-Apr-01", "2024-Apr-02", "2024-Apr-03"],
              series: [
                {
                  name: "Online",
                  type: "area",
                  fill: "gradient",
                  data: [23, 40, 37],
                  color: "#E9C237",
                },
                {
                  name: "Offline",
                  type: "area",
                  fill: "gradient",
                  data: [44, 35, 41],
                  color: "#FF453A",
                },
              ],
            }}
          />
        </Grid>
        {/* End online offline chart  */}

        {/* User Sentiment Analysis cities Start  */}
        <Grid
          item
          mt={3}
          sx={{
            backgroundColor: "#ffffff",
            height: "488px",
            borderRadius: "24px",
            marginLeft: "1.5%",
          }}
          xs={12}
          md={12}
          lg={4}
        >
          <DonutChart
            isFetching={usersIsFetching}
            type="donut"
            sx={{ borderRadius: "24px", boxShadow: "none" }}
            title="User Sentiment Analysis"
            chart={{
              series:
                (usersReports &&
                  usersReports?.ticketsChart?.map((item) => ({
                    label: item.feedback,
                    value: item.total,
                  }))) ||
                [],
            }}
            donutLabel="Total Users"
          />
        </Grid>
        <Grid mt={1.4} item xs={12} md={12} lg={7.8}>
          <DashboardCitiesChart
            isFetching={usersIsFetching}
            height={340}
            width={"100%"}
            style={{
              boxShadow: "0px 0px 0px #D3D3D3",
              borderRadius: "24px",
            }}
            title="Cities"
            queryParameters={queryParameters}
            setQueryParameters={setQueryParameters}
            chart={{
              labels: usersReports?.cities?.map((item) => item.name),
              series: [
                {
                  name: "Total",
                  type: "column",
                  fill: "solid",
                  data: usersReports?.cities?.map((item) => item.totalUsers),
                  color: "#E9C237",
                },
              ],
            }}
          />
        </Grid>
        {/*End User Sentiment Analysis and cities */}

        {/* Most Active Users Start  */}
        <Grid
          item
          p={2}
          mt={3}
          sx={{
            backgroundColor: "#ffffff",
            height: "418px",
            borderRadius: "24px",
          }}
          ml={2}
          xs={12}
          md={12}
          lg={12}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ color: "#808080", fontSize: "20px", fontWeight: "600" }}
            >
              Most Active Users
            </Typography>
          </Box>
          <NewTable
            noPagination
            columns={activeUsersColumns}
            data={usersReports?.mostActiveUser}
            isLoading={usersIsFetching}
            currentPage={queryParameters}
            rows={5}
          />
        </Grid>
        {/* End Most Active Users  */}
      </Grid>

      {/* end users overview */}
    </Box>
  );
};
export default UsersTabPanel;
