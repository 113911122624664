import { useState } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";

import { DataGridStyle } from "../../styles";
import { useNavigate } from "react-router-dom";
import ActionAlert from "../user-profile/ActionAlert";
import CustomDialog from "../../components/CustomDialog";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import EditRejectReasons from "./EditRejectReasons";
import {
  useRejectReasons,
  useDeleteRejectReason,
} from "../../services/apis/rejectionReasons";
import { handlePageChange } from "../../services/utils/filters";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import AddButton from "../../components/common/AddButton";
import { ItemsColumns } from "../../components/common/Columns";
import NewTable from "../../components/common/NewTable";
const KYCRejectionReasons = () => {
  const [disableWhileDelete, setDisableWhileDelete] = useState(false);
  const deleteBlockReasonMutation = useDeleteRejectReason();
  const [open, setOpen] = useState(false);
  const [selectedBlockReasonId, setBlockReasonId] = useState(null);
  const [selectedBlockReason, setSelectedBlockReason] = useState(null);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const navigate = useNavigate();
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
  });
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };
  const { data, isFetching, refetch } = useRejectReasons(queryParameters);

  const handleAddFAQ = () => {
    navigate(`/AddNewRejectReason`);
  };
  const handleOpenDeleteAlert = (id) => {
    setBlockReasonId(id);
    setOpenDeletedAlert(true);
  };
  const handleEditBlockReason = (blockReasonId) => {
    setSelectedBlockReason(blockReasonId);
    setOpen(true);
  };
  const handleDeleteBlockReason = async () => {
    if (selectedBlockReasonId) {
      setDisableWhileDelete(true);
      try {
        await deleteBlockReasonMutation.mutateAsync(selectedBlockReasonId);
        setDisableWhileDelete(false);
        setSuccessDialogOpen(true);
        handleCloseDeleteAlert();
        refetch(queryParameters);
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    }
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const rejectionReasonsColumns = ItemsColumns([
    {
      field: "Reason",
      specialRender: "customRender",
      renderFunction: (params) => params?.row?.reason_en,
    },
    {
      field: "action",
      headerName: "Actions",
      actions: {
        edit: (params) => {
          handleEditBlockReason(params.row.id);
        },
        delete: (params) => {
          handleOpenDeleteAlert(params.row.id);
        },
      },
    },
  ]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <DashboardMainHeader />

      <Header title="Rejection Reasons" marginL={"25px"} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: 2,
          pr: 2,
        }}
      >
        <AddButton
          title={"+ New Reason"}
          onClick={() => {
            handleAddFAQ();
          }}
        />
      </Box>
      <Box
        m="20px"
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <NewTable
            columns={rejectionReasonsColumns}
            isLoading={isFetching}
            data={data?.result}
            totalPages={data?.totalPages}
            totalItems={data?.totalItems}
            handlePageChange={(newPage) =>
              handlePageChange(newPage, queryParameters, setQueryParameters)
            }
            currentPage={queryParameters}
          />
          {/* <DataGrid
            components={{
              LoadingOverlay: () => CustomLoadingOverlay({ columns: 2 }),
            }}
            rows={isFetching ? [] : data?.result}
            columns={blockReasonsColumns}
            loading={isFetching}
            rowsPerPageOptions={[10]}
            pageSize={10}
            totalPages={data?.totalPages}
            rowCount={data?.totalItems || 0}
            paginationMode="server"
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
            localeText={{
              noRowsLabel: "No Block Reasons",
            }}
          /> */}
        </Box>
      </Box>
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteBlockReason}
        message={`Are you sure you want to delete this Reason ?`}
        label={"Delete"}
        disabled={disableWhileDelete}
      />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Reason has been deleted Successfully."
        message=""
        buttonText="Go to Rejection Reason"
      />
      <EditRejectReasons
        fetchData={() => {
          refetch(queryParameters);
        }}
        rejectReasons={data?.result || []}
        rejectReasonsId={selectedBlockReason}
        open={open}
        onClose={handleClose}
        label={"Save"}
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default KYCRejectionReasons;
