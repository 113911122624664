import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

// Custom hook to provide the handleCloseSnack function
export const useCloseSnack = () => {
  const dispatch = useDispatch();

  // Define the handleCloseSnack function
  const handleCloseSnack = (event, reason) => {
    // Define the handleCloseSnack function
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };

  // Return the function so it can be used in components
  return handleCloseSnack;
};
