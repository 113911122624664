import { useMutation, useQuery } from "react-query";
import { axiosInstance as axios } from "../config";

const getAboutUs = async () => {
  try {
    const response = await axios.get(`/api/config/about`);
    return response.data.data; // Assuming the data you need is in the 'data' property
  } catch (error) {
    throw error;
  }
};
export const useAboutUs = () => {
  return useQuery("aboutUs", getAboutUs, {
    refetchOnWindowFocus: false,
  });
};
const editAboutUs = async ({ id, body }) => {
  try {
    const response = await axios.post(`/api/config/edit/${id}`, body);
    return response.data; // Assuming the data you need is in the 'data' property
  } catch (error) {
    throw error;
  }
};
export const useEditAboutUs = () => {
  return useMutation(editAboutUs);
};
