import { GridOverlay } from "@mui/x-data-grid";
import React from "react";

export const CustomLoadingOverlay = ({ columns = 7, rows = 8 }) => {
  const columnWidth = 100 / columns - 4 / columns + "%"; // Adjust for gaps
  const rowsArray = Array.from({ length: rows });
  const columnsArray = Array.from({ length: columns });

  return (
    <GridOverlay>
      <div style={{ width: "100%", padding: "20px" }}>
        <div className="py-5 flex flex-col gap-6">
          {rowsArray.map((_, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <div className="px-5 flex items-center justify-center gap-4">
                {columnsArray.map((_, colIndex) => (
                  <React.Fragment key={colIndex}>
                    <div
                      className="h-4 bg-gray-200 rounded-xl animate-pulse"
                      style={{ width: columnWidth }}
                    />
                    {/* {rowIndex === 0 && colIndex < columns - 1 && <p>|</p>} */}
                  </React.Fragment>
                ))}
              </div>
              {rowIndex < rows - 1 && <hr />}
            </React.Fragment>
          ))}
        </div>
      </div>
    </GridOverlay>
  );
};
