import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { axiosInstance as axios } from "../../../services/config";
import TextFieldComponent from "../../../components/TextFieldComponent";
import login from "../../../svg/login.svg";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitStatus, setSubmitStatus] = useState(null);
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (/[A-Z]/.test(pathname)) {
      navigate("/forget-password");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const forget = async (email) => {
    try {
      const body = { email };
      const response = await axios.post(`/api/admin/forget-password`, body);
      return response;
    } catch (error) {
      if (error.response.data.status === 422) {
        setErrorMessage("Invalid Email");
      } else {
        setErrorMessage(error.response.data.message);
      }
    }
  };

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;

    setEmail(enteredEmail);
  };
  const isEmailValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!isEmailValid()) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    try {
      const response = await forget(email);
      if (response.status === 200) {
        setSubmitStatus("success");
      } else if (response.status === 400 || response.status === 403) {
        setErrorMessage(response.message);
      } else if (response.status === 422) {
        setErrorMessage("Invalid Email");
      }
    } catch (error) {
      console.log("error ", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className=" h-screen">
      <div className="h-full flex flex-col md:flex-row ">
        <div className="flex flex-col h-full bg-white items-center justify-center w-full md:w-1/2 p-4">
          <form
            className="flex flex-col w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"
            autoComplete="off"
            noValidate
          >
            <Typography
              sx={{ color: "#E9C237", fontSize: "36px" }}
              variant="h3"
            >
              {submitStatus === "success"
                ? "Reset Password"
                : "Forget Password"}
            </Typography>
            <Typography
              sx={{
                color: "#B3B3B3",
                fontSize: "14px",
                marginTop: "15px",
                marginBottom: "10px",
              }}
              variant="h3"
            >
              {submitStatus === "success"
                ? "You will receive an email to rest your password, Open the email and click on the provided link to proceed with the password reset."
                : "Provide the email address associated with your account in the field below. We'll send you a OTP to reset your password"}
            </Typography>
            {submitStatus === "success" ? (
              ""
            ) : (
              <TextFieldComponent
                type="text"
                style={{ width: "100%", height: "48px" }}
                name="Email"
                placeholder="Enter your Email"
                value={email}
                error={errorMessage}
                onChange={handleEmailChange}
              />
            )}
            {submitStatus === "success" ? (
              ""
            ) : (
              <Button
                disabled={isLoading}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#E9C237",
                  "&:hover": { backgroundColor: "#E9C237" },
                  height: "48px",
                  marginTop: "10%",
                  borderRadius: "9px",
                }}
              >
                Send OTP
              </Button>
            )}
          </form>
        </div>
        <div className="flex h-full bg-[#F7F7F7] items-center justify-center w-full md:w-1/2 lg:w-1/2">
          <div className="w-[450px] h-[450px]">
            <img src={login} alt="loginimage" className="h-full w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
