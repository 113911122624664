import { Box, Button, Typography } from "@mui/material";
import { useLogin } from "../../../services/apis/auth";
import { useForm } from "react-hook-form";
import RHFTextField from "../../../components/RHF/RHFTextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import login from "../../../svg/login.svg";
import ButtonLoader from "../../../components/ButtonLoader";

const schema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});
const NewLogin = () => {
  const dispatch = useDispatch();

  const { mutate: loginMutate, error, isLoading } = useLogin();
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const onSubmit = async ({ email, password }) => {
    loginMutate({ email, password });
  };
  useEffect(() => {
    if (error) {
      dispatch(
        setData({ message: "Invalid Email or Password", openSnack: true })
      );
    } else {
      dispatch(setData({ openSnack: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <div className=" h-screen">
      <div className="h-full flex flex-col md:flex-row ">
        {/* form */}
        <div className="flex flex-col h-full bg-white items-center justify-center w-full md:w-1/2 p-4">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"
            autoComplete="off"
            noValidate
          >
            <Typography
              sx={{ color: "#4C4C4C", fontSize: "40px" }}
              variant="h3"
              className="text-3xl sm:text-4xl"
            >
              Welcome Back
            </Typography>
            <Typography
              sx={{
                color: "#4C4C4C",
                fontSize: "32px",
                marginTop: "15px",
                marginBottom: "15px",
              }}
              variant="h3"
              className="text-2xl sm:text-3xl"
            >
              Login
            </Typography>
            <Box
              className="flex flex-col gap-4"
              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <RHFTextField
                sx={{
                  "& fieldset": !errors?.password ? { border: "none" } : "",
                  backgroundColor: errors?.password ? "#FFF" : "#F7F7F7",
                  borderRadius: "9px",
                }}
                name={"email"}
                control={control}
                label="E-mail"
                errors={errors?.email}
              />
              <RHFTextField
                sx={{
                  "& fieldset": !errors?.password ? { border: "none" } : "",
                  backgroundColor: errors?.password ? "#FFF" : "#F7F7F7",
                  borderRadius: "9px",
                }}
                name={"password"}
                control={control}
                label="Password"
                errors={errors?.password}
                type="password"
              />
            </Box>
            <Typography
              sx={{
                color: "#E9C237",
                fontSize: "14px",
                alignSelf: "flex-end",
                display: "flex",
                cursor: "pointer",
                marginTop: "20px",
                marginBottom: "10px",
              }}
              className="text-sm sm:text-base mt-4 mb-2 cursor-pointer self-end"
              variant="h3"
              onClick={() => {
                navigate("/forget-password");
              }}
            >
              Forget Password ?
            </Typography>
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#E9C237",
                "&:hover": { backgroundColor: "#E9C237" },
                height: "48px",
                marginTop: "15px",
              }}
              className="h-12 w-full sm:w-auto sm:px-8 mt-4"
            >
              {isLoading ? <ButtonLoader color="inherit" size={20} /> : "Login"}
            </Button>
          </form>
        </div>
        {/* image */}
        <div className="flex h-full bg-[#F7F7F7] items-center justify-center w-full md:w-1/2 lg:w-1/2">
          <div className="w-[450px] h-[450px]">
            <img src={login} alt="login" className="h-full w-full" />
          </div>
        </div>
      </div>
      <ReusableSnackbar />
    </div>
  );
};

export default NewLogin;
