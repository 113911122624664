import { useQuery, useMutation, useQueryClient } from "react-query";
import { axiosInstance as axios } from "../config";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

const getAllLicenses = async (params) => {
  const response = await axios.get("/api/licenses/index", { params });
  return response.data?.data;
};

// Update the useGetAllLicenses hook to accept parameters
export const useGetAllLicenses = (params) => {
  return useQuery(["license", params?.id], () => getAllLicenses(params), {
    refetchOnWindowFocus: false,
    keepPreviousData: true, // Optional: Useful for paginated data
  });
};
export const useDeleteLicense = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async (id) => {
      const response = await axios.get(`/api/licenses/delete/${id}`);
      return response.data; // Assuming response.data contains the result of deletion
    },
    {
      // On success, invalidate the 'vouchers' query to refetch the list
      onSuccess: () => {
        queryClient.invalidateQueries(["license"]);
      },
      onError: (error) => {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      },
    }
  );
};
// export const fetchInventoryStock = async ({ page, orderBy, sort, type }) => {
export const useAddLicense = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async (body) => {
      const response = await axios.post(`/api/licenses/add`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data; // Assuming response.data contains the result of deletion
    },
    {
      // On success, invalidate the 'vouchers' query to refetch the list
      onSuccess: () => {
        queryClient.invalidateQueries(["license"]);
      },
      onError: (error) => {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      },
    }
  );
};
export const useAddQuestionLicense = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    // Accept both `id` and `body` as parameters
    async ({ id, body }) => {
      const response = await axios.post(
        `/api/licenses/add-question/${id}`,
        body
      );
      return response.data; // Assuming response.data contains the result of the operation
    },
    {
      onSuccess: () => {
        // Invalidate the 'license' query to refetch the list
        queryClient.invalidateQueries(["license"]);
      },
      onError: (error) => {
        dispatch(
          setData({
            openSnack: true,
            message: error?.response?.data?.message || "An error occurred",
          })
        );
      },
    }
  );
};

export const useDeleteQuestionLicense = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async (id) => {
      const response = await axios.get(`/api/licenses/delete-question/${id}`);
      return response.data; // Assuming response.data contains the result of deletion
    },
    {
      // On success, invalidate the 'vouchers' query to refetch the list
      onSuccess: () => {
        queryClient.invalidateQueries(["license"]);
      },
      onError: (error) => {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      },
    }
  );
};
export const useEditLicense = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    // Pass both 'id' and 'body' as arguments to the mutation function
    async ({ id, body }) => {
      const response = await axios.post(`/api/licenses/edit/${id}`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data; // Assuming response.data contains the result of the edit operation
    },
    {
      // On success, invalidate the 'license' query to refetch the updated data
      onSuccess: () => {
        queryClient.invalidateQueries(["license"]);
      },
      onError: (error) => {
        dispatch(
          setData({
            openSnack: true,
            message: error.response?.data?.message || "An error occurred.",
          })
        );
      },
    }
  );
};
export const useEditQuestionLicense = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    // Pass both 'id' and 'body' as arguments to the mutation function
    async ({ id, body }) => {
      const response = await axios.post(
        `/api/licenses/edit-question/${id}`,
        body
      );
      return response.data; // Assuming response.data contains the result of the edit operation
    },
    {
      // On success, invalidate the 'license' query to refetch the updated data
      onSuccess: () => {
        queryClient.invalidateQueries(["license"]);
      },
      onError: (error) => {
        dispatch(
          setData({
            openSnack: true,
            message: error.response?.data?.message || "An error occurred.",
          })
        );
      },
    }
  );
};
