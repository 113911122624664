import React from "react";
import { Box } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const DistributionFilterDate = ({ queryParameters, setQueryParameters }) => {
  const handleFromDate = (date) => {
    const formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : "";
    setQueryParameters((prevParams) => ({
      ...prevParams,
      distributionOfFraction_startDate: formattedDate,
    }));
  };

  const handleToDate = (date) => {
    const formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : "";
    setQueryParameters((prevParams) => ({
      ...prevParams,
      distributionOfFraction_endDate: formattedDate,
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        flexWrap: "wrap",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label="From"
            onChange={(d) => handleFromDate(d)}
            value={
              queryParameters.distributionOfFraction_startDate !== ""
                ? dayjs(queryParameters.distributionOfFraction_startDate)
                : null
            }
            maxDate={dayjs()}
            sx={{ minWidth: "120px" }}
          />
        </DemoContainer>
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label="To"
            onChange={(d) => handleToDate(d)}
            value={
              queryParameters.distributionOfFraction_endDate !== ""
                ? dayjs(queryParameters.distributionOfFraction_endDate)
                : null
            }
            minDate={
              queryParameters.distributionOfFraction_startDate !== ""
                ? dayjs(queryParameters.distributionOfFraction_startDate)
                : null
            }
            maxDate={dayjs()}
            sx={{ minWidth: "120px" }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default DistributionFilterDate;
