import { useState } from "react";
import { Box, Container } from "@mui/material";

import DashboardMainHeader from "../../components/DashboardMainHeader";
import UsersTabPanel from "./UsersTabPanel";
import PhysicalGoldTabPanel from "./PhysicalGoldTabPanel";
import FractionalGoldTabPanel from "./FractionalGoldTabPanel";
import GeneralStatisticsTabPanel from "./GeneralStatisticsTabPanel";
import OrdersTabPanel from "./OrdersTabPanel";
import PaymentTabPanel from "./PaymentTabPanel";
import NewTabs from "../../components/common/NewTabs";

//~
const Dashboard = () => {
  const tabs = [
    { label: "users", value: "1" },
    { label: "physical gold", value: "2" },
    { label: "fractional gold", value: "3" },
    { label: "general statistics", value: "4" },
    { label: "orders", value: "5" },
    { label: "payment", value: "6" },
  ];
  const [value, setValue] = useState("1");
  const today = new Date();
  const date14DaysAgo = new Date();
  date14DaysAgo.setDate(today.getDate() - 14);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight: "calc(100vh)",
      }}
    >
      <Container>
        {/* New dashboard  */}
        <DashboardMainHeader />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <NewTabs
            tabs={tabs}
            value={value}
            onChange={handleChange}
            className={"mb-2 bg-[#F5F5F5] rounded-lg"}
          />
        </Box>
        {/* Tab panels */}
        {value === "1" ? (
          <UsersTabPanel />
        ) : value === "2" ? (
          <PhysicalGoldTabPanel />
        ) : value === "3" ? (
          <FractionalGoldTabPanel />
        ) : value === "4" ? (
          <GeneralStatisticsTabPanel />
        ) : value === "5" ? (
          <OrdersTabPanel />
        ) : (
          <PaymentTabPanel />
        )}
      </Container>
    </div>
  );
};

export default Dashboard;
