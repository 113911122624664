import { changeStatusColor } from "../../services/utils/stylesModifiers";

const UserInfoField = ({ title, data, className, ...props }) => {
  const style = {
    fontFamily: "Lato",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 500,
    color: "#808080",
    alignSelf: "center",
  };
  const statusStyle = {
    background: "",
    font: "",
    circle: "",
  };

  changeStatusColor(statusStyle, data);
  // const md = capitalizeFirstLetter(data)
  return (
    <div
      className={`${className}`}
      style={{
        display: "flex",
        gap: 1,
        width: title === "KYC Status" ? "100%" : "85%",
      }}
    >
      {/* 595959 */}
      <div style={style}>{title} :</div>
      {title !== "KYC Status" && (
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            color:
              data === "Blocked"
                ? "#CF2424"
                : data === "Active"
                ? "#2492CF"
                : "#808080",
            alignSelf: "center",
            ...props,
          }}
        >
          {data}
        </div>
      )}

      {title === "KYC Status" && (
        <div
          className={`flex items-center gap-1 h-[37px] p-4 px-2 justify-center rounded-full`}
          style={{
            background: statusStyle.background,
            ...props,
          }}
        >
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
            >
              <circle cx="4.22852" cy="4" r="4" fill={statusStyle.circle} />
            </svg>
          </div>

          <p
            className="self-center text-[12px] font-normal"
            style={{
              color: statusStyle.font,
              fontFamily: "Lato, sans-serif",
            }}
          >
            {data}
          </p>
        </div>
      )}
    </div>
  );
};

export default UserInfoField;
