import { createSlice } from "@reduxjs/toolkit";

export const globalSlice = createSlice({
  name: "global",
  initialState: {
    disableWhileDoingAction: false,
  },
  reducers: {
    changeDisableWhileDoingAction: (state) => {
      state.disableWhileDoingAction = !state.disableWhileDoingAction;
    },
  },
});

export const { changeDisableWhileDoingAction } = globalSlice.actions;
export default globalSlice.reducer;
