import { useQuery } from "react-query";
import { axiosInstance as axios } from "../config";

export const fetchPermissions = async (p) => {
  try {
    const response = await axios.get(`/api/permissions/index`, {
      params: {
        type: p.type,
        page: p.page,
        orderBy: p.orderBy,
        sort: p.sort,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

// Define the async function as you already have
export const fetchRoles = async () => {
  try {
    const response = await axios.get(`/api/userGroups/index`);
    return response.data?.data; // Return the data part of the response
  } catch (error) {
    throw error;
  }
};

// Create the custom hook using React Query
export const useFetchRoles = () => {
  // Use useQuery to call fetchRoles with a unique query key
  return useQuery("roles", fetchRoles, {
    // Optional configurations
    onError: (error) => {
      console.error("Error fetching roles:", error);
    },
  });
};
const fetchForAddRole = async () => {
  const response = await axios.get(`/api/sidebar/getForAddRole`);
  return response.data;
};
export const useFetchForAddRole = () => {
  return useQuery("forAddRole", fetchForAddRole);
};

export const fetchCategory = async () => {
  try {
    const response = await axios.get(`/api/userGroupTypes/index`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchResourceGroupSections = async () => {
  try {
    const response = await axios.get(`/api/sidebar/sections`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchRoleDropdown = async () => {
  try {
    const response = await axios.get(`/api/userGroups/dropdown`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchUserRoleDropdown = async () => {
  try {
    const response = await axios.get(`/api/userGroups/dropdown?getUserRoles=1`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchAdminsFirstTab = async (p) => {
  try {
    const response = await axios.get(`/api/admin/index`, {
      params: {
        page: p.page,
        email: p.email,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchRequestsSecondTab = async (p) => {
  try {
    const response = await axios.get(`/api/adminrequests/index`, {
      params: {
        page: p.page,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
const fetchLogsAdminProfile = async ({ queryKey }) => {
  const [, { page, id }] = queryKey; // Destructure the queryKey to get parameters
  try {
    const response = await axios.get(`/api/admin/view/${id}`, {
      params: {
        page: page,
      },
    });
    return response.data?.data; // Return the data directly
  } catch (error) {
    throw error;
  }
};

export const useAdminProfileLogs = (page, id) => {
  return useQuery(["adminProfileLogs", { page, id }], fetchLogsAdminProfile, {
    keepPreviousData: true, // Keeps previous data while new data is loading
    staleTime: 5000, // Prevents refetching if data is fresh within 5 seconds
  });
};
export const fetchLogsThirdTab = async (p) => {
  try {
    const response = await axios.get(`/api/admin/logs`, {
      params: {
        page: p.page,
        email: p.email,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const resendRequestsSecondTab = async (id) => {
  try {
    const response = await axios.get(`/api/adminrequests/reSend-email/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const deleteRequestsSecondTab = async (id) => {
  try {
    const response = await axios.get(`/api/adminrequests/delete/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchRolesDetails = async (id) => {
  try {
    const response = await axios.get(`/api/userGroups/view/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addRemoveRoll = async (pid, rid) => {
  const body = {
    id: pid,
    role: rid,
  };
  try {
    const response = await axios.post(`/api/permissions/changeRole`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addNewRole = async (body) => {
  try {
    const response = await axios.post("/api/userGroups/add", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getforResource = async (body) => {
  try {
    const response = await axios.post("/api/permissions/getForResource", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getforEdit = async (body) => {
  try {
    const response = await axios.post("/api/sidebar/getForEdit", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const addRoleToPermission = async (body) => {
  try {
    const response = await axios.post("/api/permissions/changeRole", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const changeResourcePermissions = async (body) => {
  try {
    const response = await axios.post("/api/sidebar/changeRole", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const addNewAdminRole = async (id, body) => {
  try {
    const response = await axios.post(`/api/admin/changeRole/${id}`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addNewAdmin = async (body) => {
  try {
    const response = await axios.post("/api/adminrequests/add", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const addCategory = async (body) => {
  try {
    const response = await axios.post("/api/userGroupTypes/add", body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editRole = async (id, body) => {
  try {
    const response = await axios.post(`/api/userGroups/edit/${id}`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const changeKyc = async (id) => {
  try {
    const response = await axios.get(`/api/permissions/changeKyc/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteRole = async (id) => {
  try {
    const response = await axios.get(`/api/userGroups/delete/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const deleteAdmin = async (id) => {
  try {
    const response = await axios.get(`/api/admin/delete/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const deactivateAdmin = async (id) => {
  try {
    const response = await axios.get(`/api/admin/deactivate/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
