// components/ImageUploader.js
import { Avatar, Button } from "@mui/material";
import React, { useRef } from "react";

const ImageUploader = ({
  image,
  setImage,
  setFile,
  setPayload,
  file,
  title,
}) => {
  const fileInputRef = useRef();

  const handleFileInputChange = (event) => {
    if (event?.target?.files?.[0] instanceof File) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = (e) => setImage(e.target.result);
      reader.readAsDataURL(selectedFile);

      setPayload((prev) => ({
        ...prev,
        image: selectedFile,
      }));
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile?.type.startsWith("image/")) {
      setFile(droppedFile);
      const reader = new FileReader();
      reader.onload = (e) => setImage(e.target.result);
      reader.readAsDataURL(droppedFile);
    }
  };

  const handleDragOver = (event) => event.preventDefault();

  const handleUploadButtonClick = () => fileInputRef.current.click();

  const handleDeleteImage = () => {
    setImage(null);
    setFile(null);
  };

  return (
    <div className="mt-4 flex flex-col w-full">
      <p className="text-[#666666] mb-2 ">{title}</p>
      <div
        className="  bg-[#f5f5f5] items-center justify-center  p-1 rounded-xl border"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={{
          display: !image ? "flex" : "none",
        }}
      >
        <img src={`../../assets/export.png`} alt="upload" />
        <div className="flex">
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileInputChange}
          />
          {!image && (
            <Button
              sx={{
                color: "#E9C237",
                textDecoration: "underLine",
                fontSize: "16px",
                fontWeight: 400,
                textTransform: "none",
              }}
              onClick={handleUploadButtonClick}
            >
              Click to upload
            </Button>
          )}
          {image ? (
            <img src={image} alt="Dropped" height="152px" width="100%" />
          ) : (
            <p
              style={{
                color: "#333333",
                fontSize: "16px",
                marginTop: "8px",
              }}
            >
              or drag and drop{"  "}
            </p>
          )}
        </div>
        <p className="text-[#cccccc] text-xs ml-1">
          SVG, PNG, JPG or GIF (max. 3MB)
        </p>
      </div>
      <div
        className="bg-[#f5f5f5] items-center justify-between  p-3 rounded-xl border"
        style={{
          display: file ? "flex" : "none",
        }}
      >
        <div className="flex items-center  gap-2">
          <img src={`../../assets/document-upload.png`} alt="upload" />
          <div>
            <p className="">{file?.name}</p>
            <p className="text-[#666666] text-xs mr-1">
              {Math.ceil(file?.size / 1024)}kb • Complete
            </p>
          </div>
        </div>

        <div className="flex">
          <Avatar
            sx={{
              bgcolor: "rgba(0, 51, 102, 0.1)",
              height: "24px",
              width: "24px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/checkcircle.png`} alt="circle" />
          </Avatar>

          <Avatar
            onClick={handleDeleteImage}
            sx={{
              cursor: "pointer",
              height: "24px",
              width: "24px",
              alignSelf: "center",
              backgroundColor: "#ffffff",
            }}
          >
            <img src={`../../assets/delete.png`} alt="delete" />
          </Avatar>
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
