const ImageDialog = ({ imageUrl, isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white p-4 rounded shadow-lg max-w-lg w-fit relative"
        onClick={(e) => e.stopPropagation()} // Prevents dialog from closing when clicking inside the image container
      >
        <img
          loading="lazy"
          width={300}
          src={imageUrl}
          alt="Dialog Content"
          className="rounded"
        />
      </div>
    </div>
  );
};

export default ImageDialog;
