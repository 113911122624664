import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import editSVG from "../../svg/edit.svg";
import CustomDialog from "../../components/CustomDialog";
import { MyEditor } from "../../components/MyEditor";
import Markdown from "markdown-to-jsx";
import { useAboutUs, useEditAboutUs } from "../../services/apis/about";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import AddButton from "../../components/common/AddButton";
import { FirstBox, SecondBox } from "../../components/common/CustomBox";
import { FirstTypography } from "../../components/common/CustomTypogrphy";
const About = () => {
  const [isEdit, setIsEdit] = useState(false);
  const lang = [
    { id: 1, local: "en", name: "English" },
    { id: 2, local: "ar", name: "Arabic" },
    { id: 3, local: "eg", name: "Egyptian Arabic" },
  ];
  const { data, isLoading } = useAboutUs();
  const { mutate } = useEditAboutUs();

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState("en");
  const [translatedText, setTranslatedText] = useState({
    en: "",
    ar: "",
    eg: "",
  });
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };
  const dispatch = useDispatch();

  useEffect(() => {
    setTranslatedText({
      en: data ? data[0]?.value : "",
      ar: data ? data[1]?.value : "",
      eg: data ? data[2]?.value : "",
    });
  }, [data]);
  const containsLetter = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const textContent = tempDiv.textContent || tempDiv.innerText || "";
    return /\S/.test(textContent);
  };
  const onSubmit = async () => {
    try {
      const id = currentLang === "en" ? 8 : currentLang === "ar" ? 9 : 10;
      const currentValue =
        currentLang === "en"
          ? translatedText.en
          : currentLang === "ar"
          ? translatedText.ar
          : translatedText.eg;
      mutate({ id, body: { value: currentValue } });
    } catch (error) {}
  };
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <DashboardMainHeader />
      <Header title="About us" marginL={"20px"} />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 2,
          p: 2,
          justifyContent: "end",
        }}
      >
        <AddButton
          onClick={() => setIsEdit(true)}
          title={
            <>
              <img src={editSVG} alt="" style={{ marginRight: "10px" }} /> Edit
            </>
          }
        />
      </Box>
      <SecondBox>
        <FirstBox m="40px 0 0 0" height="100%" padding={"40px"}>
          <Typography
            fontSize={"32px"}
            color={"#666666"}
            fontWeight={"500"}
            sx={{ fontFamily: "Roboto" }}
          >
            Welcome To <span style={{ color: "#E9C237" }}>Sabika</span>
          </Typography>
          <Box sx={{ width: "100%", display: isEdit ? "" : "none" }}>
            <FirstTypography title={"Select Language"} />

            <FormControl sx={{ width: "100%" }}>
              <Select
                displayEmpty
                onChange={(e) => {
                  setCurrentLang(e.target.value);
                }}
                value={currentLang}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>{""}</em>;
                  }
                  return (
                    lang.find((item) => item.local === selected)?.name || ""
                  );
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                {lang.map((item) => (
                  <MenuItem key={item.local} value={item.local}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: "100%" }}>
            <FirstTypography
              title={"About us"}
              extraSx={{ display: isEdit ? "" : "none" }}
            />

            {isEdit ? (
              <MyEditor
                text={translatedText[currentLang]}
                setText={(newContent) => {
                  setTranslatedText((prevState) => ({
                    ...prevState,
                    [currentLang]: newContent,
                  }));
                }}
              />
            ) : (
              <>
                {isLoading ? (
                  <div className="grid gap-4 mt-5">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </div>
                ) : (
                  <Markdown>{translatedText["en"]}</Markdown>
                )}
              </>
            )}
          </Box>
        </FirstBox>
        <Box
          sx={{
            display: isEdit ? "flex" : "none",
            width: "100%",
            gap: 2,
            p: 2,
            justifyContent: "end",
          }}
        >
          <AddButton
            width="180px"
            title={"Save"}
            onClick={() => {
              if (!containsLetter(translatedText[currentLang])) {
                dispatch(
                  setData({
                    openSnack: true,
                    message: "Please Fill The Textarea",
                  })
                );
              } else {
                setIsEdit((prevState) => !prevState);
                setSuccessDialogOpen(true);
                onSubmit();
              }
            }}
          />
        </Box>
      </SecondBox>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="About Us has been Edited Successfully."
        message=""
        buttonText="Go to About Us"
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default About;
