import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { fetchAllSplashes } from "../../services/apis/splashscreen";
import Header from "../../components/Header";
import Modal from "@mui/material/Modal";
import KycModalBody from "../../components/KycModalBody";
import AddSplashModal from "./AddSplashModal";
import EditSplashModal from "./EditSplashModal";
import { ItemsColumns } from "../../components/common/Columns";
import NewTable from "../../components/common/NewTable";

const AppManagement = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openImage, setOpenImage] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [splashes, setSplashes] = useState([]);
  const [image] = useState(null);
  const [currentSplash, setCurrentSplash] = useState(null);
  const onBoardingScreenColumns = ItemsColumns([
    { field: "id", headerName: "Order" },
    {
      field: "title",
      headerName: "Title",
      specialRender: "customRender",
      flex: 2,
      renderFunction: (params) => params?.row?.trans?.en?.title,
    },
    {
      field: "description",
      headerName: "Screen Text",
      specialRender: "customRender",
      flex: 2,
      renderFunction: (params) => {
        return (
          <p>
            {params?.row?.trans?.en?.text?.length > 100
              ? `${params?.row?.trans?.en?.text.substring(0, 100)}...`
              : params?.row?.trans?.en?.text}
          </p>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",

      actions: {
        edit: (params) => handleOpenEditModal(params.row),
      },
    },
  ]);
  const getSplashes = async () => {
    setLoading(true);
    try {
      const response = await fetchAllSplashes();
      const splashes = response.data.data;
      setSplashes(splashes);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseImage = async () => {
    setOpenImage(false);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    getSplashes();
  };

  const handleOpenEditModal = (splash) => {
    setCurrentSplash(splash);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    getSplashes();
  };

  useEffect(() => {
    getSplashes();
  }, []);

  return (
    <Box
      sx={{
        height: "auto",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: "column",
        background: colors.primary,
        m: "20px",
      }}
    >
      <Box gridColumn="span 1" sx={{ width: "100%" }}>
        <Header title="Onboarding Screen" subtitle="" marginB={10} />
        <NewTable
          columns={onBoardingScreenColumns}
          isLoading={loading}
          data={splashes}
          noPagination
          rows={3}
        />

        <Box display="flex" justifyContent="end" mt="20px">
          <Modal
            open={openImage}
            onClose={handleCloseImage}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <KycModalBody src={image} />
          </Modal>

          <Modal
            open={openAddModal}
            onClose={handleCloseAddModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <AddSplashModal handleClose={handleCloseAddModal} />
          </Modal>

          <Modal
            open={openEditModal}
            onClose={handleCloseEditModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <EditSplashModal
              handleClose={handleCloseEditModal}
              splash={currentSplash}
            />
          </Modal>
        </Box>
      </Box>
    </Box>
  );
};

export default AppManagement;
