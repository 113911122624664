import React, { useEffect, useState } from "react";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import Header from "../../../components/Header";
import { Step, StepLabel, Stepper } from "@mui/material";
import { useStylesHook } from "../../../components/common/useStyles";
import { useNavigate, useParams } from "react-router-dom";
import ImageUploader from "../../../components/ImageUploader";
import { useAddQuestionLicense } from "../../../services/apis/license";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { setData } from "../../../store/slices/errorMessageSlice";
import { useDispatch } from "react-redux";
import CustomDialog from "../../../components/CustomDialog";
// Arabic letters and spaces regex validation
const isArabic = (text) =>
  /^[\u0600-\u06FF\s\d.,!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(text);
const isEnglish = (text) =>
  /^[A-Za-z\s\d.,!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(text);

const AddLicenseQuestion = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const steps = ["English", "Arabic", "Review"];
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const { mutateAsync, isLoading } = useAddQuestionLicense();
  const c = useStylesHook();
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const currentLang = activeStep === 0 ? "en" : "ar";

  const [formData, setFormData] = useState({
    en: {
      question: "",
      answer: "",
    },
    ar: {
      question: "",
      answer: "",
    },
  });
  const handleFieldChange = (lang, field, value) => {
    if (lang === "ar" && !isArabic(value)) return; // Restrict to Arabic only for Arabic section
    if (lang === "en" && !isEnglish(value)) return; // Restrict to Arabic only for Arabic section
    setFormData((prev) => ({
      ...prev,
      [lang]: {
        ...prev[lang],
        [field]: value,
      },
    }));
  };
  const validateForm = () => {
    const { question, answer } = formData[currentLang];
    const allFieldsFilled =
      //   file?.name !== null &&
      //   image !== null &&
      question.trim() !== "" && answer.trim() !== "";
    setIsNextEnabled(allFieldsFilled);
  };

  useEffect(() => {
    validateForm();
  }, [formData, activeStep]);
  return (
    <div>
      <DashboardMainHeader />
      <Header title={"Add Question"} marginL={"24px"} />
      <div
        className="mt-8 py-16 px-6 mx-6 rounded-xl bg-white"
        style={{
          boxShadow: `
         0px 1px 3px 0px rgba(0, 0, 0, 0.02),
         0px 1px 6px 0px rgba(0, 0, 0, 0.07)
       `,
        }}
      >
        <Stepper className={`${c.root}`} activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="mt-12">
          {activeStep === 2 ? (
            <div className="flex mt-16">
              <div className=" flex-1">
                <div className="flex items-center ">
                  <h2 className="w-1/4">Question:</h2>
                  <h2 className="">{formData?.en?.question}</h2>
                </div>
                <hr className={`text-[#F5F5F5] border-t-[1px] my-3`} />
                <div className="flex items-center ">
                  <h2 className="w-1/4">Answer:</h2>
                  <h2 className="">{formData?.en?.answer}</h2>
                </div>
                <hr className={`text-[#F5F5F5] border-t-[1px] my-3`} />
                <div className="flex items-center ">
                  <h2 className="w-1/4">Question (Arabic):</h2>
                  <h2 className="">{formData?.ar?.question}</h2>
                </div>
                <hr className={`text-[#F5F5F5] border-t-[1px] my-3`} />
                <div className="flex items-center ">
                  <h2 className="w-1/4">Answer (Arabic):</h2>
                  <h2 className="">{formData?.ar?.answer}</h2>
                </div>
                <hr className={`text-[#F5F5F5] border-t-[1px] my-3`} />
              </div>
            </div>
          ) : (
            <div className="mt-16 mb-12 flex flex-col gap-6">
              {/* License Name Input */}
              <div>
                <p className="text-[#595959] text-sm mb-2">
                  Question ({currentLang === "en" ? "English" : "Arabic"})
                </p>
                <input
                  onChange={(e) =>
                    handleFieldChange(currentLang, "question", e.target.value)
                  }
                  value={formData[currentLang].question}
                  placeholder={`Enter  Question (${
                    currentLang === "en" ? "English" : "Arabic"
                  })`}
                  className="border w-full bg-[#f5f5f5] rounded-xl p-4 outline-none"
                />
              </div>

              {/* License Headline Input */}
              <div>
                <p className="text-[#595959] text-sm mb-2">
                  Answer ({currentLang === "en" ? "English" : "Arabic"})
                </p>
                <input
                  onChange={(e) =>
                    handleFieldChange(currentLang, "answer", e.target.value)
                  }
                  value={formData[currentLang].answer}
                  placeholder={`Enter   Answer (${
                    currentLang === "en" ? "English" : "Arabic"
                  })`}
                  className="border w-full bg-[#f5f5f5] rounded-xl p-4 outline-none"
                />
              </div>
            </div>
          )}
          {/* Navigation Buttons */}
          <div className="flex items-center justify-end gap-4">
            {/* Back Button */}
            <button
              onClick={() => setActiveStep((prev) => prev - 1)}
              disabled={activeStep === 0 || isLoading}
              className={`${
                activeStep === 0 ? "hidden" : "block"
              } px-20 py-2 border border-[#e9c237] text-[#e9c237] rounded-lg disabled:opacity-50`}
            >
              Back
            </button>

            {/* Next or Save Button */}
            <button
              onClick={async () => {
                if (activeStep === steps.length - 1) {
                  // Save API Call
                  await mutateAsync({
                    id: id,
                    body: {
                      question_en: formData.en.question,
                      answer_en: formData.en.answer,
                      question_ar: formData.ar.question,
                      answer_ar: formData.ar.answer,
                    },
                  })
                    .then(() => {
                      setSuccessDialogOpen(true);
                    })
                    .catch((error) => {
                      setData({
                        openSnack: true,
                        severity: "error",
                        message: error.response.data.message,
                      });
                    });
                }
                if (activeStep !== steps.length - 1) {
                  setActiveStep((prev) => prev + 1);
                }
              }}
              disabled={!isNextEnabled || isLoading}
              className={`px-20 py-2 bg-[#e9c237] text-white rounded-lg disabled:opacity-50`}
            >
              {activeStep === steps.length - 1 ? "Save" : "Next"}
            </button>
          </div>
        </div>
      </div>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Question has been Added Successfully."
        message=""
        buttonText="Go to License"
        buttonLink={-1}
      />
    </div>
  );
};

export default AddLicenseQuestion;
