// PageTimeTracker.jsx
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
// import { fetchTerms } from "../../services/apis/terms";

export const PageTimeTracker = () => {
  const location = useLocation();
  const startTimeRef = useRef(null);

  useEffect(() => {
    startTimeRef.current = Date.now();

    const handleBeforeUnload = async () => {
      if (startTimeRef.current) {
        const endTime = Date.now();
        const durationInSeconds = Math.floor(
          (endTime - startTimeRef.current) / 1000
        );
        console.log(
          `User stayed on ${location.pathname} for ${durationInSeconds} seconds`
        );
        // await fetchTerms();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      handleBeforeUnload();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location]);

  return null;
};
