import { Box, Typography } from "@mui/material";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import { DataGridStyle } from "../../styles";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import ActionAlert from "../user-profile/ActionAlert";
import CustomDialog from "../../components/CustomDialog";
import {
  useFetchArticles,
  useDeleteArticle,
} from "../../services/apis/Articles";
import { useNavigate } from "react-router";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import AddButton from "../../components/common/AddButton";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import { CustomLoadingOverlay } from "../../components/common/CustomLoadingOverlay";
import NewTable from "../../components/common/NewTable";
const Articles = () => {
  const [disableWhileDelete, setDisableWhileDelete] = useState(false);
  const [articleId, setArticleId] = useState(null);
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const navigate = useNavigate();

  const { data: newArticlesData, isLoading } = useFetchArticles();
  const deleteArticleMutation = useDeleteArticle();

  const handleDeleteArticle = async () => {
    setDisableWhileDelete(true);
    if (articleId) {
      const response = await deleteArticleMutation.mutateAsync(articleId);
      if (response?.status === 200) {
        setDisableWhileDelete(false);
        setSuccessDialogOpen(true);
        handleCloseDeleteAlert();
      }
    }
  };
  const handleOpenDeleteAlert = (id) => {
    setArticleId(id);
    setOpenDeletedAlert(true);
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const handleEditArticle = (params) => {
    const id = params.row.id;
    const Article = params.row;
    navigate(`/EditArticle/${id}`, {
      state: { selectedArticle: Article },
    });
  };
  const articlesColumns = ItemsColumns([
    {
      field: "sort",
      headerName: "Article Order",
      specialRender: "customRender",
      renderFunction: (params) => {
        return (
          <Typography align="center">
            {params.row.sort === null ? "-" : params.row.sort}
          </Typography>
        );
      },
    },
    {
      field: "articleheader",
      headerName: "Article Header",
      specialRender: "customRender",

      renderFunction: (params) => params?.row?.trans?.en?.title,
    },
    {
      field: "is_hidden",
      headerName: "Article Status",
      specialRender: "customRender",
      renderFunction: (params) =>
        params?.row?.sort === null ? (
          <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
            Hide
          </Typography>
        ) : (
          <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
            Show
          </Typography>
        ),
    },
    {
      field: "action",
      headerName: "Actions",
      actions: {
        edit: (params) => handleEditArticle(params),
        delete: (params) => handleOpenDeleteAlert(params.row.id),
      },
    },
  ]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Articles" marginL={"25px"} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: 2,
          pr: 2,
        }}
      >
        <AddButton
          title={"+ Add Article"}
          onClick={() => {
            navigate("/AddArticle");
          }}
        />
      </Box>

      <SecondBox>
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <NewTable
            columns={articlesColumns}
            isLoading={isLoading}
            data={newArticlesData?.result}
            noPagination
          />
          {/* <DataGrid
            components={{
              LoadingOverlay: () => CustomLoadingOverlay({ columns: 4 }),
            }}
            rows={isLoading ? [] : newArticlesData?.result}
            columns={articlesColumns}
            loading={isLoading}
            rowsPerPageOptions={[10]}
            pageSize={10}
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
          /> */}
        </Box>
      </SecondBox>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Article has been deleted Successfully."
        message=""
        buttonText="Go to Articles"
      />
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteArticle}
        message={`Are you sure you want to delete this Article ?`}
        label={"Delete"}
        disabled={disableWhileDelete}
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default Articles;
