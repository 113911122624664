import { Box, Button, IconButton, InputBase } from "@mui/material";
import React from "react";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { ItemsColumns } from "../../components/common/Columns";
const ProductSerial = () => {
  const requestsColumns = ItemsColumns([
    { field: "serial", headerName: "Serial No." },
    {
      field: "availability",
      headerName: "Item Stock Status",
      specialRender: "availability",
    },
    { field: "itemSellStatus", headerName: "Item Sell Status" },
    {
      field: "date",
      date: true,
    },
    {
      field: "action",
      headerName: "Change Item Stock Status",
      //specialRender: "customRender",
      //   renderFunction: (params) => {
      //     const isChecked = params.row.availability === 1;
      //     const handleSwitchToggle = async (event) => {
      //       const newAvailability = event.target.checked ? 1 : 0;
      //       const id = params.row.id;

      //       try {
      //         const requestData = {
      //           ids: [id],
      //           availability: newAvailability,
      //         };
      //         const response = await changeInventoryRequestStatus(requestData);
      //         setOpen(true);
      //         setRequestSerials((prevSerials) =>
      //           prevSerials.map((serial) =>
      //             serial.id === id
      //               ? { ...serial, availability: newAvailability }
      //               : serial
      //           )
      //         );
      //       } catch (error) {
      //         console.error("Error updating availability:", error);
      //       }
      //     };
      //     return (
      //       <Switch
      //         checked={isChecked}
      //         onChange={handleSwitchToggle}
      //         {...label}
      //         sx={{
      //           "& .MuiSwitch-thumb": {
      //             color: isChecked ? "#E9C237" : "#E9C237",
      //           },
      //           "& .MuiSwitch-track": {
      //             backgroundColor: isChecked ? "#0000001F" : "#0000001F",
      //           },
      //         }}
      //       />
      //     );
      //   },
    },
  ]);

  return (
    <Box sx={{ width: "90%", margin: "auto", marginTop: "3%" }}>
      <Header title={"Serials"} />
      <Box
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08);",
          padding: "10px",
        }}
      >
        <div className="flex items-center rounded-xl bg-[#f5f5f5] mb-4 p-2 w-[95%] md:w-3/5">
          <IconButton type="button" aria-label="search">
            <img alt="search-normal" src={`../../assets/search-normal.png`} />
          </IconButton>
          <InputBase
            // onChange={handleSearchInput}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Serial Number ......"
          />
        </div>
        <div className="flex items-center justify-end m-[2%]">
          <Button
            // onClick={handleInStockButtonClick}
            sx={{
              width: "142px",
              "&:hover": {
                backgroundColor: "#E9C237",
              },
              color: "#ffffff",
              height: "36px",
              backgroundColor: "#E9C237",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginRight: "10px",
              cursor: "pointer",
            }}
          >
            In Stock
          </Button>
          <Button
            // onClick={handleOutOfStockButtonClick}
            variant="outlined"
            sx={{
              "&:hover": {
                backgroundColor: "#ffffff",
                borderColor: "#E9C237",
              },
              width: "142px",
              height: "36px",
              backgroundColor: "#ffffff",
              color: "#E9C237",
              borderColor: "#E9C237",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              cursor: "pointer",
            }}
          >
            Out Of Stock
          </Button>
        </div>

        <Box
          height="auto"
          sx={[
            {
              "& .MuiDataGrid-columnHeader:first-of-type": {
                backgroundColor: "#ffffff",
                borderBottom: "none",
                pointerEvents: "auto",
              },
            },
          ]}
        >
          <DataGrid
            components={{
              Footer: () => null,
            }}
            disableColumnMenu={true}
            checkboxSelection
            // rows={requestSerials ? requestSerials : []}
            rows={[]}
            // loading={loading}
            columns={requestsColumns}
            getRowId={(row) => row.id}
            autoHeight={true}
            // onSelectionModelChange={handleCheckboxChange}
          />
        </Box>
        {/* <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Status Changed Successfully
          </Alert>
        </Snackbar> */}
      </Box>
    </Box>
  );
};

export default ProductSerial;
