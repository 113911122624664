import { Box, Button, Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import { useState } from "react";
import CustomDialog from "../../components/CustomDialog";
import StepOneTermsComponent from "./StepOneTermsComponent";
import StepTwoTermsComponent from "./StepTwoTermsComponent";
import { addTerm } from "../../services/apis/terms";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { useStylesHook } from "../../components/common/useStyles";
import { stepsEA } from "../../data/steps";

const AddNewTerms = () => {
  const c = useStylesHook();
  const [activeStep, setActiveStep] = useState(0);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termsData, setTermsData] = useState({
    title_ar: "",
    title_en: "",
    description_ar: "",
    description_en: "",
  });
  const dispatch = useDispatch();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await addTerm(termsData);
      setSuccessDialogOpen(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StepOneTermsComponent
            termsData={termsData}
            setTermsData={setTermsData}
          />
        );
      case 1:
        return (
          <StepTwoTermsComponent
            termsData={termsData}
            setTermsData={setTermsData}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Box>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <Box sx={{ mb: "3%" }}>
          <DashboardMainHeader />
        </Box>

        <Header title="Add Terms and Conditions" />
      </Box>
      <Box
        m="20px"
        sx={{
          height: "70vh",
          background: "#FFFFFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08)",
          overflow: "auto",
          padding: "4%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Stepper className={c.root} activeStep={activeStep}>
            {stepsEA.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <>
            {renderStepContent(activeStep)}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              {activeStep !== 0 && (
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#ffffff",
                    },
                    backgroundColor: "#ffffff",
                    width: "200px",
                    height: "46px",
                    borderRadius: "12px",
                    color: "#E9C237",
                    border: "3px solid #E9C237",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  Back
                </Button>
              )}
              <Button
                disabled={
                  activeStep === 0
                    ? !termsData.title_en || !termsData.description_en
                    : activeStep === 1
                    ? !termsData.title_ar ||
                      !termsData.description_ar ||
                      loading
                    : false
                }
                sx={{
                  "&:hover": { backgroundColor: "#E9C237" },
                  backgroundColor: "#E9C237",
                  width: "200px",
                  height: "46px",
                  borderRadius: "12px",
                  color: "#ffffff",

                  fontSize: "14px",
                }}
                onClick={
                  activeStep === stepsEA.length - 1 ? handleSave : handleNext
                }
              >
                {activeStep === stepsEA.length - 1 ? "Save" : "Next"}
              </Button>
            </Box>
          </>
        </Box>
      </Box>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Term and Condition has been added Successfully."
        message=""
        buttonText="Go to Terms and Conditions"
        buttonLink="/terms"
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default AddNewTerms;
