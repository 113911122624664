import { useMutation, useQuery } from "react-query";
import { axiosInstance as axios } from "../config";

// const axios = axiosInstance;
export const fetchUsers = async (p) => {
  if (p.kyc_status) {
    p.kyc_status = p.kyc_status.length === 0 ? null : p.kyc_status;
  }
  try {
    const response = await axios.get(`/api/users/index`, {
      params: {
        page: p.page,
        orderBy: p.orderBy,
        sort: p.sort,
        kyc_status: JSON.stringify(p.kyc_status),
        date_from: p.date_from,
        date_to: p.date_to,
        is_online: p.is_online,
        nationalId: p.nationalId,
        // full_name:"omar"
        phone: p.phone,
        email: p.email,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const useFetchUsers = (params) => {
  return useQuery(["users", params], () => fetchUsers(params), {
    keepPreviousData: true, // optional: useful for pagination
    staleTime: 5000, // optional: adjust based on your needs
  });
};
export const fetchUser = async (id) => {
  try {
    const response = await axios.get(`/api/users/index`, {
      params: {
        id,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};
const fetchUserDetails = async (id) => {
  try {
    const response = await axios.get(`/api/users/view/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const useUserDetails = (id) => {
  return useQuery(["userDetails", id], () => fetchUserDetails(id));
};
export const blockUser = async (id) => {
  try {
    const response = await axios.get(`/api/users/block/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const newBlock = async ({ id, block_reason_id, unBlock_at }) => {
  try {
    const response = await axios.post(`/api/users/block`, {
      id,
      block_reason_id,
      unBlock_at,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const useNewBlock = () => {
  return useMutation(newBlock);
};
export const unBlockUser = async (id) => {
  try {
    const response = await axios.get(`/api/users/unblock/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const approveUser = async (status, userId, formData) => {
  const { national_name, nationalId, kyc_rejection_reason_id } = formData;
  let body = {};
  if (status === "approved")
    body = { status, userId, national_name, nationalId };
  else body = { status, userId, kyc_rejection_reason_id };
  try {
    const response = await axios.post(`/api/users/approveKyc`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addNewUser = async (body) => {
  try {
    const response = await axios.post(`/api/adminrequests/add`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchAdmins = async (p) => {
  try {
    const response = await axios.get(`/api/admin/index`, {
      params: {
        page: p.page,
        orderBy: p.orderBy,
        sort: p.sort,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchAvailabeUsers = async () => {
  try {
    const response = await axios.get(`/api/reports/online-count`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchAdminProfile = async () => {
  try {
    const response = await axios.get(`/api/admin/me`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchDeletedUsers = async (queryParameters) => {
  const response = await axios.get(`/api/users/deletedUsers`, {
    params: queryParameters,
  });
  return response.data;
};

export const useDeletedUsers = (queryParameters) => {
  return useQuery(
    ["deletedUsers", queryParameters],
    () => fetchDeletedUsers(queryParameters),
    {
      refetchOnWindowFocus: false,
    }
  );
};
