import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import arrowLeft from "../../../svg/arrow-circle-left.svg";
import { EditSvgWithFunc } from "../../../components/common/SvgWithFunc";
import Header from "../../../components/Header";
import NewTable from "../../../components/common/NewTable";
import { ItemsColumns } from "../../../components/common/Columns";
import ActionAlert from "../../user-profile/ActionAlert";
import { setData } from "../../../store/slices/errorMessageSlice";
import { useDispatch } from "react-redux";
import {
  useDeleteQuestionLicense,
  useGetAllLicenses,
} from "../../../services/apis/license";

import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { Skeleton } from "@mui/material";
import { imageBaseUrl } from "../../../services/config";
const LicenseDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const { data, isLoading } = useGetAllLicenses({ id: params?.id });
  const { mutateAsync } = useDeleteQuestionLicense();
  const [id, setId] = useState("");
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
    dispatch(
      setData({
        openSnack: true,
        severity: "success",
        message: "Question Deleted Successfully",
      })
    );
  };
  const handleDeleteLicenseQuestion = async () => {
    try {
      await mutateAsync(id);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
    handleCloseDeleteAlert();
  };

  const LicensesColumns = ItemsColumns([
    {
      field: "question_en",
      headerName: "Question",
    },
    {
      field: "answer_en",
      headerName: "Answer",
    },

    {
      field: "action",
      headerName: "Actions",
      actions: {
        view: (params) => {
          console.log("params", params);
          navigate(`/license-question/${params.row.id}`, {
            state: {
              question: params.row,
            },
          });
        },
        edit: (params) => {
          navigate(`/edit-license-question/${params.row.id}`, {
            state: {
              question: params.row,
            },
          });
        },
        delete: (params) => {
          setId(params?.row?.id);
          setOpenDeletedAlert(true);
        },
      },
    },
  ]);

  return (
    <div>
      <DashboardMainHeader />
      <div className="m-6">
        <div className="flex items-center gap-[0.625rem]">
          <img
            className="cursor-pointer"
            src={arrowLeft}
            alt=""
            onClick={() => {
              navigate("/app-license");
            }}
          />
        </div>
        <div
          className="bg-white border border-[#e6e6e6] rounded-xl mt-8 mb-16 relative"
          style={{
            boxShadow: `
            0px 1px 3px 0px rgba(0, 0, 0, 0.02),
            0px 1px 6px 0px rgba(0, 0, 0, 0.07)
          `,
          }}
        >
          <EditSvgWithFunc
            className={"absolute right-12 top-10"}
            onClick={() => {
              navigate("/edit-license/2", {
                state: {
                  license: data?.result[0],
                },
              });
            }}
          />
          <div className="flex items-center  px-12 pt-16 pb-10">
            <img
              src={`${imageBaseUrl}/${data?.result[0]?.image}`}
              className="w-80  h-96 object-contain  mr-10"
              alt=""
            />
            <div className="grid w-full">
              <div className="grid grid-cols-[15%_85%] py-5">
                <h2 className="">License Name:</h2>
                <h2>
                  {isLoading ? (
                    <Skeleton width={200} />
                  ) : (
                    data?.result[0]?.name_en
                  )}
                </h2>
              </div>
              <hr className="text-[#F5F5F5] border-t-[1px]" />

              <div className="grid grid-cols-[15%_85%] py-5">
                <h2 className="">License Headline:</h2>
                <h2>
                  {isLoading ? (
                    <Skeleton width={200} />
                  ) : (
                    data?.result[0]?.headline_en
                  )}
                </h2>
              </div>
              {!isLoading && data?.result[0]?.description_en && (
                <>
                  <hr className="text-[#F5F5F5] border-t-[1px]" />

                  <div className="grid grid-cols-[15%_85%] py-5">
                    <h2 className="">License Description:</h2>
                    <h2>{data?.result[0]?.description_en}</h2>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {!isLoading && !data?.result[0]?.description_en && (
          <div className="mb-24">
            <Header title={"License Questions"} />
            <button
              className="px-5  mb-6 py-2 text-white bg-[#e9c237] rounded-xl flex ml-auto font-medium"
              onClick={() => {
                navigate(`/add-license-question/${data?.result[0].id}`, {
                  state: {
                    question: data?.result[0],
                  },
                });
              }}
            >
              <span className="mr-2">+</span> Add Question
            </button>
            <NewTable
              className={""}
              columns={LicensesColumns}
              isLoading={false}
              data={data?.result[0]?.questions}
              noPagination
              // totalPages={questionData?.totalPages}
              // totalItems={questionData?.totalItems}
              // handlePageChange={(newPage) =>
              //   handlePageChange(newPage, queryParameters, setQueryParameters)
              // }
              // currentPage={queryParameters}
              // onCellClick={(params) => {
              //   if (params.field !== "action") {
              //     navigate(`/product-details/${params?.row?.id}`, {
              //       state: {
              //         product: params?.row,
              //       },
              //     });
              //   }
              // }}
            />
          </div>
        )}
        <ActionAlert
          openAlert={openDeletedAlert}
          handleCloseAlert={handleCloseDeleteAlert}
          handleReject={handleDeleteLicenseQuestion}
          message={`Are you sure you want to Delete this question ?`}
          label={"Delete"}
        />
        <ReusableSnackbar />
      </div>
    </div>
  );
};

export default LicenseDetails;
