import React from "react";
import { Formik } from "formik";
import { Box, Button, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import Checkbox from "@mui/material/Checkbox";
import { modalStyle } from "../../styles";
import CustomTextArea from "../../components/CustomTextArea";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { setData } from "../../store/slices/errorMessageSlice";
import { useDispatch } from "react-redux";

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  description: yup.string().required("required"),
  type: yup.number().required("required"),
});

const AddRoleModal = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const handleFormSubmit = async (values) => {
    try {
      await props.addNewRole(values);
      props.handleClose();
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.validation.name,
        })
      );
    }
  };

  const initialValues = {
    name: "",
    description: "",
    type: 1,
  };

  return (
    <Box
      m="20px"
      display="grid"
      gridTemplateColumns="reapeat(1, 1fr)"
      alignContent="space-between"
      sx={modalStyle}
    >
      <Box gridColumn="span 1">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            setFieldValue,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" justifyContent="start" gap="30px">
                <TextField
                  variant="filled"
                  type="text"
                  label="Role Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ width: "50% " }}
                />

                <Box display="flex" alignItems="center">
                  <Checkbox
                    label="Is Admin"
                    value={values.type}
                    color="secondary"
                    onChange={(e) => {
                      setFieldValue("type", e.target.checked ? 2 : 1);
                    }}
                  />
                  <Typography>Dashboard Access</Typography>
                </Box>
              </Box>
              <CustomTextArea
                sx={{
                  marginTop: "10px",
                  alignSelf: "center",
                  display: "flex",
                  maxWidth: "320px",
                  minWidth: "320px",
                  minHeight: "50px",
                  maxHeight: "200px",
                }}
                name="description"
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                placeholder="Description"
              />
              <Box display="flex" justifyContent="start" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  Submit
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
      <ReusableSnackbar />
    </Box>
  );
});

export default AddRoleModal;
