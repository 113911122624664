//make me a slice that will hold the state of the sidebar
import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    isOpen: true,
    allowedRoutes: [],
    isLoading: true,
  },
  reducers: {
    toggleSidebar: (state) => {
      state.isOpen = !state.isOpen;
    },
      setAllowedRoutes: (state, action) => {
    state.allowedRoutes = action.payload
      },
setIsLoading: (state, action) => {
      state.isLoading = action.payload
} 

  },
});

export const { toggleSidebar,setAllowedRoutes,setIsLoading } = sidebarSlice.actions;
export default sidebarSlice.reducer;
