import { useLocation, useNavigate } from "react-router-dom";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import arrowLeft from "../../../svg/arrow-circle-left.svg";
import { EditSvgWithFunc } from "../../../components/common/SvgWithFunc";

const LicenseQuestionDetails = () => {
  const navigate = useNavigate();
  const {
    state: { question },
  } = useLocation();
   
  return (
    <div>
      <DashboardMainHeader />
      <div className="m-6">
        <div className="flex items-center gap-[0.625rem]">
          <img
            className="cursor-pointer"
            src={arrowLeft}
            alt=""
            onClick={() => {
              navigate(-1);
            }}
          />
          <span className="text-2xl font-bold">{}</span>
        </div>
        <div
          className="bg-white border border-[#e6e6e6] rounded-xl mt-8 mb-16 relative"
          style={{
            boxShadow: `
            0px 1px 3px 0px rgba(0, 0, 0, 0.02),
            0px 1px 6px 0px rgba(0, 0, 0, 0.07)
          `,
          }}
        >
          <EditSvgWithFunc
            className={"absolute right-12 top-10"}
            onClick={() => {
              navigate("/edit-license-question/1", {
                state: {
                  question: question,
                },
              });
            }}
          />
          <div className="flex items-start  px-12 pt-16 pb-10">
            <div className="grid w-full">
              <div className="grid grid-cols-[15%_85%] py-5">
                <h2 className="">Question:</h2>
                <h2>{question?.question_en}</h2>
              </div>
              <hr className="text-[#F5F5F5] border-t-[1px]" />

              <div className="grid grid-cols-[15%_85%] py-5">
                <h2 className="">Answer:</h2>
                <h2>{question?.answer_en}</h2>
              </div>
              <hr className="text-[#F5F5F5] border-t-[1px]" />

              <div className="grid grid-cols-[15%_85%] py-5">
                <h2 className="">Question (Arabic):</h2>
                <h2>{question?.question_ar}</h2>
              </div>
              <hr className="text-[#F5F5F5] border-t-[1px]" />

              <div className="grid grid-cols-[15%_85%] py-5">
                <h2 className="">Answer (Arabic):</h2>
                <h2>{question?.answer_ar}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseQuestionDetails;
