import React from "react";
import {
  Button,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Avatar,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { useEffect } from "react";
import { useFetchProducts } from "../../../services/apis/products";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  addNewInventoryRequest,
  useFetchAllStoreLocations,
} from "../../../services/apis/inventory";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import { MenuProps } from "../../../components/common/MenuProps";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
const RequestDialog = ({
  open,
  onClose,
  label,
  setRequests,
  fetchData,
  productId,
  handleResponse,
  queryParameters,
}) => {
  const { width } = useWindowSize();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [type, setType] = useState("ingot");
  const { data, isFetching } = useFetchAllStoreLocations();
  const [createdOrderId, setCreatedOrderId] = useState(null); // Store the created order ID
  const [productsqueryParameters, setProductsqueryParameters] = useState({
    inventory_type: "ingot",
  });
  const { data: products, isLoading } = useFetchProducts(
    productsqueryParameters.inventory_type
  );
  const [requestsPayload, setRequestsPayload] = useState({
    product_id: null,
    amount: null,
    storage_location: null,
  });
  const dispatch = useDispatch();
  const resetForm = () => {
    setRequestsPayload({
      product_id: null,
      amount: null,
      storage_location: "",
    });
    onClose();
  };
  const handleCloseDialog = () => {
    resetForm();
    setType("ingot");
  };
  // const handleSuccessDialogClose = () => {
  //   setSuccessDialogOpen(false);
  // };
  const handleChange = (name, value) => {
    if (name === "type") {
      setType(value);
      setProductsqueryParameters((prev) => ({
        ...prev,
        inventory_type: value,
      }));
    } else {
      setRequestsPayload((prevPayload) => ({
        ...prevPayload,
        [name]: value,
      }));
    }
  };
  useEffect(() => {
    if (type) {
      setProductsqueryParameters({
        inventory_type: type,
      });
    }
  }, [type]);
  const validateFields = () => {
    return (
      requestsPayload.product_id &&
      requestsPayload.amount &&
      requestsPayload.storage_location
    );
  };
  const navigate = useNavigate();
  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   try {
  //     if (validateFields()) {
  //       const amount = parseFloat(requestsPayload.amount);
  //       const isValidAmount = /^\d+(\.\d+)?$/.test(requestsPayload.amount);
  //       if (isValidAmount && !isNaN(amount) && amount > 0) {
  //         setIsSubmitting(true);
  //         const response = await addNewInventoryRequest(requestsPayload);

  //         // Set the order ID for use in the success dialog
  //         if (response?.data?.order_id) {
  //           setCreatedOrderId(response.data.order_id);
  //         }
  //         console.log("createdOrderId ", createdOrderId);
  //         console.log("response.data.order_id ", response.data.order_id);
  //         console.log("response.data ", response.data);
  //         console.log("response ", response);
  //         const responseData = await fetchData(queryParameters);
  //         const data = responseData?.data?.data?.result;
  //         setRequests(data);
  //         onClose();
  //         await handleResponse();
  //         setSuccessDialogOpen(true); // Open the success dialog
  //       } else {
  //         dispatch(
  //           setData({
  //             openSnack: true,
  //             message: "Please enter a valid number for the Quantity.",
  //           })
  //         );
  //       }
  //     } else {
  //       dispatch(
  //         setData({
  //           openSnack: true,
  //           message: "Please fill in all fields.",
  //         })
  //       );
  //     }
  //   } catch (error) {
  //     dispatch(
  //       setData({
  //         openSnack: true,
  //         message: error.response.data.message,
  //       })
  //     );
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (validateFields()) {
        const amount = parseFloat(requestsPayload.amount);
        const isValidAmount = /^\d+(\.\d+)?$/.test(requestsPayload.amount);
        if (isValidAmount && !isNaN(amount) && amount > 0) {
          setIsSubmitting(true);
          await addNewInventoryRequest(requestsPayload);
          // const response = await fetchData(queryParameters);
          // const data = response?.data?.data?.result;
          // console.log("res ", data);
          // setRequests(data);
          const refetchResponse = await handleResponse();

          setCreatedOrderId(refetchResponse?.data?.data?.result[0]?.order_id);
          onClose();
          setSuccessDialogOpen(true);
        } else {
          dispatch(
            setData({
              openSnack: true,
              message: "Please enter a valid number for the Quantity.",
            })
          );
        }
      } else {
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all fields.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    setRequestsPayload({
      product_id: null,
      amount: null,
      storage_location: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, productId, fetchData, productsqueryParameters, type]);
  return (
    <>
      <Dialog
        open={successDialogOpen}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
      >
        {isFetching || isLoading ? (
          <LoadingSpinner />
        ) : (
          <DialogContent
            sx={{
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#D9F4DD",
                height: "80px",
                width: "80px",
                alignSelf: "center",
                marginRight: "15px",
              }}
            >
              <img src={`../../assets/Vector.png`} alt="vector" />
            </Avatar>
            <Typography
              sx={{ marginTop: "10%", marginBottom: "10%" }}
              variant="h6"
            >
              Request has been sent Successfully.
            </Typography>
            <Button
              onClick={() => {
                navigate(`/requests/${createdOrderId}`);
              }}
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#E9C237",
                "&:hover": { backgroundColor: "#E9C237" },
                height: "46px",
                width: "291px",
                borderRadius: "9px",
              }}
            >
              Go to Requests
            </Button>
          </DialogContent>
        )}
      </Dialog>
      <Dialog
        maxWidth="700px"
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: width > 768 ? "700px" : "100%",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p className="text-2xl mx-auto mt-12 md:mx-0 md:mt-0">
                Request Order
              </p>
            </Box>
            <div className="grid grid-cols-1 md:flex md:flex-col md:w-full">
              <Box sx={{ marginTop: "15px" }}>
                <FormLabel
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#4C4C4C",
                  }}
                  id="demo-row-radio-buttons-group-label"
                >
                  Type
                </FormLabel>
                <RadioGroup
                  style={{ marginTop: "15px" }}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={type}
                  onChange={(event) => handleChange("type", event.target.value)}
                >
                  <FormControlLabel
                    style={{ color: "#4C4C4C" }}
                    value="ingot"
                    control={<Radio />}
                    label="Ingots"
                  />
                  <FormControlLabel
                    style={{ color: "#4C4C4C" }}
                    value="fraction"
                    control={<Radio />}
                    label="Fractions"
                  />
                </RadioGroup>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#595959",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  Select Product
                </Typography>
                <FormControl
                  style={{
                    border: "1px solid #E6E6E6",
                    borderRadius: "12px",
                  }}
                  sx={{ width: "100%", "& fieldset": { border: "none" } }}
                >
                  <Select
                    displayEmpty
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                    value={requestsPayload.product_id || ""}
                    onChange={(event) =>
                      handleChange("product_id", event.target.value)
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select Product</em>;
                      }
                      const selectedProduct = products?.find(
                        (product) => product.id === selected
                      );
                      return selectedProduct ? selectedProduct.name : "";
                    }}
                  >
                    {products?.map((product) => (
                      <MenuItem key={product.id} value={product.id}>
                        {product.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" }, color: "#595959" }}
                style={{
                  color: "#595959",
                  height: "48px",
                  backgroundColor: "transparent",
                  border: "1px solid #E6E6E6",
                  borderRadius: "12px",
                }}
                name="Enter Quantity"
                placeholder="Enter Quantity"
                value={requestsPayload.amount || ""}
                onChange={(event) => {
                  var temppayload = { ...requestsPayload };
                  temppayload.amount = event.target.value;
                  setRequestsPayload({ ...temppayload });
                }}
              />
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#595959",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  Select Storage Location
                </Typography>
                <FormControl
                  style={{
                    border: "1px solid #E6E6E6",
                    borderRadius: "12px",
                  }}
                  sx={{ width: "100%", "& fieldset": { border: "none" } }}
                >
                  <Select
                    displayEmpty
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                    value={requestsPayload.storage_location || ""}
                    onChange={(event) =>
                      handleChange("storage_location", event.target.value)
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select Storage Location </em>;
                      }
                      return (
                        data?.result?.find((item) => item.id === selected)
                          ?.name || ""
                      );
                    }}
                  >
                    {data?.result?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div className="grid grid-cols-1 gap-4 md:flex   justify-center items-center mx-auto">
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: width > 768 ? "200px" : "150px",
                  borderRadius: "9px",
                  background: "#E9C237",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#E9C237",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: width > 768 ? "200px" : "150px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#E9C237",
                  fontWeight: 600,
                  borderColor: "#E9C237",
                  "&:hover": {
                    borderColor: "#E9C237",
                  },
                }}
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar />
    </>
  );
};

export default RequestDialog;
