import { Box, IconButton, InputBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useFetchUsers } from "../../services/apis/users";
import { formDate } from "../../services/helpers";
import { handlePageChange, checkString } from "../../services/utils/filters";
import FilterDrawer from "../../components/common/FilterDrawer";
import { handleFilterByString } from "../../services/utils/filters";
import Header from "../../components/Header";
import { saveAs } from "file-saver";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import { DataGridStyle } from "../../styles";
import AutoRefresh from "../../components/common/AutoRefresh";
import { useGlobalSocket } from "../../useGlobalSocket";
import NewTable from "../../components/common/NewTable";
const Users = () => {
  const navigate = useNavigate();

  const handleRowClick = (row) => {
    navigate(`/profile/${row.id}`);
  };
  const [queryParameters, setQueryParameters] = useState({
    date_from: null,
    date_to: null,
    kyc_status: "",
    is_online: "all",
    orderBy: null,
    sort: null,
    page: 0,
    nationalId: null,
    phone: null,
    email: null,
  });
  const filterList = [
    { id: "no kycRequest", name: "no kycRequest" },
    { id: "pending", name: "pending" },
    { id: "approved", name: "approved" },
    { id: "rejected", name: "rejected" },
  ];
  const { data, isFetching, refetch } = useFetchUsers(queryParameters);
  const [toggleListen, setToggleListen] = useState(false);
  const { addEventListener, removeEventListener } = useGlobalSocket();

  useEffect(() => {
    if (toggleListen) {
      addEventListener("users-update", ({ user_id }) => {
        refetch();
      });
      return () => {
        removeEventListener("users-update", () => {});
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEventListener, toggleListen]);

  const newUsersColumns = ItemsColumns([
    { field: "full_name", headerName: "Username", flex: 2 },
    { field: "email", flex: 2 },
    { field: "phone", headerName: "Phone Number", flex: 2 },
    { field: "currentBalance", headerName: "Balance", float: true },
    {
      field: "is_online",
      headerName: "Online Status",
      isOnline: true,
      flex: 2,
    },
    { field: "created", headerName: "Join Date", date: true, flex: 2 },
    { field: "kyc_status", headerName: "KYC", kyc: true },
  ]);

  const handleSearchInput = (e) => {
    const { value } = e.target;
    checkString(value, queryParameters, setQueryParameters);
  };

  const handleUserFilterCheckbox = (e) => {
    handleFilterByString(
      e.target.value,
      "kyc_status",
      queryParameters,
      setQueryParameters
    );
  };

  const downloadUsers = async () => {
    try {
      const headers = [
        "ID",
        "Username",
        "Email",
        "Phone Number",
        "Balance",
        "Online Status",
        "Join Date",
        "KYC",
      ];
      const csvContent = [
        headers.join(","),
        ...data?.result?.map((user) => {
          return [
            user.id,
            user.full_name,
            user.email,
            user.phone,
            user.currentBalance ?? 0,
            user.is_online ? "online" : "offline",
            `"${formDate(user.created)}"`,
            user.kyc_status,
          ].join(",");
        }),
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "users.csv");
    } catch (error) {
      console.error("Error downloading users:", error);
    }
  };

  return (
    <Box>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="User Information" marginL={"25px"} />
      <AutoRefresh
        setToggleListen={setToggleListen}
        toggleListen={toggleListen}
      />

      <div className="m-4 md:flex items-center justify-between">
        <div className="flex items-center rounded-xl bg-white p-2 w-[95%] md:w-3/5">
          <IconButton type="button" aria-label="search">
            <img alt="search-normal" src={`../../assets/search-normal.png`} />
          </IconButton>
          <InputBase
            onChange={handleSearchInput}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Email or Phone number....."
          />
          <FilterDrawer
            queryParameters={queryParameters}
            setQueryParameters={setQueryParameters}
            firstList={filterList}
            firstKey="kyc_status"
            firstTitle="KYC Status"
            secondTitle="Online Status"
            handleFirstCheckBox={handleUserFilterCheckbox}
            isUsers={true}
          />
        </div>
        <button
          onClick={() => downloadUsers()}
          className="rounded-lg bg-[#E9C237] text-white px-4 py-2 text-sm mt-4 w-[95%] md:w-fit hidden md:block   md:m-0"
        >
          Download Report
        </button>
      </div>
      <SecondBox>
        <Box height="100%" sx={{ ...DataGridStyle, overflowX: "auto" }}>
          <NewTable
            centerHeader
            columns={newUsersColumns}
            isLoading={isFetching}
            data={data?.data?.data?.result}
            totalItems={data?.data?.data?.totalItems}
            totalPages={data?.data?.data?.totalPages}
            handlePageChange={(newPage) =>
              handlePageChange(newPage, queryParameters, setQueryParameters)
            }
            handleRowClick={handleRowClick}
            currentPage={queryParameters}
          />
        </Box>
      </SecondBox>
      <div className="flex items-center justify-center m-2">
        <button
          onClick={() => downloadUsers()}
          className="rounded-lg bg-[#E9C237] text-white px-4 py-2 text-sm mt-4 w-[90%] md:hidden mx-auto  md:m-0"
        >
          Download Report
        </button>
      </div>
    </Box>
  );
};

export default Users;
