import { useMutation, useQuery, useQueryClient } from "react-query";
import { axiosInstance as axios } from "../config";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
// Fetch articles
export const useFetchArticles = () => {
  return useQuery(["articles"], async () => {
    const response = await axios.get(`/api/articles/index`);
    return response.data?.data; // Assuming response.data contains the articles
  });
};

// Delete an article
export const useDeleteArticle = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async (id) => {
      const response = await axios.get(`/api/articles/delete/${id}`);
      return response.data; // Assuming response.data contains the result of deletion
    },
    {
      // On success, invalidate the 'articles' query to refetch the list
      onSuccess: () => {
        queryClient.invalidateQueries(["articles"]);
      },
      onError: (error) => {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      },
    }
  );
};

// Add an article
export const useAddArticle = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async (body) => {
      const response = await axios.post("/api/articles/add", body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data; // Assuming response.data contains the added article
    },
    {
      // On success, invalidate the 'articles' query to refetch the list
      onSuccess: () => {
        queryClient.invalidateQueries(["articles"]);
      },
      onError: (error) => {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      },
    }
  );
};

// Edit an article
export const useEditArticle = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async ({ id, body }) => {
       const response = await axios.post(`/api/articles/edit/${id}`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data; // Assuming response.data contains the updated article
    },
    {
      // On success, invalidate the 'articles' query to refetch the list
      onSuccess: () => {
        queryClient.invalidateQueries(["articles"]);
      },
      onError: (error) => {
        if (error.response.status === 422) {
          dispatch(
            setData({
              openSnack: true,
              message: "fields should not be empty",
            })
          );
        } else {
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.message,
            })
          );
        }
      },
    }
  );
};
