import React, { useState, useEffect } from "react";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Button,
} from "@mui/material";
import { useStylesHook } from "../../components/common/useStyles";
import ImageUploader from "../../components/ImageUploader";
import { GoPlus } from "react-icons/go";
import shariah from "../../svg/shariah.svg";
import { useNavigate } from "react-router-dom";
import { useAddLicense } from "../../services/apis/license";
import CustomDialog from "../../components/CustomDialog";
// Arabic letters and spaces regex validation
const isArabic = (text) =>
  /^[\u0600-\u06FF\s\d.,!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(text);
const isEnglish = (text) =>
  /^[A-Za-z\s\d.,!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(text);

const steps = ["English", "Arabic", "Review"];

const AddAppLicense = () => {
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useAddLicense();
  const [activeStep, setActiveStep] = useState(0);
  const c = useStylesHook();
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [formData, setFormData] = useState({
    questionsOrDescription: "questions",
    image: "",
    en: {
      name: "",
      headline: "",
      description: "",
      questions: [{ question: "", answer: "" }],
    },
    ar: {
      name: "",
      headline: "",
      description: "",
      questions: [{ question: "", answer: "" }],
    },
  });

  const currentLang = activeStep === 0 ? "en" : "ar";

  const handleFieldChange = (lang, field, value) => {
    if (lang === "ar" && !isArabic(value)) return; // Restrict to Arabic only for Arabic section
    if (lang === "en" && !isEnglish(value)) return; // Restrict to Arabic only for Arabic section
    setFormData((prev) => ({
      ...prev,
      [lang]: {
        ...prev[lang],
        [field]: value,
      },
    }));
  };

  const handleQuestionChange = (lang, index, field, value) => {
    if (lang === "ar" && !isArabic(value)) return; // Restrict to Arabic for Arabic questions/answers
    if (lang === "en" && !isEnglish(value)) return; // Restrict to Arabic for Arabic questions/answers
    setFormData((prev) => {
      const updatedQuestions = [...prev[lang].questions];
      updatedQuestions[index][field] = value;
      return {
        ...prev,
        [lang]: {
          ...prev[lang],
          questions: updatedQuestions,
        },
      };
    });
  };

  const addQuestion = () => {
    if (currentLang === "en") {
      setFormData((prev) => ({
        ...prev,
        en: {
          ...prev.en,
          questions: [...prev.en.questions, { question: "", answer: "" }],
        },
        ar: {
          ...prev.ar,
          questions: [...prev.ar.questions, { question: "", answer: "" }],
        },
      }));
    }
  };

  const validateForm = () => {
    const { name, headline, questions, description } = formData[currentLang];
    const allFieldsFilled =
      file?.name !== null &&
      image !== null &&
      name.trim() !== "" &&
      headline.trim() !== "" &&
      formData?.questionsOrDescription === "questions"
        ? questions.every(
            (q) => q.question.trim() !== "" && q.answer.trim() !== ""
          )
        : description.trim() !== "";

    setIsNextEnabled(allFieldsFilled);
  };

  useEffect(() => {
    validateForm();
  }, [formData, activeStep, file, image]);

  console.log("FormData -->", formData);

  return (
    <div>
      <DashboardMainHeader />
      <Header title={"Add License"} marginL={"24px"} marginB={"32px"} />
      <div
        className="mx-6 px-6 py-10 bg-white rounded-2xl"
        style={{
          boxShadow:
            "0px 1px 3px rgba(0, 0, 0, 0.02), 0px 1px 6px rgba(0, 0, 0, 0.07)",
        }}
      >
        <Stepper className={`${c.root}`} activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 2 ? (
          <div className="flex mt-16">
            <img src={shariah} alt="" />
            <div className=" flex-1">
              <div className="flex items-center ">
                <h2 className="w-1/4">License Name:</h2>
                <h2 className="">{formData?.en?.name}</h2>
              </div>
              <hr className={`text-[#F5F5F5] border-t-[1px] my-3`} />
              <div className="flex items-center ">
                <h2 className="w-1/4">License Headline:</h2>
                <h2 className="">{formData?.en?.headline}</h2>
              </div>
              <hr
                className={`text-[#F5F5F5] border-t-[1px] my-3 ${
                  formData?.questionsOrDescription === "description"
                    ? ""
                    : "hidden"
                }`}
              />
              <div
                className={`flex items-center ${
                  formData?.questionsOrDescription === "description"
                    ? ""
                    : "hidden"
                }`}
              >
                <h2 className="w-1/4">License Description:</h2>
                <h2 className="">{formData?.en?.description}</h2>
              </div>
              <hr className="text-[#F5F5F5] border-t-[1px] my-3" />
              <div className="flex items-center ">
                <h2 className="w-1/4">License Name (Arabic):</h2>
                <h2 className="">{formData?.ar?.name}</h2>
              </div>
              <hr className={`text-[#F5F5F5] border-t-[1px] my-3 `} />
              <div className="flex items-center ">
                <h2 className="w-1/4">License Headline(Arabic):</h2>
                <h2 className="">{formData?.ar?.headline}</h2>
              </div>
              <hr
                className={`text-[#F5F5F5] border-t-[1px] my-3 ${
                  formData?.questionsOrDescription === "description"
                    ? ""
                    : "hidden"
                }`}
              />
              <div
                className={`flex items-center  ${
                  formData?.questionsOrDescription === "description"
                    ? ""
                    : "hidden"
                }`}
              >
                <h2 className="w-1/4">License Description(Arabic):</h2>
                <h2 className="">{formData?.ar?.description}</h2>
              </div>
              <hr className="text-[#F5F5F5] border-t-[1px] my-3" />
            </div>
          </div>
        ) : (
          <div className="mt-16 mb-12 flex flex-col gap-6">
            {/* License Name Input */}
            <div>
              <p className="text-[#595959] text-sm mb-2">
                License Name ({currentLang === "en" ? "English" : "Arabic"})
              </p>
              <input
                onChange={(e) =>
                  handleFieldChange(currentLang, "name", e.target.value)
                }
                value={formData[currentLang].name}
                placeholder={`Enter License Name (${
                  currentLang === "en" ? "English" : "Arabic"
                })`}
                className="border w-full bg-[#f5f5f5] rounded-xl p-4 outline-none"
              />
            </div>

            {/* Image Uploader - Only for English */}
            {currentLang === "en" && (
              <ImageUploader
                title={"License Image"}
                image={image}
                setFile={setFile}
                setImage={setImage}
                setPayload={setFormData}
                file={file}
              />
            )}

            {/* License Headline Input */}
            <div>
              <p className="text-[#595959] text-sm mb-2">
                License Headline ({currentLang === "en" ? "English" : "Arabic"})
              </p>
              <input
                onChange={(e) =>
                  handleFieldChange(currentLang, "headline", e.target.value)
                }
                value={formData[currentLang].headline}
                placeholder={`Enter License Headline (${
                  currentLang === "en" ? "English" : "Arabic"
                })`}
                className="border w-full bg-[#f5f5f5] rounded-xl p-4 outline-none"
              />
            </div>

            {/* Questions Section */}
            <div className={`${activeStep !== 0 ? "hidden" : ""}`}>
              <p className="text-[#595959] text-sm mb-2">
                Add License Questions or Description
              </p>
              <RadioGroup
                style={{
                  marginTop: "15px",
                }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(event) => {
                  setFormData((prevPayload) => ({
                    ...prevPayload,
                    questionsOrDescription: event.target.value,
                  }));
                }}
                value={formData.questionsOrDescription}
              >
                <FormControlLabel
                  onClick={() => {
                    setFormData((prevPayload) => ({
                      ...prevPayload,
                      questionsOrDescription: "questions",
                    }));
                  }}
                  style={{
                    marginRight: "4%",
                    color: "#4C4C4C",
                    fontSize: "24px",
                  }}
                  value={"questions"}
                  control={
                    <Radio
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#E9C237",
                        },
                        "&, &.Mui-unchecked": {
                          color: "#B3B3B3",
                        },
                      }}
                    />
                  }
                  label="Questions"
                />
                <FormControlLabel
                  style={{ color: "#4C4C4C", fontSize: "24px" }}
                  value={"description"}
                  control={
                    <Radio
                      onClick={() => {
                        setFormData((prevPayload) => ({
                          ...prevPayload,
                          questionsOrDescription: "description",
                        }));
                      }}
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#E9C237",
                        },
                        "&, &.Mui-unchecked": {
                          color: "#B3B3B3",
                        },
                      }}
                    />
                  }
                  label="Description"
                />
              </RadioGroup>
            </div>
            {formData.questionsOrDescription === "questions" ? (
              <div>
                {formData[currentLang].questions.map((question, index) => (
                  <div key={index} className="flex flex-col gap-2 mb-3">
                    <div>
                      <p className="text-[#595959] text-sm mb-2">
                        Question {index + 1}
                      </p>
                      <input
                        onChange={(e) =>
                          handleQuestionChange(
                            currentLang,
                            index,
                            "question",
                            e.target.value
                          )
                        }
                        value={question.question}
                        placeholder={`Enter Question ${index + 1}`}
                        className="border w-full bg-[#f5f5f5] rounded-xl p-4 outline-none"
                      />
                    </div>
                    <div>
                      <p className="text-[#595959] text-sm mb-2">Answer</p>
                      <input
                        onChange={(e) =>
                          handleQuestionChange(
                            currentLang,
                            index,
                            "answer",
                            e.target.value
                          )
                        }
                        value={question.answer}
                        placeholder={`Enter Answer  `}
                        className="border w-full bg-[#f5f5f5] rounded-xl p-4 outline-none"
                      />
                    </div>
                  </div>
                ))}
                {currentLang === "en" && (
                  <div
                    className="text-[#e9c237] flex items-center gap-2 mt-2 cursor-pointer"
                    onClick={addQuestion}
                  >
                    <GoPlus fontSize={14} /> Add another question
                  </div>
                )}
              </div>
            ) : (
              <div>
                <p className="text-[#595959] text-sm mb-2">
                  License Description (
                  {currentLang === "en" ? "English" : "Arabic"})
                </p>
                <input
                  onChange={(e) =>
                    handleFieldChange(
                      currentLang,
                      "description",
                      e.target.value
                    )
                  }
                  value={formData[currentLang].description}
                  placeholder={`Enter License Name (${
                    currentLang === "en" ? "English" : "Arabic"
                  })`}
                  className="border w-full bg-[#f5f5f5] rounded-xl p-4 outline-none"
                />
              </div>
            )}
          </div>
        )}

        {/* Navigation Buttons */}
        <div className="flex items-center justify-end gap-4">
          {/* Back Button */}
          <button
            onClick={() => setActiveStep((prev) => prev - 1)}
            disabled={activeStep === 0 || isLoading}
            className={`${
              activeStep === 0 ? "hidden" : "block"
            } px-20 py-2 border border-[#e9c237] text-[#e9c237] rounded-lg disabled:opacity-50`}
          >
            Back
          </button>

          {/* Next or Save Button */}
          <button
            onClick={async () => {
              if (activeStep === steps.length - 1) {
                // Save API Call
                let sentData;
                if (formData.questionsOrDescription === "questions") {
                  sentData = {
                    image: formData.image,
                    data: JSON.stringify({
                      name_ar: formData.ar.name,
                      name_en: formData.en.name,
                      headline_ar: formData.ar.headline,
                      headline_en: formData.en.headline,
                      questions: formData.ar.questions.map((q, index) => ({
                        question_ar: q.question,
                        answer_ar: q.answer,
                        question_en:
                          formData.en.questions[index]?.question || "",
                        answer_en: formData.en.questions[index]?.answer || "",
                      })),
                    }),
                  };
                } else {
                  sentData = {
                    image: formData.image,
                    data: JSON.stringify({
                      name_ar: formData.ar.name,
                      name_en: formData.en.name,
                      headline_ar: formData.ar.headline,
                      headline_en: formData.en.headline,
                      description_ar: formData.ar.description,
                      description_en: formData.en.description,
                    }),
                  };
                }
                await mutateAsync(sentData).then(() => {
                  setSuccessDialogOpen(true);
                });
              }
              setActiveStep((prev) => prev + 1);
            }}
            disabled={!isNextEnabled || isLoading}
            className={`px-20 py-2 bg-[#e9c237] text-white rounded-lg disabled:opacity-50`}
          >
            {activeStep === steps.length - 1 ? "Save" : "Next"}
          </button>
        </div>
      </div>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="License has been Added Successfully."
        message=""
        buttonText="Go to Licenses"
        buttonLink="/app-license"
      />
    </div>
  );
};

export default AddAppLicense;
