// PrivateRoute.js
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { checkIfUserIsLogged } from "../services/helpers";

const noSidebarPaths = [
  "/forget-password",
  "/ResetPassword",
  "/register",
  "/deactivatedAdmin",
  "/not-authorized",
];

const PrivateRoute = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { allowedRoutes, isLoading } = useSelector((state) => state.sideBar);
  const { isAuthenticated } = useSelector((state) => state.authentication);
  const isRouteAllowed = (currentPath) =>
    [...allowedRoutes, "/AdminProfile"].some((route) =>
      currentPath.startsWith(route)
    );
  useEffect(() => {
    const isLoggedIn = checkIfUserIsLogged();

    if (
      !isLoggedIn &&
      ![
        "/register",
        "/forget-password",
        "/ResetPassword",
        "/deactivatedAdmin",
      ].includes(pathname)
    ) {
      navigate("/login", { replace: true });
      return;
    }
    if (!isLoading && isLoggedIn && pathname === "/login") {
      navigate("/AdminProfile", { replace: true });
      return;
    }
    if (!isLoading && isLoggedIn && !isRouteAllowed(pathname)) {
      navigate("/not-authorized", { replace: true });
      return;
    }

    if (!isAuthenticated && !noSidebarPaths.includes(pathname)) {
      navigate("/login", { replace: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, navigate, isLoading, allowedRoutes, isAuthenticated]);

  return isAuthenticated && isRouteAllowed(pathname) ? (
    <Outlet />
  ) : [
      "/register",
      "/login",
      "/forget-password",
      "/ResetPassword",
      "/deactivatedAdmin",
    ].includes(pathname) ? (
    <Outlet />
  ) : null;
};

export default PrivateRoute;
