import React from "react";
import { Button, Box, Typography, Avatar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import TextFieldComponent from "../../components/TextFieldComponent";
import CustomDialog from "../../components/CustomDialog";
import { TbExclamationMark } from "react-icons/tb";
import { useMutation, useQueryClient } from "react-query";
import { sellFraction } from "../../services/apis/inventory";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

const SellFractionDialog = ({ open, label, onClose, row, refetch }) => {
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const [sellFractionPayload, setSellFractionPayload] = useState({
    id: row?.id,
    serial: "",
    sold_price: "",
  });
  const handleNumericInput = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(sellFraction, {
    onSuccess: () => {
      setSuccessDialogOpen(true);
      refetch();
      queryClient.invalidateQueries("fractions");
      onClose();
    },
    onError: (error) => {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    },
  });
  const handleSubmit = async () => {
    mutation.mutate(sellFractionPayload);
  };
  useEffect(() => {
    setSellFractionPayload({ id: row?.id, serial: "", sold_price: "" });
  }, [row]);
  useEffect(() => {
    if (!open) {
      setSellFractionPayload((prevPayload) => ({
        ...prevPayload,
        serial: "",
        sold_price: "",
      }));
    }
  }, [open]);
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            padding: { xs: "16px", sm: "40px" },
            height: "auto",
            backgroundColor: "#FFF",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
            <IoIosCloseCircleOutline
              onClick={onClose}
              color="#4C4C4C"
              style={{
                width: "24px",
                height: "24px",
                cursor: "pointer",
              }}
            />
          </Box>
          <Avatar
            sx={{
              bgcolor: "rgba(233, 194, 55, 0.3)",
              height: { xs: "60px", sm: "80px" },
              width: { xs: "60px", sm: "80px" },
              marginRight: "15px",
            }}
          >
            <TbExclamationMark color={"#E9C237"} size={50} />
          </Avatar>
          <Typography
            sx={{
              color: "#4C4C4C",
              fontSize: { xs: "16px", sm: "20px" },
              fontWeight: 400,
            }}
          >
            Are you sure you want to Sell this ingot ?
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                marginTop: "5%",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: { xs: "100%", sm: "50%" },
                }}
              >
                <Typography
                  sx={{ color: "#808080", fontSize: "14px", fontWeight: 500 }}
                >
                  Product :
                </Typography>
                <Typography
                  sx={{
                    marginLeft: "6px",
                    color: "#808080",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {row?.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: { xs: "100%", sm: "50%" },
                }}
              >
                <Typography
                  sx={{ color: "#808080", fontSize: "14px", fontWeight: 500 }}
                >
                  Serial number:
                </Typography>
                <Typography
                  sx={{
                    marginLeft: "6px",
                    color: "#808080",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {row?.serial}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                marginTop: "5%",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: { xs: "100%", sm: "50%" },
                }}
              >
                <Typography
                  sx={{ color: "#808080", fontSize: "14px", fontWeight: 500 }}
                >
                  Sold Weight :
                </Typography>
                <Typography
                  sx={{
                    marginLeft: "6px",
                    color: "#808080",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {row?.soldWeight || 0} Gm
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ color: "#808080", fontSize: "14px", fontWeight: 500 }}
                >
                  Available Weight :
                </Typography>
                <Typography
                  sx={{
                    marginLeft: "6px",
                    color: "#808080",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {row?.currentWeight} Gm
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextFieldComponent
              sx={{ "& fieldset": { border: "none" } }}
              style={{
                height: "48px",
                width: "100%",
                backgroundColor: "#F7F7F7",
                borderRadius: "9px",
              }}
              name="Write Ingot Serial number to confirm Sell"
              placeholder="Enter Serial number "
              value={sellFractionPayload.serial}
              onChange={(event) => {
                const numericValue = handleNumericInput(event.target.value);
                setSellFractionPayload((prevPayload) => ({
                  ...prevPayload,
                  serial: numericValue,
                }));
              }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextFieldComponent
              sx={{ "& fieldset": { border: "none" } }}
              style={{
                height: "48px",
                width: "100%",
                backgroundColor: "#F7F7F7",
                borderRadius: "9px",
              }}
              name="Ingot Price"
              placeholder="Enter Ingot Price"
              value={sellFractionPayload.sold_price}
              onChange={(event) => {
                const numericValue = handleNumericInput(event.target.value);
                setSellFractionPayload((prevPayload) => ({
                  ...prevPayload,
                  sold_price: numericValue,
                }));
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 2,
              marginTop: "10px",
              flexDirection: { xs: "column", sm: "row" },
              width: "100%",
            }}
          >
            <Button
              disabled={!sellFractionPayload.serial || mutation.isLoading}
              variant="contained"
              sx={{
                width: "100%",
                borderRadius: "9px",
                background: "#E9C237",
                height: "48px",
                fontWeight: 500,
                "&:hover": {
                  background: "#E9C237",
                },
              }}
              onClick={handleSubmit}
            >
              {label}
            </Button>

            <Button
              variant="outlined"
              sx={{
                width: "100%",
                borderRadius: "9px",
                height: "48px",
                color: "#E9C237",
                fontWeight: 600,
                borderColor: "#E9C237",
                "&:hover": {
                  borderColor: "#E9C237",
                },
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Fraction has been Selled Successfully."
        message=""
        buttonText="Go to Sell Fractions Ingots"
      />
      <ReusableSnackbar />
    </>
  );
};

export default SellFractionDialog;
