import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Typography, Button, Skeleton } from "@mui/material";
import ImageDialog from "../../components/common/ImageDialog";

const KycCard = ({ img, title, isLoading }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card sx={{  height: 197 ,width:"100%"}}>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height={140} />
      ) : (
        <CardMedia
          loading="lazy"
          component="img"
          sx={{ height: 140, cursor: "pointer" }}
          title={title}
          image={img}
          onClick={handleClickOpen}
        />
      )}

      <CardContent>
        <Typography
          sx={{
            color: "#595959",
            fontFamily: "Lato",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
          }}
        >
          {title}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
      <ImageDialog isOpen={open} onClose={handleClose} imageUrl={img} />
    </Card>
  );
};

export default KycCard;
