const TextView = ({ title, value, hide }) => {
  return (
    <div
      className={`flex flex-wrap items-center py-4 gap-2 ${hide ? "hidden" : "block"}`}
    >
      <p className="text-sm text-[#595959]">{title}:</p>
      <p
        className={`text-sm    ${
          value === "Approved"
            ? "text-[#28a745]"
            : value === "Rejected"
            ? "text-[#dc3545]"
            : value === "Pending"
            ? "text-[#FFC107]"
            : "text-[#595959]"
        } `}
      >
        {value}
      </p>
    </div>
  );
};

export default TextView;
