import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { axiosInstance as axios } from "../services/config";
import { MdLogout } from "react-icons/md";
import { clearToken } from "../store/slices/authenticationSlice";

const Logout = ({ isOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = async () => {
    try {
      await axios.post(`api/users/adminLogout`);
      // window.close();
      // window.open("/login", "_blank");
      // navigate("/login", { replace: true });

      // Clear the history state to prevent back navigation
      window.history.pushState(null, null, "/login");
      window.history.replaceState(null, null, "/login");
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
      sessionStorage.removeItem("selectedTitle");
      sessionStorage.removeItem("isPageRefreshed");
      dispatch(clearToken());
    } catch (error) {
      if (error.response.status === 401) {
        // Clear the history state to prevent back navigation
        window.history.pushState(null, null, "/login");
        window.history.replaceState(null, null, "/login");
        navigate("/login", { replace: true });
        localStorage.removeItem("token");
        sessionStorage.removeItem("selectedTitle");
        sessionStorage.removeItem("isPageRefreshed");
        dispatch(clearToken());
      } else {
        // Clear the history state to prevent back navigation
        window.history.pushState(null, null, "/login");
        window.history.replaceState(null, null, "/login");
        navigate("/login", { replace: true });
        localStorage.removeItem("token");
        sessionStorage.removeItem("selectedTitle");
        sessionStorage.removeItem("isPageRefreshed");
        dispatch(clearToken());
      }
    }
  };

  return (
    <div
      onClick={handleLogOut}
      className={`flex items-center ${
        !isOpen && "justify-center"
      } gap-5 p-4 cursor-pointer hover:bg-gray-100 bg-white`}
    >
      <MdLogout size={20} />
      {isOpen && <p className="text-sm">Logout</p>}
    </div>
  );
};

export default Logout;
