import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import { DataGridStyle } from "../../../styles";
import { fetchAdminsFirstTab } from "../../../services/apis/roles-and-permissions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EditRoleModal from "../EditRoleModal";
import { checkString, handlePageChange } from "../../../services/utils/filters";
import { saveAs } from "file-saver";
import { ItemsColumns } from "../../../components/common/Columns";
import NewTable from "../../../components/common/NewTable";
const FirstAdminTabPanel = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [admins, setAdmins] = useState([]);
  const [totalAdmins, setTotalAdmins] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
    email: null,
  });
  const navigate = useNavigate();
  const getAdmins = async () => {
    setLoading(true);
    setAdmins([]);
    try {
      const response = await fetchAdminsFirstTab(queryParameters);
      const admins = response?.data?.data?.result;
      const adminsPagecount = response.data.data.totalPages;
      setAdmins(admins);
      setTotalAdmins(response.data.data.totalItems);
      setTotalPages(adminsPagecount);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const handleClose = async () => {
    setOpenDialog(false);
  };
  const handleSuccess = () => {
    getAdmins();
    handleClose();
  };
  const handleOpenEditRole = (Admin) => {
    setSelectedAdminId(Admin.id);
    setOpenDialog(true);
  };
  const handleSearchInput = (e) => {
    const { value } = e.target;
    checkString(value, queryParameters, setQueryParameters);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    getAdmins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParameters]);
  const handleClick = (params) => {
    const admin = params.row;
    navigate(`/ViewAdminProfile/${admin.id}`, { state: { admin } });
  };
  const downloadAdmins = async () => {
    try {
      const response = await fetchAdminsFirstTab(queryParameters);
      const admins = response.data.data.result;
      const headers = ["Username", "Email", "National ID", "Status", "Role"];
      const csvContent = [
        headers.join(","),
        ...admins.map((admin) => {
          return [
            admin.full_name,
            admin.email,
            admin.nationalId,
            admin.deactivated_at ? "Deactivated" : "Active",
            admin.userGroup?.name,
          ].join(",");
        }),
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "admins.csv");
    } catch (error) {
      console.error("Error downloading admins:", error);
    }
  };

  const adminsColumns = ItemsColumns([
    { field: "full_name", headerName: "Username", flex: 2 },
    { field: "email", flex: 2 },
    { field: "nationalId", headerName: "National ID" },
    {
      field: "deactivated_at",
      headerName: "Status",
      specialRender: "customRender",
      renderFunction: (params) =>
        params.row?.deactivated_at === null ? (
          <Typography
            onClick={() => {}}
            sx={{ color: "#3284FF", fontSize: "14px", fontWeight: "400" }}
          >
            Active
          </Typography>
        ) : (
          <Typography
            onClick={() => {}}
            sx={{ color: "#F23045", fontSize: "14px", fontWeight: "400" }}
          >
            Deactivated
          </Typography>
        ),
    },
    {
      field: "role",
      specialRender: "customRender",
      renderFunction: (params) => params?.row?.userGroup?.name,
    },
    {
      field: "action",
      headerName: "Actions",
      actions: {
        view: (params) => handleClick(params),
        edit: (params) => handleOpenEditRole(params.row),
      },
    },
  ]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 2,
          mb: 2,
          p: 2,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Paper
          component="form"
          sx={{
            // p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "60%",
            height: "48px",
            backgroundColor: "#F5F5F5",
            boxShadow: "none",
            borderRadius: "12px",
          }}
          onSubmit={handleSubmit}
        >
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <img
              alt="search-normal"
              src={`../../../assets/search-normal.png`}
            />
          </IconButton>
          <InputBase
            onChange={handleSearchInput}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Email , National ID or Phone number"
          />
        </Paper>
        <Box>
          <Button
            onClick={() => downloadAdmins()}
            sx={{
              borderRadius: "9px",
              background: "#E9C237",
              "&:hover": {
                background: "#E9C237",
              },
              // width: "171px",
              height: "42px",
              color: "#FFF",
              fontSize: "16px",
              textTransform: "none",
            }}
          >
            {"Download Report"}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
        }}
      >
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <NewTable
            columns={adminsColumns}
            isLoading={loading}
            data={admins}
            totalPages={totalPages}
            totalItems={totalAdmins}
            handlePageChange={(newPage) =>
              handlePageChange(newPage, queryParameters, setQueryParameters)
            }
            currentPage={queryParameters}
            onCellClick={(params) => {
              if (params.field !== "action") {
                handleClick(params);
              }
            }}
          />
          {/* <DataGrid
            components={{
              LoadingOverlay: () => CustomLoadingOverlay({ columns: 6 }),
            }}
            rows={admins}
            columns={adminsColumns}
            rowCount={totalAdmins}
            pageSize={10}
            loading={loading}
            paginationMode="server"
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
            rowsPerPageOptions={[10]}
            totalPages={totalPages}
            onCellClick={(params) => {
              if (params.field !== "action") {
                handleClick(params);
              }
            }}
          /> */}
        </Box>
      </Box>
      <EditRoleModal
        selectedAdminId={selectedAdminId}
        admins={admins}
        open={openDialog}
        onClose={handleClose}
        onSuccess={handleSuccess}
        label={"Save"}
      />
    </Box>
  );
};
export default FirstAdminTabPanel;
