import { useState } from "react";
import { Box, InputBase } from "@mui/material";
import Modal from "@mui/material/Modal";
import AddRoleModal from "./AddRoleModal";
import EditRoleModal from "./EditRoleModal";
import { useFetchRoles } from "../../services/apis/roles-and-permissions";
import Header from "../../components/Header";
import { addNewRole } from "../../services/apis/roles-and-permissions";
import { DataGridStyle } from "../../styles";
import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import AddButton from "../../components/common/AddButton";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import NewTable from "../../components/common/NewTable";

const ANR = () => {
  const { data: roles, isLoading, refetch } = useFetchRoles();

  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [currentRole] = useState(null);
  const navigate = useNavigate();
  const handleClose = async () => {
    setOpen(false);
    refetch();
  };

  const handleCloseEditModel = () => {
    setOpenEditModal(false);
    refetch();
  };

  const handleClick = (params) => {
    const id = params.row.id;
    const selectedRole = roles.find((role) => role.id === id);
    navigate(`/ViewRole/${id}`, { state: { rolesdetials: selectedRole } });
  };
  const rolesColumns = ItemsColumns([
    { field: "name", flex: 2 },
    { field: "permissions", specialRender: "permissions", flex: 3 },
    { field: "totalUsers", headerName: "Number of Users" },
    { field: "category" },
    {
      field: "is_built",
      headerName: "Type",
      specialRender: "customRender",
      renderFunction: (params) =>
        params?.row?.is_built === 0 ? "Created" : "Built in",
    },
  ]);

  const handleAddRole = () => {
    navigate(`/AddNewRole`);
  };

  return (
    <Box>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Roles" marginL={"25px"} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: 2,
          pr: 2,
        }}
      >
        <AddButton
          title={"+ New Role"}
          onClick={() => {
            handleAddRole();
          }}
        />
      </Box>

      <SecondBox>
        <Box
          m="40px 0 0 0"
          height="auto"
          sx={[DataGridStyle, { overflowX: "auto" }]}
        >
          <Box sx={{ display: "flex", width: "100%", gap: 2, mb: 2, p: 2 }}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "60%",
                height: "48px",
                backgroundColor: "#F5F5F5",
                boxShadow: "none",
                borderRadius: "12px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Filter by Category or Type...."
              />
            </Paper>
          </Box>

          <NewTable
            noPagination
            columns={rolesColumns}
            isLoading={isLoading}
            data={roles}
            onCellClick={(params) => {
              handleClick(params);
            }}
          />
          {/* <DataGrid
            rows={isLoading ? [] : roles}
            columns={rolesColumns}
            loading={isLoading}
            autoHeight
            components={{
              Footer: () => null,
              LoadingOverlay: () =>
                CustomLoadingOverlay({ columns: 7, rows: 2 }),
            }}
            sx={{
              cursor: "pointer",
              minWidth: "1000px",
            }}
            onRowClick={(params) => {
              handleClick(params);
            }}
          /> */}
        </Box>
        <Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <AddRoleModal handleClose={handleClose} addNewRole={addNewRole} />
          </Modal>

          <Modal open={openEditModal} onClose={handleCloseEditModel}>
            <EditRoleModal
              handleClose={handleCloseEditModel}
              role={currentRole}
            />
          </Modal>
        </Box>
      </SecondBox>

      <ReusableSnackbar />
    </Box>
  );
};

export default ANR;
