import React from "react";
import { Button, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import TextFieldComponent from "../../components/TextFieldComponent";
import CustomDialog from "../../components/CustomDialog";
import { addNewSubject, editSubject } from "../../services/apis/subejct";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import useWindowSize from "../../hooks/useWindowSize";

const AddSubjectDialog = ({
  open,
  onClose,
  label,
  fetchData,
  handleResponse,
  subjectId,
  subjects,
  functypeparams,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const bankToEdit = subjects?.find((subject) => subject.id === subjectId);
  const [subjectsPayload, setSubjectsPayload] = useState({
    name_ar: "",
    name_en: "",
  });
  const dispatch = useDispatch();

  const resetForm = () => {
    setSubjectsPayload({
      name_ar: "",
      name_en: "",
    });
    onClose();
  };
  const handleCloseDialog = () => {
    resetForm();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (functypeparams === "edit") {
        if (!subjectsPayload.name_ar || !subjectsPayload.name_en) {
          dispatch(
            setData({
              openSnack: true,
              message: "Please fill in all the fields.",
            })
          );
        } else {
          setIsSubmitting(true);
          await editSubject(subjectId, subjectsPayload);
          onClose();
          setSuccessDialogOpen(true);
          await handleResponse();
        }
      } else {
        if (!subjectsPayload.name_ar || !subjectsPayload.name_en) {
          dispatch(
            setData({
              openSnack: true,
              message: "Please fill in all the fields.",
            })
          );
        } else {
          setIsSubmitting(true);
          await addNewSubject(subjectsPayload);
          onClose();
          setSuccessDialogOpen(true);
          await handleResponse();
        }
      }
    } catch (error) {
      if (error.response.status === 422) {
        const validationErrors = error.response.data.validation;
        const nameEnError = validationErrors.name_en;
        const nameArError = validationErrors.name_ar;

        let errorMessage = "";
        if (nameEnError) {
          errorMessage += `${nameEnError}`;
        } else if (nameArError) {
          errorMessage += `${nameArError}`;
        }
        dispatch(
          setData({
            openSnack: true,
            message: errorMessage,
          })
        );
      } else {
        dispatch(
          setData({
            openSnack: true,
            message: error?.data?.data?.message,
          })
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    if (functypeparams === "edit") {
      setSubjectsPayload({
        name_ar: bankToEdit?.name_ar,
        name_en: bankToEdit?.name_en,
      });
    } else {
      setSubjectsPayload({
        name_ar: "",
        name_en: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, fetchData, subjectId]);
  const { width } = useWindowSize();
  return (
    <>
      <Dialog
        maxWidth="721px"
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: width < 768 ? "100%" : "600px",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5%",
            paddingBottom: "8%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={handleCloseDialog}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p className="text-2xl text-center md:text-start  w-full text-nowrap">
                {functypeparams === "edit" ? "Edit Subject" : "Add New Subject"}
              </p>
            </Box>
            <TextFieldComponent
              widthFit={width > 768 ? false : true}
              sx={{ "& fieldset": { border: "none" } }}
              style={{
                height: "48px",
                width: width > 768 ? "465px" : "100%",
                backgroundColor: "#F7F7F7",
                borderRadius: "9px",
              }}
              name="Subject name - English"
              placeholder="eg. Orders"
              value={subjectsPayload.name_en}
              onChange={(event) => {
                var temppayload = { ...subjectsPayload };
                temppayload.name_en = event.target.value;
                setSubjectsPayload({ ...temppayload });
              }}
            />
            <TextFieldComponent
              widthFit={width > 768 ? false : true}
              sx={{ "& fieldset": { border: "none" } }}
              style={{
                height: "48px",
                width: width > 768 ? "465px" : "100%",
                backgroundColor: "#F7F7F7",
                borderRadius: "9px",
              }}
              name="Subject name - Arabic"
              placeholder="eg. الطلبات"
              value={subjectsPayload.name_ar}
              onChange={(event) => {
                const disallowEnglishRegex = /^[^a-zA-Z]*$/;
                if (
                  event.target.value === "" ||
                  disallowEnglishRegex.test(event.target.value)
                ) {
                  var temppayload = { ...subjectsPayload };
                  temppayload.name_ar = event.target.value;
                  setSubjectsPayload({ ...temppayload });
                }
              }}
            />

            <div className="grid grid-cols-1 md:flex gap-3">
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: width > 768 ? "200px" : "150px",
                  borderRadius: "9px",
                  background: "#E9C237",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#E9C237",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: width > 768 ? "200px" : "150px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#E9C237",
                  fontWeight: 600,
                  borderColor: "#E9C237",
                  "&:hover": {
                    borderColor: "#E9C237",
                  },
                }}
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title={
          functypeparams === "edit"
            ? "Subject has been Edit Successfully."
            : "Subject has been added Successfully."
        }
        message=""
        buttonText="Go to Ticket Subjects"
      />
      <ReusableSnackbar />
    </>
  );
};

export default AddSubjectDialog;
