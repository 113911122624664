import React from "react";
import { Button, Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { addCategory } from "../../services/apis/roles-and-permissions";
import CustomDialog from "../../components/CustomDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

const AddNewCategoryModal = ({
  open,
  onClose,
  label,
  setSelectedCategoryName,
  onAddCategory,
}) => {
  const [categoryPayload, setCategoryPayload] = useState({
    name: "",
    description: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const resetForm = () => {
    setCategoryPayload({
      name: "",
      description: "",
    });
  };
  const dispatch = useDispatch();

  const handleClose = () => {
    resetForm();
    onClose();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!categoryPayload.name.trim() || !categoryPayload.description.trim()) {
      dispatch(
        setData({
          openSnack: true,
          message: "Please fill in all required fields.",
        })
      );
      return;
    }
    try {
      setIsSubmitting(true);
      const addedCategoryResponse = await addCategory(categoryPayload);
      const data = addedCategoryResponse?.data?.data;
      onAddCategory();
      handleClose();
      setSuccessDialogOpen(true);
      setSelectedCategoryName(data);
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.validation.name,
          })
        );
      } else {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        maxWidth="721px"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        borderRadius="24px"
        sx={{ borderRadius: "24px" }}
      >
        <DialogContent
          sx={{
            height: "450px",
            width: "600px",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={handleClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Typography
              sx={{
                color: "#4C4C4C",
                fontSize: "20px",
                fontWeight: "400",
                display: "flex",
                alignSelf: "flex-start",
              }}
            >
              Add New Category
            </Typography>
            <Box>
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: "465px",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name="Category name "
                placeholder="eg. Marketing"
                value={categoryPayload.name}
                onChange={(event) => {
                  var temppayload = { ...categoryPayload };
                  temppayload.name = event.target.value;
                  setCategoryPayload({ ...temppayload });
                }}
              />
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: "465px",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name="Category Description"
                placeholder="Enter Category Description"
                value={categoryPayload.description}
                onChange={(event) => {
                  var temppayload = { ...categoryPayload };
                  temppayload.description = event.target.value;
                  setCategoryPayload({ ...temppayload });
                }}
              />
            </Box>
            <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#E9C237",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#E9C237",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                autoCapitalize="false"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#E9C237",
                  fontWeight: 600,
                  borderColor: "#E9C237",
                  "&:hover": {
                    borderColor: "#E9C237",
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Category has been added successfuly"
        message=""
        buttonText="Close"
      />
      <ReusableSnackbar />
    </>
  );
};

export default AddNewCategoryModal;
