import React from "react";
import {
  Button,
  Box,
  Typography,
  Avatar,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  useFetchAllStoreLocations,
  useUpdateLocation,
} from "../../../services/apis/inventory";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import { MenuProps } from "../../../components/common/MenuProps";

const EditStorageLocationDialog = ({
  open,
  onClose,
  label,
  requestData,
  refetch,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [requestsPayload, setRequestsPayload] = useState({
    locationId: null,
  });
  const { data } = useFetchAllStoreLocations();
  const { mutate } = useUpdateLocation();
  const dispatch = useDispatch();
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };
  const resetForm = () => {
    setRequestsPayload({
      locationId: "",
    });
    onClose();
  };
  const handleChange = (name, value) => {
    setRequestsPayload((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (requestsPayload.locationId) {
      const payload = {
        locationId: requestsPayload.locationId,
      };
      setIsSubmitting(true);
      mutate(
        {
          id: requestData.order_id,
          body: payload,
        },
        {
          onSuccess: () => {
            resetForm();
            setSuccessDialogOpen(true);
            refetch();
            setIsSubmitting(false);
          },
          onError: (error) => {
            dispatch(
              setData({
                openSnack: true,
                message: "An error occurred while submitting the order",
              })
            );
            setIsSubmitting(false);
          },
        }
      );
    } else {
      dispatch(
        setData({
          openSnack: true,
          message: "Please select a storage location",
        })
      );
    }
  };
  return (
    <>
      <Dialog
        open={successDialogOpen}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            Order Canceled Successfully.
          </Typography>
          <Button
            onClick={handleSuccessDialogClose}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#E9C237",
              "&:hover": { backgroundColor: "#E9C237" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Go to order Details
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "630px",
            height: "474px",
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "90%",
            height: "100%",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "100%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 3,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: "#595959",
                  fontWeight: "400",
                  fontSize: "24px",
                  display: "flex",
                  alignSelf: "flex-start",
                }}
              >
                Edit Storage Location
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#595959",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Select Storage Location
              </Typography>
              <FormControl
                style={{
                  border: "1px solid #E6E6E6",
                  borderRadius: "12px",
                }}
                sx={{ width: "100%", "& fieldset": { border: "none" } }}
              >
                <Select
                  displayEmpty
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  value={requestsPayload.locationId || ""}
                  onChange={(event) =>
                    handleChange("locationId", event.target.value)
                  }
                  inputProps={{ "aria-label": "Without label" }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select Storage Location </em>;
                    }
                    return (
                      data?.result?.find((item) => item.id === selected)
                        ?.name || ""
                    );
                  }}
                >
                  {data?.result?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <div className="grid grid-cols-1 md:flex gap-2">
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#E9C237",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#E9C237",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#E9C237",
                  fontWeight: 600,
                  borderColor: "#E9C237",
                  "&:hover": {
                    borderColor: "#E9C237",
                    backgroundColor: "#FFF",
                  },
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar />
    </>
  );
};

export default EditStorageLocationDialog;
