import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../styles";
import { useNavigate } from "react-router-dom";
import { ItemsColumns } from "../../components/common/Columns";
import NewTable from "../../components/common/NewTable";
const ThirdTabPanel = ({ role }) => {
  const navigate = useNavigate();
  const handleClick = (params) => {
    const admin = params.row;
    navigate(`/ViewAdminProfile/${admin.id}`, { state: { admin } });
  };
  const userRolesColumns = ItemsColumns([
    { field: "id", headerName: "User ID" },
    { field: "full_name", headerName: "Username", flex: 2 },
    { field: "email", flex: 2 },
    { field: "phone" },
    {
      field: "created",
      headerName: "Creation Date",
      date: true,
      flex: 2,
    },
    {
      field: "Status",
      headerName: "Status",
      renderCell: (params) => (
        <Typography
          sx={{ marginLeft: "5px", color: "#3284FF", fontSize: "14px" }}
        >
          Active
        </Typography>
      ),
    },
    // {
    //   field: "orderDetails",
    //   headerName: "Actions",
    //   actions: { view: (params) => handleClick(params) },
    // },
  ]);

  return (
    <div className="mr-8">
      <NewTable
        noPagination
        columns={userRolesColumns}
        isLoading={!role}
        data={role?.users}
        onCellClick={(params) => {
          handleClick(params);
        }}
      />
    </div>
  );
};
export default ThirdTabPanel;
