import { useMutation, useQuery, useQueryClient } from "react-query";
import { axiosInstance as axios } from "../config";
import { setData } from "../../store/slices/errorMessageSlice";
import { useDispatch } from "react-redux";
const fetchRejectReasons = async ({ page, limit }) => {
  try {
    const params = page;
    if (limit !== undefined) {
      params.limit = limit;
    }
    const response = await axios.get(`/api/kycRejectionReasons/index`, {
      params,
    });
    return response.data?.data; // assuming you need the data part of the response
  } catch (error) {
    throw error;
  }
};

export const useRejectReasons = (page, limit) => {
  return useQuery(
    ["rejectReasons", { page, limit }],
    () => fetchRejectReasons({ page, limit }),
    {
      keepPreviousData: true, // Keeps the previous data while fetching new data
      staleTime: 5000, // Time before the data is considered stale (in milliseconds)
      refetchOnWindowFocus: false,
    }
  );
};
const fetchRejectReasonsDropDown = async () => {
  try {
    const response = await axios.get(`/api/kycRejectionReasons/dropdown`);
    return response.data?.data; // assuming you need the data part of the response
  } catch (error) {
    throw error;
  }
};

export const useRejectReasonsDropDown = () => {
  return useQuery(["rejectReasonsDropDown"], () => fetchRejectReasonsDropDown(), {
    keepPreviousData: true, // Keeps the previous data while fetching new data
    staleTime: 5000, // Time before the data is considered stale (in milliseconds)
    refetchOnWindowFocus: false,
  });
};
// Add a new block reason
export const useAddRejectReason = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async (body) => {
      const response = await axios.post("/api/kycRejectionReasons/add", body);
      return response.data; // Assuming response.data contains the added block reason
    },
    {
      // On success, invalidate relevant queries to refetch data if needed
      onSuccess: () => {
        queryClient.invalidateQueries(["rejectReasons"]); // Adjust query key as needed
      },
      onError: (error) => {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      },
    }
  );
};

// Delete a block reason
export const useDeleteRejectReason = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id) => {
      const response = await axios.get(`/api/kycRejectionReasons/delete/${id}`);
      return response.data; // Assuming response.data contains the result of deletion
    },
    {
      // On success, invalidate relevant queries to refetch data if needed
      onSuccess: () => {
        queryClient.invalidateQueries(["rejectReasons"]); // Adjust query key as needed
      },
    }
  );
};

// Edit a block reason
export const useEditRejectReason = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, body }) => {
      const response = await axios.post(
        `/api/kycRejectionReasons/edit/${id}`,
        body
      );
      return response.data; // Assuming response.data contains the updated block reason
    },
    {
      // On success, invalidate relevant queries to refetch data if needed
      onSuccess: () => {
        queryClient.invalidateQueries(["rejectReasons"]); // Adjust query key as needed
      },
    }
  );
};
