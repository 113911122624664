import { axiosInstance as axios } from "../config";

export const configuration = async (category) => {
  try {
    const response = await axios.get(`/api/config/index`, {
      params: { category } // Send category as a query parameter
    });
    return response.data?.data; // Assuming response.data contains the articles
  } catch (error) {
    throw error;
  }
}; 

export const updateFraction = async (id, body) => {
  try {
    const response = await axios.post(`/api/config/edit/${id}`, body);
    return response;
  } catch (error) {
    throw error;
  }
};
