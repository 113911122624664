import React, { useState } from "react";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import { ItemsColumns } from "../../components/common/Columns";
import NewTable from "../../components/common/NewTable";
import { useNavigate } from "react-router-dom";
import { setData } from "../../store/slices/errorMessageSlice";
import { useDispatch } from "react-redux";
import ActionAlert from "../user-profile/ActionAlert";
import {
  useDeleteLicense,
  useGetAllLicenses,
} from "../../services/apis/license";
import { handlePageChange } from "../../services/utils/filters";
import ReusableSnackbar from "../../components/ReusableSnackbar";
const AppLicense = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
  });
  const [id, setId] = useState("");
  const { data, isLoading } = useGetAllLicenses(queryParameters);
  const { mutateAsync } = useDeleteLicense();
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const handleDeleteLicense = async () => {
    try {
      await mutateAsync(id);
      dispatch(
        setData({
          openSnack: true,
          severity: "success",
          message: "License Deleted Successfully",
        })
      );
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
    handleCloseDeleteAlert();
  };

  const LicensesColumns = ItemsColumns([
    {
      field: "name_en",
      headerName: "Name",
    },
    {
      field: "description_en",
      headerName: "Description",
      specialRender: "customRender",
      renderFunction: (params) => {
        console.log("params", params?.row?.description_en?.length);
        return (
          <span>
            {params?.row?.description_en
              ? params?.row?.description_en?.length < 30
                ? params?.row?.description_en
                : params?.row?.description_en?.substring(0, 50)
              : "-"}
          </span>
        );
      },
    },
    {
      field: "numberOfQuestions",
      headerName: "Number of Questions",
      specialRender: "customRender",
      renderFunction: (params) => {
        return (
          <span>
            {params.row.questions.length > 0
              ? params?.row?.questions.length
              : "-"}
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      actions: {
        view: (params) => {
          navigate(`/license/${params.row.id}`, {
            state: {
              license: params.row,
            },
          });
        },
        edit: (params) => {
          navigate(`/edit-license/${params.row.id}`, {
            state: {
              license: params.row,
            },
          });
        },
        delete: (params) => {
          setId(params?.row?.id);
          setOpenDeletedAlert(true);
        },
      },
    },
  ]);
  return (
    <div>
      <DashboardMainHeader />
      <Header title={"App Licenses"} marginL={"24px"} />
      <button
        className="px-5 m-6 py-2 text-white bg-[#e9c237] rounded-xl flex ml-auto font-medium"
        onClick={() => {
          navigate("/add-app-license");
        }}
      >
        <span className="mr-2">+</span> Add License
      </button>
      <NewTable
        className={"mx-6"}
        columns={LicensesColumns}
        isLoading={isLoading}
        data={data?.result}
        totalPages={data?.totalPages}
        totalItems={data?.totalItems}
        handlePageChange={(newPage) =>
          handlePageChange(newPage, queryParameters, setQueryParameters)
        }
        currentPage={queryParameters}
      />
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteLicense}
        message={`Are you sure you want to Delete this license ?`}
        label={"Delete"}
      />
      <ReusableSnackbar />
    </div>
  );
};

export default AppLicense;
