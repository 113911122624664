const NewTabs = ({ tabs, value, onChange, className, isSpecial }) => {
  return (
    <div className="pt-5 pb-1 flex justify-center w-full">
      <div className={`flex flex-wrap w-full bg-gray-100 rounded-lg mx-4 ${className}`}>
        {tabs.map((tab) => (
          <button
            key={tab.value}
            className={`flex-grow py-3 px-4 text-center text-s ${
              value === tab.value
                ? isSpecial === true
                  ? "border-b-[3px] border-b-[#E9C237] text-[#E9C237]"
                  : isSpecial === false
                  ? "border-b-[3px] border-b-[#E9C237] text-[#E9C237]"
                  : "bg-[#E9C237] text-white rounded-[0.6rem]"
                : isSpecial === true
                ? "text-gray-500 border-b-[3px]"
                : isSpecial === false
                ? "text-[#00303c]"
                : "text-[#00303c]"
            }`}
            onClick={() => {
              onChange(tab.value);
            }}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default NewTabs;
