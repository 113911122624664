import React from "react";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import ProductViewEdit from "./ProductViewEdit";
import ProductSerial from "./ProductSerial";

const ProductDetailsPage = () => {
  return (
    <div>
      <DashboardMainHeader />
      <ProductViewEdit />
      <ProductSerial />
    </div>
  );
};

export default ProductDetailsPage;
